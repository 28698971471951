import Map from "components/Map";
import { FC, useEffect, useState } from "react";
import CircleMapMarker from "components/CircleMapMarker";
import { Coords, Position } from "google-map-react";
import { Box, Skeleton, styled } from "@mui/material";
import { SuspectVehicle } from "api/CloneDashboardAPI";
import SuspectVehiclesStatusGuide from "./SuspectVehiclesStatusGuide";

const Container = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  overflow: "hidden",
  display: "flex",
  height: 350
}));

interface CloneMapProps {
  center: Coords;
  zoom: number;
  isLoading: boolean;
  suspectVehicles: SuspectVehicle[][];
}

const CloneMap: FC<CloneMapProps> = ({
  center,
  zoom,
  isLoading,
  suspectVehicles
}) => {
  const [geolocations, setGeolocations] = useState<Position[]>([]);

  useEffect(() => {
    if (suspectVehicles.length > 0) {
      const configGeographic: Position[] = [];
      suspectVehicles.forEach(vehicles => {
        vehicles.forEach(vehicle => {
          configGeographic.push({
            lat: Number(vehicle.latitude),
            lng: Number(vehicle.longitude)
          });
        });
      });
      setGeolocations(configGeographic || []);
    }
  }, [suspectVehicles]);

  return (
    <Container>
      <div
        style={{
          flexGrow: 1,
          width: "100%"
        }}
      >
        {isLoading && <Skeleton variant="rectangular" height={348} />}
        {!isLoading && (
          <Map
            center={center}
            zoom={zoom}
            customControls={<SuspectVehiclesStatusGuide />}
          >
            {geolocations.map((location, idx) => (
              <CircleMapMarker
                key={location.lat + location.lng + idx}
                lat={location.lat}
                lng={location.lng}
              />
            ))}
          </Map>
        )}
      </div>
    </Container>
  );
};

export default CloneMap;
