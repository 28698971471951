import { FC, useState } from "react";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { CustomFilterTextField } from "components/AdvancedFilters/AdvancedFiltersHelpers";
import { formatDateTime, setTimezone } from "utils/DateFunctions";
import { isValid } from "date-fns";
import { Calendar } from "react-feather";
import { IconButton } from "@mui/material";

type DateInputFilterProps = {
  time?: boolean;
  value: string;
  onValueChange: (value: string) => void;
};

const DateInputFilter: FC<DateInputFilterProps> = ({
  time,
  value,
  onValueChange
}) => {
  const [date, setDate] = useState<Date | null>(value ? new Date(value) : null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let Component;
  if (time) {
    Component = DateTimePicker;
  } else {
    Component = DatePicker;
  }

  return (
    <Component
      open={isOpen}
      onClose={() => setIsOpen(false)}
      value={date}
      onChange={newDate => {
        if (isValid(newDate)) {
          const isoFormatDate = setTimezone(newDate as Date);
          setDate(newDate);
          onValueChange(formatDateTime(isoFormatDate, isoFormatDate));
        } else {
          onValueChange("");
        }
      }}
      renderInput={props => (
        <CustomFilterTextField
          {...props}
          InputProps={{
            ...props.InputProps,
            endAdornment: null,
            startAdornment: (
              <IconButton size="small" onClick={() => setIsOpen(true)}>
                <Calendar />
              </IconButton>
            )
          }}
        />
      )}
    />
  );
};

export default DateInputFilter;
