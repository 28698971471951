enum Pages {
  BILLING = "/settings/plan-payment",
  CAMERAS = "/system/cameras",
  CAMERA_DASHBOARD = "/dashboards/cameraDashboard",
  CAMERA_DASHBOARD_OLD = "/dashboards/cameraDashboardOld",
  CAPTURE_REPORT = "/reports/capture-report",
  CLONE_DASHBOARD = "/dashboards/cloneDashboard",
  CONVOY_DASHBOARD = "/dashboards/convoyDashboard",
  CONFIRM_USER = "/confirm-user",
  CONVOY_REPORT = "/reports/convoy-report",
  CORRELATION_REPORT = "/reports/correlation-report",
  CREATE_ACCOUNT = "/create-account",
  CUSTOMERS = "/settings/customers",
  CUSTOMIZATION = "/settings/customizations",
  EQUIPMENT_MAP = "/system/equipment-map",
  EQUIPMENTS = "/system/equipments",
  ERROR_403 = "/403",
  FLOW_DASHBOARD = "/dashboards/flowDashboard",
  FORENSIC_REPORT = "/reports/forensics-report",
  INCIDENT_REPORT = "/reports/incidents-report",
  INFORMATION_PROTECTION = "/settings/information-protection",
  INTEGRATIONS = "/settings/integrations",
  INVOICE = "/settings/invoices",
  LOG_REPORT = "/reports/log-report",
  MONITORED_ROUTES = "/system/routes",
  MONITORING_REPORT = "/reports/monitoring-report",
  MONITORING_VEHICLES = "/system/monitoring-vehicles",
  MONITORING_VEHICLES_DASHBOARD = "/dashboards/dashboard",
  MOSAIC = "/system/cameras-mosaic",
  MY_ACCOUNT = "/my-account",
  RESTRICTION = "/system/restriction",
  RESET_PASSWORD = "/reset-password",
  RESTRICTION_DASHBOARD = "/dashboards/restrictionDashboard",
  SETTINGS = "/settings",
  SHARED_DATA = "/shared-data",
  SIGN_IN = "/sign-in",
  SPIA_REPORT = "/reports/spia-report",
  SYSTEM = "/system",
  SYSTEM_FILTER_CAMERAS = "/system/mosaic/filter-cameras",
  SYSTEM_MONITORING = "/system/monitoring",
  TERMS = "/terms",
  USER_GROUPS = "/settings/user-groups",
  USER_GUIDE = "/user-guide",
  USERS = "/settings/users",
  VEHICLE_OVERVIEW = "/vehicle-overview",
  VERTICALS = "/settings/verticals"
}

export const ErrorPages: string[] = [Pages.ERROR_403];

export default Pages;
