import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";

export type ListVehicleOverviewParams = {
  ["customer_id"]: string;
  ["page_size"]?: number;
  page?: number;
  ["initial_date"]: string;
  ["final_date"]: string;
  plate: string;
  cameras?: string[];
  locations?: string[];
};

export type CaptureReport = {
  id: string;
  ["date_capture"]: string;
  ["location_name"]: string;
  ["camera_name"]: string;
  latitude: string;
  longitude: string;
  ["plate_coordinate"]: string;
  ["vehicle_year"]: string;
  plate: string;
  ["vehicle_class"]: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  ["vehicle_color"]: string;
  imageLink: string;
};

export type VehicleOverviewPaginated = {
  response: {
    ["registers_incidents_items"]: CaptureReport[];
    page: number;
    ["total_items"]: number;
    ["total_pages"]: number;
  };
};

export type DownloadParams = {
  ["customer_id"]: string;
  language: string;
  ["initial_date"]: string;
  ["final_date"]: string;
  plate: string;
  cameras?: string[];
  locations?: string[];
};

export type VehicleInfos = {
  make: string;
  model: string;
  color: string;
  class: string;
  year: string;
};

type UseVehicleOverviewAPIType = {
  list: (param: ListVehicleOverviewParams) => Promise<VehicleOverviewPaginated>;
  downloadCsv: (param: DownloadParams) => Promise<string>;
  downloadPdf: (param: DownloadParams) => Promise<string>;
};

const useVehicleOverviewAPI = (): UseVehicleOverviewAPIType => {
  const API = useAxios();

  const list = async (
    filter: ListVehicleOverviewParams
  ): Promise<VehicleOverviewPaginated> => {
    const response: AxiosResponse<VehicleOverviewPaginated> =
      await API.instance.post(APIEndpoints.VEHICLE_OVERVIEW_LIST, filter);
    return response.data;
  };

  const downloadCsv = async (filter: DownloadParams): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.VEHICLE_OVERVIEW_DOWNLOAD,
      {
        ...filter,
        type: "csv",
        timezone: -new Date().getTimezoneOffset() / 60
      },
      {
        responseType: "blob"
      }
    );
    return response.data;
  };

  const downloadPdf = async (filter: DownloadParams): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.VEHICLE_OVERVIEW_DOWNLOAD,
      {
        ...filter,
        type: "pdf",
        timezone: -new Date().getTimezoneOffset() / 60
      }
    );
    return response.data;
  };

  return {
    list,
    downloadCsv,
    downloadPdf
  };
};

export default useVehicleOverviewAPI;
