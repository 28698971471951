import {
  Grid,
  Autocomplete,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import FormLabel from "components/FormLabel";
import { useTranslation } from "react-i18next";
import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "contexts/AuthContext";
import FormDialog from "components/FormDialog";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { captureReportTableColumns } from "utils/captureReportTableColumns";

const TextTypeValues = ["CSV", "PDF"] as const;

export type FormDownloadCaptureReport = {
  formatType: string;
  tableColumnsSelected: string[];
};

type DownloadCaptureReportProps = {
  open: boolean;
  download: FormDownloadCaptureReport;
  defaultValues: FormDownloadCaptureReport;
  onDialogDownload: (data: FormDownloadCaptureReport) => void;
  setOpen: (open: boolean) => void;
  onClear?: () => void;
};

const DownloadCaptureReportDialog: FC<DownloadCaptureReportProps> = ({
  open,
  setOpen,
  onDialogDownload,
  download
}) => {
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { errorHandler } = useErrorHandler();
  const captureReportTableColumnsArray = captureReportTableColumns.map(
    item => item.label
  );
  const [tableColumnsSelected, setTableColumnsSelected] = useState<string[]>([
    "all"
  ]);
  const [formatTypeSelected, setFormatTypeSelected] = useState<string>("CSV");

  useEffect(() => {
    if (!open) {
      setFormatTypeSelected("CSV");
      setTableColumnsSelected(["all"]);
    }
  }, [open]);

  const { handleSubmit } = useForm<FormDownloadCaptureReport>({
    mode: "onChange",
    defaultValues: download
  });

  const getDialogData = (
    data: FormDownloadCaptureReport
  ): FormDownloadCaptureReport => {
    const newData = { ...data };

    newData.formatType = formatTypeSelected;

    if (tableColumnsSelected.includes(t("form.all"))) {
      newData.tableColumnsSelected = [];
    } else {
      newData.tableColumnsSelected = tableColumnsSelected;
    }

    return newData;
  };

  const onSubmit = async (data: FormDownloadCaptureReport) => {
    if (!sessionUser?.["customer_id"]) return;
    const dialogData = getDialogData(data);
    try {
      onDialogDownload(dialogData);
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormDialog
        onConfirm={handleSubmit(onSubmit)}
        open={open}
        maxWidth="sm"
        setOpen={setOpen}
        isLoading={isLoading}
        confirmText={t("action.apply")}
        title={t("action.export")}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <FormLabel>{t("IntegrationsPage.vms.outputFormat")}*</FormLabel>
            <RadioGroup
              row
              aria-label="gridSize"
              name="row-radio-buttons-group"
            >
              {TextTypeValues.map(value => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={
                    <Radio
                      color="secondary"
                      size="small"
                      checked={value === formatTypeSelected}
                      onChange={event => {
                        setFormatTypeSelected(event.target.value);
                      }}
                    />
                  }
                  label={value}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>{t("ConvoyDashboardPage.info")}*</FormLabel>
            <Autocomplete
              multiple
              disablePortal
              size="small"
              openText={t("action.open")}
              closeText={t("action.close")}
              clearText={t("action.clear")}
              noOptionsText={t("form.noOptions")}
              options={["all", ...captureReportTableColumnsArray]}
              getOptionLabel={option =>
                option === "all"
                  ? t("form.all")
                  : t(`CaptureReportPage.${option}`)
              }
              value={tableColumnsSelected}
              onChange={(event, values) => {
                event.preventDefault();
                const noIsAll = values.findIndex(c => c === "all");
                const allArray = tableColumnsSelected.filter(c => c === "all");
                if (allArray.length > 0 && tableColumnsSelected.length === 1) {
                  values.splice(noIsAll, 1);
                  setTableColumnsSelected(values.map(option => option));
                } else if (noIsAll === -1) {
                  setTableColumnsSelected(values.map(option => option));
                } else {
                  setTableColumnsSelected(["all"]);
                }
              }}
              renderInput={params => (
                <div style={{ position: "relative" }}>
                  <TextField {...params} placeholder={t("form.startTyping")} />
                </div>
              )}
            />
          </Grid>
        </Grid>
      </FormDialog>
    </>
  );
};

export default DownloadCaptureReportDialog;
