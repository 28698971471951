import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  ReactZoomPanPinchHandlers,
  TransformComponent,
  TransformWrapper
} from "react-zoom-pan-pinch";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  styled
} from "@mui/material";
import {
  Download,
  Maximize,
  Minimize,
  MinusSquare,
  PlusSquare,
  RefreshCcw,
  Video
} from "react-feather";
import noImage from "assets/image_unavailable.jpg";
import PicInPicture from "components/CaptureImageViewerDialog/PicInPicture";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useAuth } from "contexts/AuthContext";
import useVmsIntegrationAPI from "api/VmsIntegrationApi";
import VideoViewerDialog, {
  VideoViewerData
} from "components/CaptureImageViewerDialog/VideoViewerDialog";
import useCameraAPI from "api/CameraAPI";
import { useTranslation } from "react-i18next";
import { secondaryDefaultColors } from "styles/theme";

export type ImageCoordinate = {
  x: number;
  y: number;
  height: number;
  width: number;
};

export type Classifier = {
  type: number;
  prob: number;
  coordenate: ImageCoordinate;
};

export const parsePlateCoordinate = (
  plateCoordinate: string
): ImageCoordinate | undefined => {
  if (plateCoordinate === "undefined" || plateCoordinate === "null") {
    return undefined;
  }

  const parts = plateCoordinate.split(",");
  if (parts.length !== 2) {
    // Handle invalid format or return undefined as needed
    return undefined;
  }

  const positionPair = parts[0].split("x");
  const sizePair = parts[1].split("x");
  return {
    x: Number(positionPair[0]),
    y: Number(positionPair[1]),
    height: Number(sizePair[1]),
    width: Number(sizePair[0])
  };
};

export const parseClassifierCoordinate = (
  classifierCoordinate: string
): Classifier[] | undefined => {
  if (classifierCoordinate === "undefined" || classifierCoordinate === "null") {
    return undefined;
  }

  const classifierList: Classifier[] = [];
  const listRegex = /\[([0-9,\s]+)\]/g;
  const classifierString = `[${classifierCoordinate}]`;
  const classifierStringList = classifierString.match(listRegex);

  if (classifierStringList && classifierStringList.length > 0) {
    const classifiersArray: number[][] = classifierStringList.map(match =>
      JSON.parse(match)
    );
    classifiersArray.map(classifier =>
      classifierList.push({
        type: classifier[0],
        prob: classifier[1],
        coordenate: {
          x: classifier[2],
          y: classifier[3],
          width: classifier[4],
          height: classifier[5]
        }
      })
    );
  }

  return classifierList;
};

const Wrapper = styled(Box)(() => ({
  position: "relative",
  display: "inline-block"
}));

const BgImageMenuIcon = styled(Box)(() => ({
  backgroundColor: "rgba(0,0,0,0.45)"
}));

type Props = {
  img: string;
  id: string;
  cameraId: string;
  plateCoordinate?: ImageCoordinate;
  classifierCoordinate?: Classifier[];
  isDialogOpen: boolean;
  isFullscreen: boolean;
  setIsFullscreen: (on: boolean) => void;
};

const minusPercent = (number: number, percentage: number): number =>
  number - number * (percentage / 100);

const ImageViewer: FC<Props> = ({
  img,
  isFullscreen,
  setIsFullscreen,
  isDialogOpen,
  plateCoordinate,
  classifierCoordinate,
  id,
  cameraId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [videoData, setVideoData] = useState<VideoViewerData>({
    url: "",
    requestId: ""
  });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);
  const { errorHandler } = useErrorHandler();
  const { sessionUser } = useAuth();
  const VmsIntegrationAPI = useVmsIntegrationAPI();
  const CameraAPI = useCameraAPI();
  const background = useMemo(() => new Image(), [img]);

  const { t } = useTranslation();
  const classifierTypes = [
    t("MonitoredVehiclesDashboardPage.car"),
    t("MonitoredVehiclesDashboardPage.motorcycle"),
    t("MonitoredVehiclesDashboardPage.truck"),
    t("MonitoredVehiclesDashboardPage.bus")
  ];

  const drawImage = (
    width: number,
    height: number,
    parent: Element | null = null
  ) => {
    if (isNaN(width) && isNaN(height)) {
      background.src = noImage;
      return;
    }

    if (!canvasRef.current) return;
    const context = canvasRef.current.getContext("2d");
    if (!context) return;

    const maxHeight = window.innerHeight - 60;
    let newWidth = width;
    let newHeight = height;

    if (maxHeight < height) {
      newHeight = maxHeight;
      if (parent) {
        newWidth = (parent.clientHeight / background.height) * background.width;
      }
    }

    canvasRef.current.width = newWidth;
    canvasRef.current.height = newHeight;
    context.drawImage(background, 0, 0, newWidth, newHeight);

    setImageSize({
      width: newWidth,
      height: newHeight
    });

    if (plateCoordinate) {
      const variationX =
        ((background.width - newWidth) * 100) / background.width;
      const variationY =
        ((background.height - newHeight) * 100) / background.height;
      context.lineWidth = 3;
      context.strokeStyle = "red";
      context.strokeRect(
        minusPercent(plateCoordinate.x, variationX) - 2,
        minusPercent(plateCoordinate.y, variationY) - 2,
        minusPercent(plateCoordinate.width, variationX) + 3,
        minusPercent(plateCoordinate.height, variationY) + 3
      );
    }

    if (classifierCoordinate) {
      classifierCoordinate.forEach(classifier => {
        const message = `${classifierTypes[
          classifier.type - 1
        ].toUpperCase()} (${classifier.prob}%)`;
        const variationX =
          ((background.width - newWidth) * 100) / background.width;
        const variationY =
          ((background.height - newHeight) * 100) / background.height;

        context.fillStyle = "#FFF";
        context.fillRect(
          minusPercent(classifier.coordenate.x, variationX),
          minusPercent(classifier.coordenate.y, variationY),
          classifier.type <= 2 ? 85 : 110,
          12
        );

        context.fillStyle = secondaryDefaultColors.main;
        context.font = "bold 12px Inter, sans-serif";
        context.textAlign = "start";
        context.textBaseline = "top";
        context.fillText(
          message,
          minusPercent(classifier.coordenate.x, variationX) + 1,
          minusPercent(classifier.coordenate.y, variationY) + 1
        );

        context.lineWidth = 3;
        context.strokeStyle = "blue";
        context.strokeRect(
          minusPercent(classifier.coordenate.x, variationX),
          minusPercent(classifier.coordenate.y, variationY),
          minusPercent(classifier.coordenate.width, variationX) + 3,
          minusPercent(classifier.coordenate.height, variationY) + 3
        );
      });
    }
  };

  useEffect(() => {
    if (isDialogOpen) {
      observer.current = new ResizeObserver(entries => {
        entries.forEach(({ target }) => {
          const { width, height } = background;
          const scale = target.clientWidth / (width || 1);
          drawImage(width * scale, height * scale, target);
        });
      });
      observer.current.observe(containerRef.current as Element);
    } else if (observer.current) {
      observer.current.unobserve(containerRef.current as Element);
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (!canvasRef?.current) return;
    background.src = img;
    setIsLoading(true);

    background.onload = () => {
      setIsLoading(false);
      drawImage(background.width, background.height);
    };
  }, [background]);

  const download = () => {
    const fileName = img.split("/").pop();
    if (!fileName) return;

    const el = document.createElement("a");
    el.setAttribute("href", img);
    el.setAttribute("download", fileName);
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  useEffect(() => {
    (async () => {
      try {
        if (!sessionUser) return;
        setIsLoadingVideo(true);
        const cameras = await CameraAPI.listAll({
          customerId: sessionUser.customer_id
        });
        const allCameras = cameras.data
          .filter(c => c.camera_data.vms)
          .map(c => c.camera_name);
        setIsVideoAvailable(allCameras.includes(cameraId));
      } finally {
        setIsLoadingVideo(false);
      }
    })();
  }, []);

  const requestVideo = async () => {
    try {
      if (!sessionUser) return;
      setIsLoadingVideo(true);
      const requestDataResponse = await VmsIntegrationAPI.requestData({
        customerId: sessionUser.customer_id,
        registerId: id
      });
      setVideoData({
        requestId: requestDataResponse.request_id,
        url: requestDataResponse.url
      });
      setIsVideoOpen(true);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoadingVideo(false);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <div
        ref={containerRef}
        style={{ textAlign: "center", position: "relative", width: "99%" }}
      >
        {img && (
          <Wrapper>
            {canvasRef.current && plateCoordinate && (
              <PicInPicture
                imageSize={imageSize}
                background={background}
                platePosition={plateCoordinate}
              />
            )}
            <TransformWrapper>
              {({
                zoomIn,
                zoomOut,
                resetTransform
              }: ReactZoomPanPinchHandlers) => (
                <>
                  <BgImageMenuIcon
                    hidden={isLoading}
                    sx={{ position: "absolute", top: 0, left: 0, zIndex: 10 }}
                  >
                    <Stack direction="column">
                      <IconButton
                        onClick={() => {
                          setIsFullscreen(!isFullscreen);
                          resetTransform();
                        }}
                      >
                        {isFullscreen ? (
                          <Minimize color="#C2C9D1" />
                        ) : (
                          <Maximize color="#C2C9D1" />
                        )}
                      </IconButton>
                      <IconButton onClick={download}>
                        <Download color="#C2C9D1" />
                      </IconButton>
                      {isVideoAvailable && !isLoadingVideo && (
                        <IconButton onClick={requestVideo}>
                          <Video color="#C2C9D1" />
                        </IconButton>
                      )}
                      {isLoadingVideo && (
                        <CircularProgress
                          color="secondary"
                          size={25}
                          sx={{
                            ml: "6px"
                          }}
                        />
                      )}
                    </Stack>
                  </BgImageMenuIcon>
                  <BgImageMenuIcon
                    hidden={isLoading}
                    sx={{ position: "absolute", top: 0, right: 0, zIndex: 10 }}
                  >
                    <Stack direction="column">
                      <IconButton onClick={() => zoomIn()}>
                        <PlusSquare color="#C2C9D1" />
                      </IconButton>
                      <IconButton onClick={() => zoomOut()}>
                        <MinusSquare color="#C2C9D1" />
                      </IconButton>
                      <IconButton onClick={() => resetTransform()}>
                        <RefreshCcw color="#C2C9D1" />
                      </IconButton>
                    </Stack>
                  </BgImageMenuIcon>
                  <TransformComponent>
                    <canvas ref={canvasRef} />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </Wrapper>
        )}
      </div>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 300,
            position: "absolute",
            top: 0,
            left: "50%"
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && !img && (
        <Box>
          <img src={noImage} alt="no-capture" width="100%" />
        </Box>
      )}
      <VideoViewerDialog
        open={isVideoOpen}
        setOpen={setIsVideoOpen}
        videoData={videoData}
      />
    </Box>
  );
};

export default ImageViewer;
