import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Filter, Users, Video } from "react-feather";

import Button from "components/Button";
import CameraView from "components/CameraView";
import { useAuth } from "contexts/AuthContext";
import Pages from "enums/Pages";

import DraggableItem from "./DraggableItem";
import SelectField from "components/SelectField";
import CaptureImageViewerDialog from "components/CaptureImageViewerDialog/CaptureImageViewerDialog";
import { EMPTY_VALUE } from "utils/String";

const maxHeight = "32vw";
const maxHeightMobile = "62vw";

const gridSizeValues = [
  "1x1",
  "2x2",
  "3x3",
  "4x4",
  "5x5",
  "6x6",
  "7x7",
  "8x8"
] as const;
type GridSizeTuple = typeof gridSizeValues;
export type GridSize = GridSizeTuple[number];

export type GridItem = {
  key: string;
  id: string;
  location: string;
  camera: string;
  image?: string;
  dateTime?: string;
  plate?: string;
  serialNumber?: string;
  type?: string;
  brand?: string;
  model?: string;
  color?: string;
  owner: string;
  latitude: string;
  longitude: string;
};

const MosaicContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey["200"]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1)
}));

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .MuiFormGroup-root": {
    marginLeft: theme.spacing(1)
  },
  "& .MuiFormGroup-root .MuiTypography-root": {
    fontSize: "14px"
  }
}));

const CamerasContainer = styled(Box)(({ theme }) => ({
  height: maxHeight,
  "& .main-item": {
    position: "relative"
  },
  "& .main-item, & .grid-item": {
    border: `1px solid ${theme.palette.grey["200"]}`
  },
  "& .grid-item.selected": {
    border: `2px solid ${theme.palette.secondary.main}`
  },
  "& .camera-name": {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    color: theme.palette.common.white,
    fontSize: "10px",
    padding: "0 4px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    height: "20px",
    "& > svg": {
      marginRight: "5px"
    }
  },
  [theme.breakpoints.down("sm")]: {
    height: "initial",
    "& .main-item, & .mosaic-grid": {
      width: "100%"
    }
  }
}));

const ImgToolbar = styled(Box)(() => ({
  backgroundColor: "rgba(255,255,255,0.6)",
  padding: 8,
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
  "& svg": {
    color: "#2B2E32"
  },
  fontSize: 12,
  fontWeight: "600",
  ".label": {
    fontSize: 10,
    fontWeight: "normal"
  }
}));

type Props = {
  size: GridSize | null;
  setSize: Dispatch<SetStateAction<GridSize | null>>;
  onSizeChange?: (size: GridSize) => void;
  items: GridItem[];
  setItems: Dispatch<SetStateAction<GridItem[]>>;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  onItemsChange?: (items: GridItem[]) => void;
};

const Mosaic: FC<Props> = ({
  size,
  setSize,
  onSizeChange,
  items,
  setItems,
  onItemsChange,
  setIsDragging
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedGridItem, setSelectedGridItem] = useState<GridItem | null>(
    items.length > 0 ? items[0] : null
  );
  const [selectedModalItem, setSelectedModalItem] = useState<GridItem | null>();

  const [isCaptureDialogOpen, setCaptureDialogOpen] = useState(false);

  const { sessionUser } = useAuth();

  const [gridW, gridH] = size?.split("x").map(d => Number(d)) ?? [0, 0];
  const gridLength = gridW * gridH;

  const openCaptureDialog = (item: GridItem) => {
    setSelectedModalItem({ ...item });
    setCaptureDialogOpen(true);
  };

  const switchItemsByIndex = (
    sourceIndex: number,
    destinationIndex: number
  ) => {
    let newDestinationIndex = destinationIndex;

    if (destinationIndex >= items.length) {
      newDestinationIndex = items.length - 1;
    }

    const newItems = [...items];

    [newItems[sourceIndex], newItems[newDestinationIndex]] = [
      newItems[newDestinationIndex],
      newItems[sourceIndex]
    ];

    return newItems;
  };

  const handleMoveItem = useCallback(
    async (sourceKey: string, destinationKey: string) => {
      if (!sourceKey || !destinationKey || !sessionUser?.["customer_id"]) {
        return;
      }

      const sourceIndex = items.findIndex(item => item.id === sourceKey);

      const destinationIndex = items.findIndex(
        item => item.id === destinationKey
      );

      const newItems = switchItemsByIndex(sourceIndex, destinationIndex);

      setItems(newItems);
    },
    [items]
  );

  const handleDropItem = useCallback(async () => {
    if (onItemsChange) onItemsChange(items);
  }, [size, items]);

  const history = useHistory();

  return (
    <MosaicContainer>
      <Header>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!isMobile && (
            <Typography color="textSecondary">
              <strong>{t("CamerasMosaicPage.visualization")}</strong>
            </Typography>
          )}
          {isTablet && (
            <SelectField
              onChange={event => {
                setSize(event.target.value as GridSize);
                if (onSizeChange) {
                  onSizeChange(event.target.value as GridSize);
                }
              }}
              value={size || ""}
              style={{ marginBottom: 0, marginLeft: "6px" }}
            >
              {gridSizeValues.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </SelectField>
          )}
          {!isTablet && (
            <RadioGroup
              row
              aria-label="gridSize"
              name="row-radio-buttons-group"
            >
              {gridSizeValues.map(value => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={
                    <Radio
                      color="secondary"
                      size="small"
                      checked={value === size}
                      onChange={event => {
                        setSize(event.target.value as GridSize);
                        if (onSizeChange) {
                          onSizeChange(event.target.value as GridSize);
                        }
                      }}
                    />
                  }
                  label={value}
                />
              ))}
            </RadioGroup>
          )}
        </Box>
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            customProps={{
              color: "secondary",
              startIcon: <Filter />,
              onClick: () => history.push(Pages.SYSTEM_FILTER_CAMERAS)
            }}
          >
            {t("CamerasMosaicPage.filterCamera")}
          </Button>
        </Stack>
      </Header>
      <CamerasContainer>
        <Grid container>
          <Grid className="main-item" item sm={isMobile ? 12 : 5}>
            <CameraView
              onClick={() => {
                if (selectedGridItem?.image) {
                  openCaptureDialog(selectedGridItem);
                }
              }}
              image={selectedGridItem?.image ?? ""}
              style={{
                height: isMobile ? "initial" : maxHeight,
                minHeight: isMobile ? maxHeightMobile : "initial",
                display: "flex",
                justifyContent: "center"
              }}
            />
            {selectedGridItem && (
              <ImgToolbar>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ mr: 1 }}>
                      <div className="label">
                        {t("MonitoringReportPage.plate")}
                      </div>
                      <div>{selectedGridItem?.plate ?? EMPTY_VALUE}</div>
                    </Box>
                    <Box sx={{ mr: 1 }}>
                      <div className="label">
                        {t("MonitoringReportPage.equipment")}
                      </div>
                      <div>{selectedGridItem?.location ?? EMPTY_VALUE}</div>
                    </Box>
                    {selectedGridItem.owner !== "" && (
                      <Box>
                        <div className="label">
                          <Users size={12} /> {selectedGridItem?.owner}
                        </div>
                      </Box>
                    )}
                  </Box>
                  <IconButton
                    onClick={() => {
                      if (selectedGridItem?.image) {
                        openCaptureDialog(selectedGridItem);
                      }
                    }}
                  >
                    <Video />
                  </IconButton>
                </Box>
              </ImgToolbar>
            )}
          </Grid>
          <Grid className="mosaic-grid" item sm={isMobile ? 12 : 7}>
            <DndProvider backend={HTML5Backend}>
              <Grid container>
                {items.map((item, index) => {
                  if (index >= gridLength || !item) return undefined;
                  return (
                    <Grid
                      item
                      key={item.key}
                      xs={12 / gridW}
                      sx={{
                        height: `calc(${
                          isMobile ? maxHeightMobile : maxHeight
                        } / ${gridH})`,
                        position: "relative"
                      }}
                      onClick={() => {
                        setSelectedGridItem(item);
                      }}
                    >
                      <div
                        className={
                          item && selectedGridItem?.key === item.key
                            ? "grid-item selected"
                            : "grid-item"
                        }
                        style={{
                          height: `calc(${
                            isMobile ? maxHeightMobile : maxHeight
                          } / ${gridH})`
                        }}
                      >
                        <div className="camera-name">
                          {item.owner !== "" && <Users size={12} />}{" "}
                          {item.camera}
                        </div>
                        <DraggableItem
                          item={item}
                          onMoveItem={handleMoveItem}
                          onDropItem={handleDropItem}
                          setIsDragging={setIsDragging}
                          style={{
                            height: `calc(${
                              isMobile ? maxHeightMobile : maxHeight
                            } / ${gridH})`
                          }}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </DndProvider>
          </Grid>
        </Grid>
      </CamerasContainer>
      <CaptureImageViewerDialog
        open={isCaptureDialogOpen}
        setOpen={setCaptureDialogOpen}
        capture={{
          id: selectedModalItem?.id ?? "",
          image: selectedModalItem?.image ?? "",
          equipment: selectedModalItem?.location ?? "",
          camera: selectedModalItem?.camera ?? "",
          dateTime: selectedModalItem?.dateTime ?? "",
          plate: selectedModalItem?.plate ?? "",
          latitude: selectedModalItem?.latitude ?? "",
          longitude: selectedModalItem?.longitude ?? ""
        }}
      />
    </MosaicContainer>
  );
};

export default Mosaic;
