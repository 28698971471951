import { Box, Tooltip, Typography } from "@mui/material";
import Pages from "enums/Pages";
import debounce from "lodash/debounce";
import { useIsMount } from "hooks/useIsMount";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";
import CamerasFormDialog from "./CamerasFormDialog";
import InnerPageLayout from "layouts/InnerPageLayout";
import AliasFormDialog from "components/AliasFormDialog";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useCameraAPI, { Camera, CamerasPaginated } from "api/CameraAPI";
import { Edit, MinusCircle, Plus, Users } from "react-feather";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import { fomatHour, fromDateToUtc } from "utils/DateFunctions";

export const defaultCameraValues: Camera = {
  ["location_name"]: "",
  ["camera_name"]: "",
  ["serial_number"]: "",
  ["updated_at"]: "",
  ["camera_data"]: {
    ["request_image_unrecognized"]: false,
    ["request_image_recognized"]: false,
    active: false,
    ["serial_number"]: "",
    ["customer_id"]: "",
    side: "",
    cortex: {
      lane: 0
    }
  },
  ["shared_data"]: {
    ["owner_name"]: "",
    owner: "",
    alias: "",
    shared: []
  }
};

const CamerasPage: FC = () => {
  const CameraAPI = useCameraAPI();
  const isMount = useIsMount();
  const { showModal } = useModal();
  const [search, setSearch] = useState("");
  const [userFormOpen, setFormOpen] = useState(false);
  const [aliasFormOpen, setAliasFormOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [itemSelected, setItemSelected] = useState<Camera>(defaultCameraValues);
  const { errorHandler } = useErrorHandler();

  const openFormDialog = (item: Camera) => {
    setItemSelected(item);
    if (
      item.shared_data &&
      item.shared_data.owner !== "" &&
      item.shared_data.owner !== null &&
      item.shared_data.owner !== undefined
    ) {
      setAliasFormOpen(true);
    } else {
      setFormOpen(true);
    }
  };

  const [cameras, setCameras] = useState<Camera[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { sessionUser } = useAuth();

  const requestData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      try {
        let camerasResponse;
        if (searchValue) {
          camerasResponse = await CameraAPI.search({
            customerId: sessionUser.customer_id,
            name: searchValue,
            page: pageValue,
            pageSize: pageSizeValue
          });
        } else {
          camerasResponse = await CameraAPI.listPaginated({
            customerId: sessionUser.customer_id,
            page: pageValue,
            pageSize: pageSizeValue
          });
        }
        const camerasData = camerasResponse.data as CamerasPaginated;
        setCameras(camerasData.cameras || []);
        setPaginator({
          totalPages: camerasData.total_pages || 0,
          totalItems: camerasData.total_count || 0
        });
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(search, page, pageSize);
  }, [requestData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string, pageSizeValue: number) => {
        requestData(searchValue, page, pageSizeValue);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search, pageSize);
    }
  }, [search, fetch]);

  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.cameras"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.equipmentsAndCameras")
      },
      {
        label: t("CamerasPage.title"),
        page: Pages.CAMERAS
      }
    ]);
  }, [t, Pages]);

  const handleRemove = (cameraName: string, locationName: string) => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    showModal({
      modalType: "confirm",
      onConfirm: () => remove(customerId, cameraName, locationName),
      confirmText: t("action.remove"),
      title: t("prompt.removeConfirmTitle", { name: cameraName }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const remove = async (
    customerId: string,
    cameraName: string,
    locationName: string
  ) => {
    try {
      setIsLoading(true);
      await CameraAPI.deleteByName({ customerId, cameraName, locationName });
      snackNotification.success(t("CamerasPage.cameraRemoved"));
      requestData(search, page, pageSize);
    } catch (error) {
      errorHandler({ error });
      setIsLoading(false);
    }
  };

  const onRequestCompleted = () => {
    requestData(search, page, pageSize);
  };

  function hourDifference(difference: number): string {
    const seconds = Math.abs(Math.floor(difference / 1000));

    if (seconds > 0) {
      if (seconds < 60) {
        return `(${seconds} ${t("form.second")} ${t("NotificationPanel.ago")})`;
      }

      const minutes = Math.floor(seconds / 60);
      if (minutes < 60) {
        return `(${minutes} ${t("CorrelationReportPage.minute")} ${t(
          "NotificationPanel.ago"
        )})`;
      }

      const hours = Math.floor(minutes / 60);
      if (hours < 24) {
        return `(${hours} ${t("NotificationPanel.hour")} ${t(
          "NotificationPanel.ago"
        )})`;
      }

      const days = Math.floor(hours / 24);
      if (days < 30) {
        return `(${days} ${t("NotificationPanel.day")} ${t(
          "NotificationPanel.ago"
        )})`;
      }

      const months = Math.floor(days / 30);
      if (months < 12) {
        return `(${months} ${t("CameraDashboardPage.month")} ${t(
          "NotificationPanel.ago"
        )})`;
      }

      const years = Math.floor(months / 12);
      return `(${years} ${t("CameraDashboardPage.year")} ${t(
        "NotificationPanel.ago"
      )})`;
    }
    return "";
  }

  function formatUpdatedCameraDate(updated: string): string {
    let outputString = "";

    if (updated) {
      const updatedDate = new Date(updated);
      const currentDate = new Date();

      const diferenca = currentDate.getTime() - updatedDate.getTime();

      outputString = `${fromDateToUtc(updatedDate)} ${t("form.at")} ${fomatHour(
        updatedDate
      )} ${hourDifference(diferenca)}`;
    }

    return outputString;
  }

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("CamerasPage.title")}
          isLoading={isLoading}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("CamerasPage.searchCameras")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openFormDialog(defaultCameraValues)}
            >
              {t("CamerasPage.newCameras")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "camera_name", label: t("CamerasPage.name") },
              { key: "location_name", label: t("CamerasPage.equipment") },
              { key: "side", label: t("CamerasPage.direction") },
              { key: "serial_number", label: t("CamerasPage.serialNumber") },
              { key: "updated_at", label: t("CamerasPage.updated_at") },
              { key: "camera_data.active", label: t("CamerasPage.status") },
              {
                key: "actions",
                label: t("CamerasPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["camera_name", "asc"]}
            onHeaderSort={setCameras}
            data={cameras}
            renderRow={row => [
              <>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  {row["shared_data"] &&
                    ((row["shared_data"].owner !== "" &&
                      row["shared_data"].owner !== null &&
                      row["shared_data"].owner !== undefined) ||
                      (row["shared_data"].shared &&
                        row["shared_data"].shared.length > 0)) && (
                      <Tooltip title={t("action.shared").toString()}>
                        <Users size={12} style={{ marginRight: "6px" }} />
                      </Tooltip>
                    )}
                  <Box>
                    <strong>{row["camera_name"]}</strong>{" "}
                    {row["shared_data"]?.alias &&
                      row["shared_data"].alias !== "" &&
                      row["shared_data"].alias !== null &&
                      row["shared_data"].alias !== undefined && (
                        <Typography color="textSecondary" variant="caption">
                          {`(${row["shared_data"].alias})`}
                        </Typography>
                      )}
                  </Box>
                </Box>
              </>,
              <>
                <strong>{row["location_name"]}</strong>
              </>,
              <>
                <Typography component="div" variant="caption">
                  {t(`CameraDirection.${row["camera_data"].side}`)}
                </Typography>
              </>,
              <>
                <Typography component="div" variant="caption">
                  {row["serial_number"]}
                </Typography>
              </>,
              <>
                <Typography component="div" variant="caption">
                  {formatUpdatedCameraDate(row["updated_at"])}
                </Typography>
              </>,
              <>
                <strong>
                  {t(
                    `status.${
                      row["camera_data"].active ? "active" : "inactive"
                    }`
                  )}
                </strong>
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.edit"),
                      icon: Edit,
                      onClick: () => openFormDialog(row)
                    },
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      disabled: Boolean(row["shared_data"]?.owner),
                      onClick: () =>
                        handleRemove(row["camera_name"], row["location_name"])
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsSm={[2, 3, 4]}
            hideColumnsXs={[1, 2, 3, 4, 5, 6]}
            page={page}
            onPageChange={pageValue => requestData(search, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <CamerasFormDialog
        cameraName={itemSelected.camera_name}
        locationName={itemSelected.location_name}
        open={userFormOpen}
        setOpen={setFormOpen}
        persist
        onSave={onRequestCompleted}
      />
      <AliasFormDialog
        aliasType="camera"
        open={aliasFormOpen}
        setOpen={setAliasFormOpen}
        currentAlias={
          itemSelected.shared_data ? itemSelected.shared_data.alias : ""
        }
        item={itemSelected}
        onRequestCompleted={onRequestCompleted}
      />
    </DefaultPageLayout>
  );
};

export default CamerasPage;
