import {
  UsersPage,
  TermsPage,
  SignInPage,
  BillingPage,
  CamerasPage,
  InvoicePage,
  Error403Page,
  CustomerPage,
  LogReportPage,
  UserGuidePage,
  VerticalsPage,
  EquipmentsPage,
  UserGroupsPage,
  SpiaReportPage,
  ConfirmUserPage,
  RestrictionsPage,
  EquipmentMapPage,
  IntegrationsPage,
  CamerasMosaicPage,
  CaptureReportPage,
  CreateAccountPage,
  CustomizationPage,
  FilterCamerasPage,
  FlowDashboardPage,
  ResetPasswordPage,
  CloneDashboardPage,
  EditUserGroupsPage,
  IncidentReportPage,
  CameraDashboardPage,
  ForensicsReportPage,
  ConvoyDashboardPage,
  VehicleOverviewPage,
  MonitoringReportPage,
  ForensicsReportViewer,
  MonitoringVehiclesPage,
  RestrictionDashboardPage,
  InformationProtectionPage,
  EditUserGroupsPermissionPage,
  MonitoredVehiclesDashboardPage
} from "pages";
import { FC } from "react";
import Pages from "enums/Pages";
import history from "utils/history";
import { useAuth } from "contexts/AuthContext";
import { Router as RRouter } from "react-router";
import { canRead } from "utils/PermissionManager";
import { Redirect, Route, Switch } from "react-router-dom";
import ConvoyReportPage from "pages/ConvoyReport/ConvoyReportPage";
import MonitoredRoutesPage from "pages/Settings/MonitoredRoutes/MonitoredRoutesPage";
import SharedDataPage from "pages/Settings/SharedData/SharedDataPage";

type RouteRedirect = [boolean, string]; // Redirect to <string> if <boolean> is true

type ProtectedRouteProps = {
  exact?: boolean;
  path: string;
  search?: string;
  hash?: string;
  redirects: RouteRedirect[];
  onReject?: () => void; // Optional function that executes if rejected
};

/**
 * Creates a route that renders a redirect if the reject condition is met.
 */
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  exact = false,
  path,
  search,
  hash,
  redirects = [],
  onReject,
  children
}) => (
  <Route
    exact={exact}
    path={path}
    render={({ location }) => {
      for (const redirect of redirects) {
        if (redirect[0]) {
          if (onReject) onReject();
          return (
            <Redirect
              to={{
                pathname: redirect[1],
                search,
                hash,
                state: { from: location }
              }}
            />
          );
        }
      }
      return children;
    }}
  />
);

export const HOME_PAGE = Pages.MONITORING_VEHICLES_DASHBOARD;

/**
 * Aggregator for all routes
 */
const Router: FC = () => {
  const { sessionUser } = useAuth();

  const isSignedIn = sessionUser !== null;
  const isTermsAccepted = sessionUser?.isTermsAccepted ?? false;

  const signedInRedirects: RouteRedirect[] = [[isSignedIn, HOME_PAGE]];
  const protectedRedirects = (page?: string): RouteRedirect[] => {
    const arr: RouteRedirect[] = [
      [!isSignedIn, Pages.SIGN_IN],
      [!isTermsAccepted, Pages.TERMS]
    ];
    if (page) {
      arr.push([!canRead(page, sessionUser), Pages.ERROR_403]);
    }
    return arr;
  };

  return (
    <RRouter history={history}>
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          redirects={[
            [!isSignedIn, Pages.SIGN_IN],
            [!isTermsAccepted, Pages.TERMS],
            [true, HOME_PAGE] // Always trigger this redirection if the ones above don't
          ]}
        />
        <ProtectedRoute
          exact
          path={Pages.SIGN_IN}
          redirects={signedInRedirects}
        >
          <SignInPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.CREATE_ACCOUNT}
          redirects={signedInRedirects}
        >
          <CreateAccountPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.RESET_PASSWORD}
          redirects={signedInRedirects}
        >
          <ResetPasswordPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.CONFIRM_USER}
          redirects={signedInRedirects}
        >
          <ConfirmUserPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.TERMS}
          redirects={[
            [!isSignedIn, Pages.SIGN_IN],
            [isTermsAccepted, HOME_PAGE]
          ]}
        >
          <TermsPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.USERS}
          redirects={protectedRedirects("users")}
        >
          <UsersPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.USER_GROUPS}
          redirects={protectedRedirects("userGroups")}
        >
          <UserGroupsPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={`${Pages.USER_GROUPS}/:name`}
          redirects={protectedRedirects("userGroups")}
        >
          <EditUserGroupsPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={`${Pages.USER_GROUPS}/:name/:permission`}
          redirects={protectedRedirects("userGroups")}
        >
          <EditUserGroupsPermissionPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.SHARED_DATA}
          redirects={protectedRedirects("sharedData")}
        >
          <SharedDataPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.CUSTOMERS}
          redirects={protectedRedirects("customers")}
        >
          <CustomerPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.VERTICALS}
          redirects={protectedRedirects("verticals")}
        >
          <VerticalsPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.EQUIPMENTS}
          redirects={protectedRedirects("equipments")}
        >
          <EquipmentsPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.CAMERAS}
          redirects={protectedRedirects("cameras")}
        >
          <CamerasPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.MONITORING_VEHICLES_DASHBOARD}
          redirects={protectedRedirects("monitoredVehiclesdashboard")}
        >
          <MonitoredVehiclesDashboardPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.CAMERA_DASHBOARD}
          redirects={protectedRedirects("cameraDashboard")}
        >
          <CameraDashboardPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.FLOW_DASHBOARD}
          redirects={protectedRedirects("flowDashboard")}
        >
          <FlowDashboardPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.RESTRICTION_DASHBOARD}
          redirects={protectedRedirects("restrictionDashboard")}
        >
          <RestrictionDashboardPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.CLONE_DASHBOARD}
          redirects={protectedRedirects("cloneDashboard")}
        >
          <CloneDashboardPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.CONVOY_DASHBOARD}
          redirects={protectedRedirects("convoyDashboard")}
        >
          <ConvoyDashboardPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.USER_GUIDE}
          redirects={protectedRedirects("userGuide")}
        >
          <UserGuidePage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.MOSAIC}
          redirects={protectedRedirects("mosaic")}
        >
          <CamerasMosaicPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.SYSTEM_FILTER_CAMERAS}
          redirects={protectedRedirects("mosaic")}
        >
          <FilterCamerasPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${Pages.MONITORING_VEHICLES}/:plate/:class/:brand/:model/:color`}
          redirects={protectedRedirects("monitoringVehicles")}
        >
          <MonitoringVehiclesPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${Pages.MONITORING_VEHICLES}/:plate/:class`}
          redirects={protectedRedirects("monitoringVehicles")}
        >
          <MonitoringVehiclesPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${Pages.MONITORING_VEHICLES}/:plate`}
          redirects={protectedRedirects("monitoringVehicles")}
        >
          <MonitoringVehiclesPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.MONITORING_VEHICLES}
          redirects={protectedRedirects("monitoringVehicles")}
        >
          <MonitoringVehiclesPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${Pages.VEHICLE_OVERVIEW}/:plate`}
          redirects={protectedRedirects("vehicleOverview")}
        >
          <VehicleOverviewPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.RESTRICTION}
          redirects={protectedRedirects("restriction")}
        >
          <RestrictionsPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.MONITORED_ROUTES}
          redirects={protectedRedirects("monitoredRoutes")}
        >
          <MonitoredRoutesPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${Pages.CAPTURE_REPORT}/:plate`}
          redirects={protectedRedirects("captureReport")}
        >
          <CaptureReportPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.CAPTURE_REPORT}
          redirects={protectedRedirects("captureReport")}
        >
          <CaptureReportPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.CONVOY_REPORT}
          redirects={protectedRedirects("convoyReport")}
        >
          <ConvoyReportPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${Pages.FORENSIC_REPORT}/:report`}
          redirects={protectedRedirects("captureReport")}
        >
          <ForensicsReportViewer />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${Pages.INCIDENT_REPORT}/:plate`}
          redirects={protectedRedirects("incidentReport")}
        >
          <IncidentReportPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.INCIDENT_REPORT}
          redirects={protectedRedirects("incidentReport")}
        >
          <IncidentReportPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.FORENSIC_REPORT}
          redirects={protectedRedirects("forensicReport")}
        >
          <ForensicsReportPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.LOG_REPORT}
          redirects={protectedRedirects("logReport")}
        >
          <LogReportPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.MONITORING_REPORT}
          redirects={protectedRedirects("monitoringReport")}
        >
          <MonitoringReportPage />
        </ProtectedRoute>
        {/* <ProtectedRoute
          path={Pages.CORRELATION_REPORT}
          redirects={protectedRedirects("correlationReport")}
        >
          <CorrelationReportPage />
        </ProtectedRoute> */}
        <ProtectedRoute
          path={Pages.SPIA_REPORT}
          redirects={protectedRedirects("spiaReport")}
        >
          <SpiaReportPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.EQUIPMENT_MAP}
          redirects={protectedRedirects("equipmentMap")}
        >
          <EquipmentMapPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.BILLING}
          redirects={protectedRedirects("billing")}
        >
          <BillingPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.INVOICE}
          redirects={protectedRedirects("invoice")}
        >
          <InvoicePage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.CUSTOMIZATION}
          redirects={protectedRedirects("customization")}
        >
          <CustomizationPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.INFORMATION_PROTECTION}
          redirects={protectedRedirects("informationProtection")}
        >
          <InformationProtectionPage />
        </ProtectedRoute>
        <ProtectedRoute
          path={Pages.INTEGRATIONS}
          redirects={protectedRedirects("integrations")}
        >
          <IntegrationsPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={Pages.ERROR_403}
          redirects={protectedRedirects()}
        >
          <Error403Page />
        </ProtectedRoute>
      </Switch>
    </RRouter>
  );
};

export default Router;
