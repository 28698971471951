import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";
import { RestrictionType } from "./RestrictionAPI";

export type RestrictionParams = {
  ["customer_id"]: string;
  ["initial_date"]: string;
  ["final_date"]: string;
  ["restriction_type"]: RestrictionType[] | undefined;
  cameras: string[] | undefined;
  equipments: string[] | undefined;
  ["grouping_by_type"]: "all" | "type";
};

export type RestrictionGeolocation = {
  latitude: string;
  longitude: string;
  weight: number;
};

export type FrequencyByType = {
  type: RestrictionType;
  weight: number;
};

export type RestrictionByTypeResponse = {
  ["total_frequency"]?: { value: number };
  ["frequency_by_type"]: FrequencyByType[];
};

export type FrequencyByClass = {
  ["vehicle_class"]: string;
  weight: number;
};

export type FrequencyByDay = {
  date: string;
  weight: number;
};

export type FrequencyByWeek = {
  ["location"]: string;
  ["day_week_frequency"]: {
    ["day_week"]: string;
    weight: number | null;
  }[];
};

export type FrequencyByHour = {
  ["location"]: string;
  ["hour_frequency"]: {
    hour: string;
    weight: number | null;
  }[];
};

export type VehicleFrequency = {
  plate: string;
  ["vehicle_class"]: string;
  ["vehicle_color"]: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  weight: number;
};

export type RestrictionByTimeResponse = {
  ["frequency_by_day"]: FrequencyByDay[];
  ["frequency_by_week"]: FrequencyByWeek[];
  ["frequency_by_hour"]: FrequencyByHour[];
};

export type BiRestrictionResponse = {
  ["geographic_coordinates"]: RestrictionGeolocation[];
  ["response_by_type"]: RestrictionByTypeResponse;
  ["vehicle_class_frequency"]: FrequencyByClass[];
  ["response_by_time"]: RestrictionByTimeResponse;
  ["vehicle_violations"]: VehicleFrequency[];
};

type UseRestrictionDashboardAPIType = {
  biRestriction: (param: RestrictionParams) => Promise<BiRestrictionResponse>;
};

const useRestrictionDashboardAPI = (): UseRestrictionDashboardAPIType => {
  const API = useAxios();

  const biRestriction = async (
    filter: RestrictionParams
  ): Promise<BiRestrictionResponse> => {
    const response: AxiosResponse<BiRestrictionResponse> = await API.instance
      .post(APIEndpoints.ANALYTICS_BI_RESTRICTION, filter)
      .catch(error => {
        throw error;
      });
    return response.data;
  };

  return {
    biRestriction
  };
};

export default useRestrictionDashboardAPI;
