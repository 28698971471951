import useRestrictionsAPI, {
  Restriction,
  RestrictionPaginated
} from "api/RestrictionAPI";
import Pages from "enums/Pages";
import { debounce } from "lodash";
import { EMPTY_VALUE, setRestrictionLabelColor } from "utils/String";
import { useIsMount } from "hooks/useIsMount";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import TypeBox from "components/TypeBox";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import snackNotification from "components/SnackNotification";
import RestrictionsFormDialog from "./RestrictionsFormDialog";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";
import { Edit, Plus, MinusCircle } from "react-feather";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

const RestrictionsPage: FC = () => {
  const isMount = useIsMount();
  const { showModal } = useModal();
  const { sessionUser } = useAuth();
  const [search, setSearch] = useState("");
  const RestrictionsAPI = useRestrictionsAPI();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [restrictions, setRestrictions] = useState<Restriction[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );

  const { errorHandler } = useErrorHandler();

  const [itemSelected, setItemSelected] = useState<string>("");
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);

  const requestData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      try {
        let restrictionResponse;
        if (searchValue) {
          restrictionResponse = await RestrictionsAPI.search(
            sessionUser["customer_id"],
            searchValue,
            pageValue,
            pageSizeValue
          );
        } else {
          restrictionResponse = await RestrictionsAPI.listPaginated(
            sessionUser["customer_id"],
            pageValue,
            pageSizeValue
          );
        }
        const restrictionsResponseValue =
          restrictionResponse.data as RestrictionPaginated;
        setRestrictions(restrictionsResponseValue.restrictions || []);
        setPaginator({
          totalItems: restrictionsResponseValue.total_count || 0,
          totalPages: restrictionsResponseValue.total_pages || 0
        });
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(search, page, pageSize);
  }, [requestData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string, pageSizeValue: number) => {
        requestData(searchValue, page, pageSizeValue);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search, pageSize);
    }
  }, [search, fetch]);

  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.restrictions"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.monitoring")
      },
      {
        label: t("RestrictionsPage.restrictionRecords"),
        page: Pages.RESTRICTION
      }
    ]);
  }, [t, Pages]);

  const handleRemove = (name: string, index: string) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => remove(index),
      confirmText: t("action.confirm"),
      title: t("prompt.removeConfirmTitle", { name }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const openFormDialog = (index = "") => {
    setItemSelected(index);
    setIsFormDialogOpen(true);
  };

  const remove = async (index: string) => {
    try {
      if (sessionUser) {
        await RestrictionsAPI.deleteByIndex({
          customerId: sessionUser["customer_id"],
          index
        });
        requestData(search, page, pageSize);
        snackNotification.success(t("RestrictionsPage.restrictionRemoved"));
      }
    } catch (error) {
      errorHandler({ error });
    }
  };

  const editItem = (index: string) => {
    openFormDialog(index);
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("RestrictionsPage.restrictionRecords")}
          isLoading={isLoading}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("RestrictionsPage.searchRestriction")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openFormDialog()}
            >
              {t("RestrictionsPage.newRule")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "name", label: t("RestrictionsPage.name") },
              {
                key: "startDateTime",
                label: t("RestrictionsPage.startDateTime")
              },
              { key: "endDateTime", label: t("RestrictionsPage.endDateTime") },
              { key: "type", label: t("RestrictionsPage.restrictionType") },
              {
                key: "actions",
                label: t("RestrictionsPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["startDateTime", "asc"]}
            onHeaderSort={setRestrictions}
            data={restrictions}
            renderRow={row => [
              <>{row.name || EMPTY_VALUE}</>,
              <>{row["initial_date"] || EMPTY_VALUE}</>,
              <>{row["final_date"] || EMPTY_VALUE}</>,
              <>
                <TypeBox
                  text={t(`RestrictionsPage.type.${row.type}`)}
                  color={setRestrictionLabelColor(row.type)}
                  align="left"
                />
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.edit"),
                      icon: Edit,
                      onClick: () => editItem(row.index)
                    },
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      onClick: () => handleRemove(row.name, row.index)
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsSm={[1, 2]}
            hideColumnsXs={[1, 2]}
            page={page}
            onPageChange={pageValue => requestData(search, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <RestrictionsFormDialog
        restrictionIndex={itemSelected}
        open={isFormDialogOpen}
        setOpen={setIsFormDialogOpen}
        updateCallerContent={() => requestData(search, page, pageSize)}
      />
    </DefaultPageLayout>
  );
};

export default RestrictionsPage;
