import { FC, MouseEventHandler } from "react";
import { Position } from "google-map-react";
import { styled } from "@mui/material";
import { MapPin } from "react-feather";

const Pin = styled(MapPin)(() => ({
  position: "absolute",
  transform: "translate(-50%, -100%)",
  cursor: "pointer",
  "& circle": {
    fill: "white"
  }
}));

type Props = {
  color: string;
  onClick?: MouseEventHandler<SVGElement>;
};

const MapMarker: FC<Position & Props> = ({ color, onClick }) => (
  <Pin fill={color} size={32} onClick={onClick} />
);

export default MapMarker;
