import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type CameraDashboardParams = {
  ["initial_date"]?: string;
  ["final_date"]?: string;
  year?: number;
  month?: number;
  ["customer_id"]: string;
  ["camera"]: string;
  ["grouping"]: string;
};

type DownloadParams = {
  camera: string;
  ["customer_id"]: string;
  grouping: string;
  language?: string;
  year?: number;
  month?: number;
  ["initial_date"]?: string;
  ["final_date"]?: string;
};

type HeatmapResponse = {
  ["camera_name"]: string;
  ["hour"]: string;
  ["not_null"]: number;
};

export type CameraDashboardResponse = {
  1: HeatmapResponse[];
  2: HeatmapResponse[];
  3: HeatmapResponse[];
  4: HeatmapResponse[];
  5: HeatmapResponse[];
  6: HeatmapResponse[];
  7: HeatmapResponse[];
  8?: HeatmapResponse[];
  9?: HeatmapResponse[];
  10?: HeatmapResponse[];
  11?: HeatmapResponse[];
  12?: HeatmapResponse[];
  13?: HeatmapResponse[];
  14?: HeatmapResponse[];
  15?: HeatmapResponse[];
  16?: HeatmapResponse[];
  17?: HeatmapResponse[];
  18?: HeatmapResponse[];
  19?: HeatmapResponse[];
  20?: HeatmapResponse[];
  21?: HeatmapResponse[];
  22?: HeatmapResponse[];
  23?: HeatmapResponse[];
  24?: HeatmapResponse[];
  25?: HeatmapResponse[];
  26?: HeatmapResponse[];
  27?: HeatmapResponse[];
  28?: HeatmapResponse[];
  29?: HeatmapResponse[];
  30?: HeatmapResponse[];
  31?: HeatmapResponse[];
};

type UseCameraDashboardAPIType = {
  get: (params: CameraDashboardParams) => Promise<CameraDashboardResponse>;
  downloadCsv: (param: DownloadParams) => Promise<string>;
  downloadPdf: (param: DownloadParams) => Promise<string>;
};

const useCameraDashboardAPI = (): UseCameraDashboardAPIType => {
  const API = useAxios();

  const get = async (
    filter: CameraDashboardParams
  ): Promise<CameraDashboardResponse> => {
    const response: AxiosResponse<CameraDashboardResponse> =
      await API.instance.post(APIEndpoints.CAMERA_EFFICIENCY, filter);

    return response.data;
  };

  const downloadCsv = async (filter: DownloadParams): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.CAMERA_EFFICIENCY_DOWNLOAD,
      {
        ...filter,
        type: "csv"
      },
      {
        responseType: "blob"
      }
    );
    return response.data;
  };

  const downloadPdf = async (filter: DownloadParams): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.CAMERA_EFFICIENCY_DOWNLOAD,
      {
        ...filter,
        type: "pdf"
      }
    );
    return response.data;
  };

  return {
    get,
    downloadPdf,
    downloadCsv
  };
};

export default useCameraDashboardAPI;
