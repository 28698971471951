import {
  Box,
  Card,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  IconButton,
  TableSortLabel,
  CircularProgress
} from "@mui/material";
import Pages from "enums/Pages";
import { orderBy } from "lodash";
import Map from "components/Map";
import { format } from "date-fns";
import Order from "enums/OrderType";
import { coalesce } from "utils/String";
import { Table } from "components/Table";
import { visuallyHidden } from "@mui/utils";
import EquipmentMapMarker from "./EquipmentMapMarker";
import { fitBounds } from "google-map-react";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import useMapsAPI, { Location } from "api/MapsAPI";
import EquipmentDetails from "./EquipmentDetails";
import { Maximize2, Minimize2 } from "react-feather";
import InnerPageLayout from "layouts/InnerPageLayout";
import { useWebSocket } from "contexts/WebSocketContext";
import EquipmentStatusGuide from "./EquipmentStatusGuide";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { usePageLocation } from "contexts/PageLocationContext";
import { useEquipmentMap } from "contexts/EquipmentMapContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { createRef, FC, useCallback, useEffect, useState } from "react";

const statusColors = {
  disabled: "#0b2Bfc",
  online: "#4caf50",
  error: "#ffce09",
  offline: "#ef5350"
};

const Title = styled("h1")(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.light,
  fontSize: "16px",
  fontWeight: 500 ?? null
}));

const Subtitle = styled("h2")(({ theme }) => ({
  margin: 0,
  color: theme.palette.primary.light,
  textTransform: "uppercase",
  fontSize: "12px",
  fontWeight: 500
}));

const EquipmentMapPage: FC = () => {
  const MapsAPI = useMapsAPI();
  const { setLocation, setPageTitle } = usePageLocation();
  const { t } = useTranslation();
  const [isMapExpanded, setMapExpanded] = useState<boolean>(false);
  const toggleMapExpanded = () => setMapExpanded(!isMapExpanded);
  const { sessionUser } = useAuth();
  const [isRequestingData, setRequestingData] = useState<boolean>(false);
  const [equipments, setEquipments] = useState<Location[]>([]);
  const { center, setCenter, zoom, setZoom } = useEquipmentMap();
  const { errorHandler } = useErrorHandler();
  const [order, setOrder] = useState<Order>("asc");
  const [orderProperty, setOrderProperty] = useState<string>("location_name");

  const requestData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setRequestingData(true);
    try {
      const equipmentApiReturn = await MapsAPI.listAllByCustomer(
        sessionUser["customer_id"]
      );
      setEquipments(equipmentApiReturn);
      setOrder("asc");
      setOrderProperty("location_name");
    } catch (error) {
      errorHandler({ error });
    } finally {
      setRequestingData(false);
    }
  }, []);

  useEffect(() => {
    requestData();
  }, [requestData]);

  useEffect(() => {
    setPageTitle(t("windowTitle.equipmentMap"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.monitoring")
      },
      {
        label: t("EquipmentMapPage.title"),
        page: Pages.EQUIPMENT_MAP
      }
    ]);
  }, [t, Pages]);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [filteredStatus, setFilteredStatus] = useState<string[]>([]);
  const [modalEquipment, setModalEquipment] = useState<Location | null>(null);

  const openModal = (equipment: Location) => {
    setModalEquipment(equipment);
    setModalOpen(true);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderProperty === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderProperty(property);

    const sortEquipments = orderBy(
      equipments,
      ["", property],
      isAsc ? [false, "asc"] : [false, "desc"]
    );

    setEquipments(sortEquipments || []);
  };

  const mapContainerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!mapContainerRef?.current || !equipments) return;

    const points = equipments
      .filter(
        equipment =>
          Number(equipment.latitude) !== 0 && Number(equipment.longitude) !== 0
      )
      .map(equipment => ({
        lat: Number(equipment.latitude),
        lng: Number(equipment.longitude)
      }));

    const lats = points.map(point => point.lat);
    const lngs = points.map(point => point.lng);

    const minLat = Math.min(...lats);
    const minLng = Math.min(...lngs);

    const maxLat = Math.max(...lats);
    const maxLng = Math.max(...lngs);

    const bounds = {
      ne: { lat: maxLat, lng: maxLng },
      sw: { lat: minLat, lng: minLng }
    };

    const size = {
      width: mapContainerRef.current.clientWidth,
      height: mapContainerRef.current.clientHeight
    };

    const boundPosition = fitBounds(bounds, size);

    setCenter(boundPosition.center);
    setZoom(boundPosition.zoom);
  }, [mapContainerRef.current, equipments]);

  const { lastMessage } = useWebSocket();

  useEffect(() => {
    if (isRequestingData || !lastMessage?.data) return;

    const parsedMessage = JSON.parse(lastMessage.data);

    if (!parsedMessage?.key || parsedMessage.key !== "keepAliveData") return;

    const results = (parsedMessage.data as Location[]).filter(
      ({ location_name: id1 }) =>
        equipments.some(({ location_name: id2 }) => id2 === id1)
    );
    setEquipments(results);
  }, [lastMessage]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <Card
          variant="outlined"
          sx={{
            height: isMapExpanded ? "100%" : "500px",
            display: "flex",
            flexDirection: "column",
            background: "transparent"
          }}
        >
          <CardHeader
            title={
              <Title>
                {isRequestingData && (
                  <CircularProgress size={16} sx={{ mr: 1 }} />
                )}
                {t("EquipmentMapPage.equipmentMap")}
              </Title>
            }
            action={
              <>
                <IconButton onClick={toggleMapExpanded}>
                  {isMapExpanded && <Minimize2 />}
                  {!isMapExpanded && <Maximize2 />}
                </IconButton>
              </>
            }
          />
          <div ref={mapContainerRef} style={{ width: "100%", flexGrow: 1 }}>
            <Map
              center={center}
              zoom={zoom}
              onChange={({ center: centerValue, zoom: zoomValue }) => {
                setCenter(centerValue);
                setZoom(zoomValue);
              }}
              customControls={
                <EquipmentStatusGuide
                  statusColors={statusColors}
                  onChange={statuses => setFilteredStatus(statuses)}
                />
              }
            >
              {equipments
                .filter(
                  e => Number(e.latitude) !== 0 && Number(e.longitude) !== 0
                )
                .filter(e => filteredStatus.includes(e.status))
                .map(equipment => (
                  <EquipmentMapMarker
                    type="static"
                    key={equipment["location_name"]}
                    lat={Number(equipment.latitude)}
                    lng={Number(equipment.longitude)}
                    color={statusColors[equipment.status]}
                    onClick={() => {
                      setCenter({
                        lat: Number(equipment.latitude),
                        lng: Number(equipment.longitude)
                      });
                      openModal(equipment);
                    }}
                  />
                ))}
            </Map>
          </div>
        </Card>
        {!isMapExpanded && (
          <Box mt={3}>
            <Subtitle>{t("EquipmentMapPage.equipments")}</Subtitle>
            <Box mt={1}>
              <Card
                variant="outlined"
                sx={{
                  width: "100%",
                  background: "transparent"
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        width="15%"
                        component="th"
                        sortDirection={
                          orderProperty === "location_name" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderProperty === "location_name"}
                          direction={
                            orderProperty === "location_name" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("location_name")}
                        >
                          {t("EquipmentMapPage.equipment")}
                          {orderProperty === "location_name" ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        width="25%"
                        component="th"
                        sortDirection={
                          orderProperty === "camera_data" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderProperty === "camera_data"}
                          direction={
                            orderProperty === "camera_data" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("camera_data")}
                        >
                          {t("EquipmentMapPage.cameras")}
                          {orderProperty === "camera_data" ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        width="25%"
                        component="th"
                        sortDirection={
                          orderProperty === "address" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderProperty === "address"}
                          direction={
                            orderProperty === "address" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("address")}
                        >
                          {t("EquipmentMapPage.address")}
                          {orderProperty === "address" ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        component="th"
                        sortDirection={
                          orderProperty === "latitude" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderProperty === "latitude"}
                          direction={
                            orderProperty === "latitude" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("latitude")}
                        >
                          {t("EquipmentMapPage.latitude")}
                          {orderProperty === "latitude" ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        component="th"
                        sortDirection={
                          orderProperty === "longitude" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderProperty === "longitude"}
                          direction={
                            orderProperty === "longitude" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("longitude")}
                        >
                          {t("EquipmentMapPage.longitude")}
                          {orderProperty === "longitude" ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        component="th"
                        sortDirection={
                          orderProperty === "status" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderProperty === "status"}
                          direction={orderProperty === "status" ? order : "asc"}
                          onClick={() => handleRequestSort("status")}
                        >
                          {t("EquipmentMapPage.status.label")}
                          {orderProperty === "status" ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        component="th"
                        sortDirection={
                          orderProperty === "updated" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderProperty === "updated"}
                          direction={
                            orderProperty === "updated" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("updated")}
                        >
                          {t("EquipmentMapPage.updated")}
                          {orderProperty === "updated" ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {equipments
                      .filter(e => filteredStatus.includes(e.status))
                      .map(equipment => (
                        <TableRow
                          hover
                          key={equipment["location_name"]}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setCenter({
                              lat: Number(equipment.latitude),
                              lng: Number(equipment.longitude)
                            });
                            setZoom(15);
                            openModal(equipment);
                          }}
                        >
                          <TableCell>
                            {coalesce(equipment["location_name"])}
                          </TableCell>
                          <TableCell>
                            {coalesce(
                              equipment?.["camera_data"]
                                ?.map(camera => camera["camera_name"])
                                .join(", ")
                            )}
                          </TableCell>
                          <TableCell>{coalesce(equipment.address)}</TableCell>
                          <TableCell>{coalesce(equipment.latitude)}</TableCell>
                          <TableCell>{coalesce(equipment.longitude)}</TableCell>
                          <TableCell>
                            {equipment.status
                              ? t(`EquipmentMapPage.status.${equipment.status}`)
                              : "‒"}
                          </TableCell>
                          <TableCell>
                            {equipment.updated
                              ? format(
                                  new Date(equipment.updated),
                                  t("EquipmentMapPage.updatedFormat")
                                )
                              : "‒"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Card>
            </Box>
          </Box>
        )}
      </InnerPageLayout>
      {modalEquipment && (
        <EquipmentDetails
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          equipment={modalEquipment}
          statusColor={statusColors[modalEquipment.status]}
        />
      )}
    </DefaultPageLayout>
  );
};

export default EquipmentMapPage;
