import GoogleMapReact, {
  ChangeEventValue,
  Coords,
  MapOptions,
  Maps,
  Position
} from "google-map-react";
import { Box } from "@mui/material";
import { FC, ReactNode } from "react";
import { useLocale } from "contexts/LocaleContext";

const key = process.env.REACT_APP_MAPS_API_KEY ?? "__ENV_VARIABLE_MISSING__";

const defaultMinHeight = 300;
const defaultZoom = 13;
const defaultRadius = 20;
const defaultOpacity = 0.6;
const defaultCenter = { lat: -25.431040332176853, lng: -49.2434949242968 };

const createMapOptions = (maps: Maps): MapOptions =>
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  ({
    fullscreenControl: false,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_TOP
    },
    minZoom: 0
  });
type Props = {
  minHeight?: number;
  zoom?: number;
  center?: Coords;
  zoomLevel?: number;
  customControls?: ReactNode;
  heatmapPositions?: Position[];
  onLoad?: (map: google.maps.Map) => void;
  onChange?: (value: ChangeEventValue) => void;
  onClick?: (event: GoogleMapReact.ClickEventValue) => void;
};

const Map: FC<Props> = ({
  minHeight = defaultMinHeight,
  center = defaultCenter,
  zoom = defaultZoom,
  onChange,
  customControls,
  children,
  heatmapPositions,
  onLoad,
  onClick
}) => {
  const { language } = useLocale();
  const [mapLanguage, mapRegion] = language.split("-");

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight,
        position: "relative"
      }}
    >
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key,
          region: mapRegion,
          language: mapLanguage,
          libraries: ["visualization", "places"]
        }}
        onClick={onClick}
        onGoogleApiLoaded={({ map }) => {
          if (onLoad) {
            onLoad(map);
          }
        }}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={defaultZoom}
        zoom={zoom}
        options={createMapOptions}
        onChange={onChange}
        heatmap={{
          positions: heatmapPositions ?? [],
          options: {
            radius: defaultRadius,
            opacity: defaultOpacity
          }
        }}
      >
        {children}
      </GoogleMapReact>
      {customControls}
    </Box>
  );
};

export default Map;
