import { FC, memo, MouseEvent } from "react";
import { IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { XCircle } from "react-feather";
import {
  cloneFilter,
  GridItem,
  GridWrapper,
  GroupWrapper
} from "./AdvancedFiltersHelpers";
import {
  AdvancedFilterGroupItem,
  ArrayReferenceType,
  ConditionItem,
  ConfigurationType
} from "./AdvancedFilters";
import ConditionRow from "./ConditionRow";
import NewButtonMenu from "components/AdvancedFilters/NewButtonMenu";
import { useTranslation } from "react-i18next";

type GroupRowProps = {
  filter: AdvancedFilterGroupItem;
  configs: ConfigurationType[];
  arraysReferences?: ArrayReferenceType[];
  onFilterChange: (filter: AdvancedFilterGroupItem) => void;
  removeItem?: () => void;
  itemIndex?: number;
};

const GroupRow: FC<GroupRowProps> = ({
  filter,
  configs,
  arraysReferences,
  removeItem,
  onFilterChange,
  itemIndex
}) => {
  const { t } = useTranslation();

  const onItemValueChanged = (
    index: number,
    newFilterValue: AdvancedFilterGroupItem | ConditionItem
  ) => {
    const newFilter = cloneFilter(filter);
    newFilter.items[index] = newFilterValue;
    onFilterChange(newFilter);
  };

  const handleNewConditionClick = () => {
    const newFilter = cloneFilter(filter);
    newFilter.items.push({
      isGroup: false,
      field: "",
      operator: "",
      value: "",
      condition: "and"
    });
    onFilterChange(newFilter);
  };

  const handleNewGroupClick = () => {
    const newFilter = cloneFilter(filter);
    const newGroup: AdvancedFilterGroupItem = {
      isGroup: true,
      groupOp: "and",
      items: []
    };
    newFilter.items.push(newGroup);
    onFilterChange(newFilter);
  };

  const handleGroupOpChange = (value: string) => {
    const newFilter = cloneFilter(filter);
    newFilter.groupOp = value;
    onFilterChange(newFilter);
  };

  const handleRemoveGroupClick = (index: number) => {
    const newFilter = cloneFilter(filter);
    newFilter.items.splice(index, 1);
    onFilterChange(newFilter);
  };

  const isDisabledConditionOption = !itemIndex || itemIndex === 0;

  return (
    <GroupWrapper>
      <GridWrapper>
        <GridItem className="iconButton">
          {removeItem && (
            <IconButton disableRipple onClick={removeItem}>
              <XCircle size={18} />
            </IconButton>
          )}
        </GridItem>
        {!isDisabledConditionOption && (
          <GridItem className="conditionSelect">
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={isDisabledConditionOption ? "" : filter.groupOp}
              exclusive
              onChange={(
                _event: MouseEvent<HTMLElement>,
                value: string | null
              ) => handleGroupOpChange(value ?? "and")}
              disabled={isDisabledConditionOption}
              sx={{
                opacity: isDisabledConditionOption ? 0.5 : 1
              }}
            >
              <ToggleButton value="and">
                {t("AdvancedFilterComponent.and")}
              </ToggleButton>
              <ToggleButton value="or">
                {t("AdvancedFilterComponent.or")}
              </ToggleButton>
            </ToggleButtonGroup>
          </GridItem>
        )}
        <GridItem className="addButton">
          <NewButtonMenu
            onHandleNewGroupClick={() => handleNewGroupClick()}
            onHandleNewConditionClick={() => handleNewConditionClick()}
          />
        </GridItem>
      </GridWrapper>
      <div className="group-items">
        {filter.items.map((item, itemKey) => {
          const filterItemChangeHandler = (
            value: AdvancedFilterGroupItem | ConditionItem
          ) => onItemValueChanged(itemKey, value);
          const removeItemClickHandler = () => handleRemoveGroupClick(itemKey);
          if (item.isGroup) {
            return (
              <GroupRow
                key={itemKey}
                arraysReferences={arraysReferences}
                configs={configs}
                filter={item as AdvancedFilterGroupItem}
                onFilterChange={filterItemChangeHandler}
                removeItem={removeItemClickHandler}
                itemIndex={itemKey}
              />
            );
          }
          return (
            <ConditionRow
              key={itemKey}
              item={item as ConditionItem}
              configs={configs}
              arraysReferences={arraysReferences}
              onFilterChange={filterItemChangeHandler}
              removeItem={removeItemClickHandler}
            />
          );
        })}
      </div>
    </GroupWrapper>
  );
};

export default memo(GroupRow);
