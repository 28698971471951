import Map from "components/Map";
import { FC, useEffect, useState } from "react";
import CircleMapMarker from "components/CircleMapMarker";
import ConvoyStatusGuide from "./ConvoyStatusGuide";
import { Coords, Position } from "google-map-react";
import { Box, Skeleton, styled } from "@mui/material";
import { ConvoyLocation } from "api/ConvoyDashboardAPI";

const Container = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  overflow: "hidden",
  display: "flex",
  height: 350
}));

interface ConvoyMapProps {
  center: Coords;
  zoom: number;
  isLoading: boolean;
  totalConvoys: number;
  convoyLocations: ConvoyLocation[];
}

const ConvoyMap: FC<ConvoyMapProps> = ({
  center,
  zoom,
  isLoading,
  totalConvoys,
  convoyLocations
}) => {
  const [geolocations, setGeolocations] = useState<Position[]>([]);

  useEffect(() => {
    if (convoyLocations.length > 0) {
      const configGeographic: Position[] = [];
      convoyLocations.forEach(location => {
        configGeographic.push({
          lat: Number(location.latitude),
          lng: Number(location.longitude)
        });
      });
      setGeolocations(configGeographic || []);
    } else {
      setGeolocations([]);
    }
  }, [convoyLocations]);

  return (
    <Container>
      <div
        style={{
          flexGrow: 1,
          width: "100%"
        }}
      >
        {isLoading && <Skeleton variant="rectangular" height={348} />}
        {!isLoading && (
          <Map
            center={center}
            zoom={zoom}
            customControls={<ConvoyStatusGuide total={totalConvoys} />}
          >
            {geolocations.map((location, idx) => (
              <CircleMapMarker
                key={location.lat + location.lng + idx}
                lat={location.lat}
                lng={location.lng}
              />
            ))}
          </Map>
        )}
      </div>
    </Container>
  );
};

export default ConvoyMap;
