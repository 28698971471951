import {
  ChangeEvent,
  FC,
  FormEvent,
  MouseEvent,
  useEffect,
  useState
} from "react";
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  styled,
  Toolbar as MToolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Pages from "enums/Pages";
import Button from "components/Button";
import Breadcrumb from "layouts/Breadcrumb";
import { useHistory } from "react-router-dom";
import SideMenuBar from "layouts/SideMenuBar";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { Trans, useTranslation } from "react-i18next";
import { Bell, LogOut, Menu, Search } from "react-feather";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useTheme as useThemeContext } from "contexts/ThemeContext";
import { useNotificationPanel } from "contexts/NotificationPanelContext";
import NotificationPanel from "components/notification/NotificationPanel";
import buildInfo from "../build-info.json";
import { format, parseISO } from "date-fns";

const Toolbar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "80px",
  boxShadow: "none",
  borderBottom: `1px solid${theme.palette.grey["200"]}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  color: theme.palette.primary.light,
  div: {
    display: "flex",
    alignItems: "center"
  }
}));

const Link = styled("a")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "12px",
  marginLeft: "3px",
  color: theme.palette.sideBar?.contrastText
}));

const Footer = styled(MToolbar)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "12px",
  minHeight: "40px",
  justifyContent: "center",
  color: theme.palette.sideBar?.contrastText,
  backgroundColor: theme.palette.sideBar?.main
}));

const DefaultPageLayout: FC = ({ children }) => {
  const theme = useTheme();
  const { hasMessageToRead } = useNotificationPanel();
  const { isOpenSideBar, setIsOpenSideBar } = useThemeContext();
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const { errorHandler } = useErrorHandler();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isMobile || isTablet) {
      setIsOpenSideBar(false);
    }
  }, [isMobile, isTablet]);

  const handleSignOut = async () => {
    try {
      snackNotification.close();
      await signOut();
    } catch (error) {
      errorHandler({ error });
    }
  };

  const history = useHistory();

  const handleSearchChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    if ((value !== "" && !/^[a-zA-Z0-9?*]+$/.test(value)) || value.length > 7)
      return;
    setSearch(value.toUpperCase());
  };

  const handleSearchSubmit = (
    event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (search.trim() === "") {
      return;
    }
    history.push(`${Pages.CAPTURE_REPORT}/${encodeURIComponent(search)}`);
    setIsSearch(false);
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      {(isOpenSideBar || (!isOpenSideBar && !isMobile && !isTablet)) && (
        <SideMenuBar
          isOpenSideBar={isOpenSideBar}
          setIsOpenSideBar={setIsOpenSideBar}
        />
      )}
      <Box
        data-testid="app-main"
        component="main"
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Toolbar
          position="fixed"
          sx={{
            padding:
              !isOpenSideBar || isMobile || isTablet
                ? "0 16px 0px 96px"
                : "0 26px 0px 310px",
            marginLeft: "auto",
            width: { sm: "100%" },
            displayPrint: "none"
          }}
        >
          <div>
            {(isMobile || isTablet) && !isOpenSideBar && (
              <IconButton
                sx={{
                  marginLeft: "-80px"
                }}
                onClick={() => {
                  setIsOpenSideBar(true);
                }}
              >
                <Menu />
              </IconButton>
            )}
            {!isMobile && !isTablet && (
              <div>
                <Breadcrumb />
              </div>
            )}
          </div>
          <div>
            {isMobile && !isSearch && (
              <div style={{ marginRight: "8px" }}>
                <IconButton
                  onClick={() => {
                    setIsSearch(true);
                  }}
                >
                  <Search />
                </IconButton>
              </div>
            )}
            <div>
              {(isSearch || !isMobile) && (
                <div style={{ marginRight: "16px" }}>
                  <Tooltip
                    title={t("DefaultPageLayout.tooltipPlate").toString()}
                  >
                    <form onSubmit={handleSearchSubmit}>
                      <InputField
                        gutter={false}
                        customProps={{
                          value: search,
                          onChange: handleSearchChange,
                          endAdornment: (
                            <IconButton onClick={handleSearchSubmit}>
                              <Search />
                            </IconButton>
                          ),
                          placeholder: t("DefaultPageLayout.searchGeneral")
                        }}
                      />
                    </form>
                  </Tooltip>
                </div>
              )}
              <Tooltip
                title={t("DefaultPageLayout.notificationPanel").toString()}
              >
                <IconButton
                  sx={{ marginRight: "16px" }}
                  onClick={() => {
                    setIsFilterOpen(true);
                  }}
                >
                  <Box sx={{ color: "action.active" }}>
                    {hasMessageToRead && (
                      <Badge
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: theme.palette.warning.main,
                            top: "4px",
                            right: "6px",
                            boxShadow: `0px 0px 0px 1px ${theme.palette.background.default}`
                          }
                        }}
                        color="secondary"
                        variant="dot"
                      >
                        <Bell />
                      </Badge>
                    )}
                    {!hasMessageToRead && <Bell />}
                  </Box>
                </IconButton>
              </Tooltip>
              {isMobile || isTablet ? (
                <IconButton onClick={handleSignOut}>
                  <LogOut />
                </IconButton>
              ) : (
                <Button
                  color={theme.palette.primary.light}
                  customProps={{
                    variant: "text",
                    startIcon: <LogOut />,
                    onClick: handleSignOut
                  }}
                >
                  {t("action.signOut")}
                </Button>
              )}
            </div>
          </div>
        </Toolbar>
        <div
          data-testid="content-main"
          style={{
            flexGrow: 1,
            marginTop: "72px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {children}
        </div>
        <Box
          sx={{
            height: 40
          }}
        />
        <Footer
          variant="dense"
          sx={{
            marginLeft: isMobile ? "unset" : "auto",
            width: { sm: "100%" },
            displayPrint: "none",
            position: "fixed",
            top: "auto",
            bottom: 0,
            left: 0,
            zIndex: 1,
            padding: isOpenSideBar
              ? "5px 26px 5px 310px !important"
              : isMobile || isTablet
              ? "5px 16px 5px 16px !important"
              : "5px 16px 5px 96px !important"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="caption" align="center">
              <Trans
                i18nKey="DefaultPageLayout.buildFrom"
                values={{
                  revision: buildInfo.revision,
                  date: format(parseISO(buildInfo.date), t("form.dateFormat"))
                }}
                components={[
                  <Link
                    key={0}
                    sx={{ fontWeight: "bold", cursor: "copy" }}
                    onClick={() =>
                      navigator.clipboard.writeText(buildInfo.revision)
                    }
                  />
                ]}
              />
              &nbsp;|&nbsp;
              <span>
                Powered by
                <Link href="https://www.pumatronix.com.br">
                  <strong>Pumatronix</strong>
                </Link>
                ®
              </span>
            </Typography>
          </div>
        </Footer>
        <NotificationPanel open={isFilterOpen} setOpen={setIsFilterOpen} />
      </Box>
    </Box>
  );
};

export default DefaultPageLayout;
