import { Auth } from "api/AuthAPI";

const READ = "read";
const WRITE = "write";

type PermissionType = typeof READ | typeof WRITE; // WRITE supersedes READ, as in, WRITE can also READ

type Permissions = Record<string, Record<string, PermissionType>>;

const permissions: Permissions = {
  cloneDashboard: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  convoyDashboard: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  restrictionDashboard: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  monitoredVehiclesdashboard: {
    // analytics
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    //"Cliente/Móvel": READ, // FIXME none set in dynamodb
    "Cliente/Usuário": WRITE,
    //"Cliente/VideoWall": READ, // FIXME none set in dynamodb
    //"Administrador/Auditor": READ, // FIXME none set in dynamodb
    "Administrador/Coordenador": READ
    //"Administrador/Financeiro": READ // FIXME none set in dynamodb
  },
  cameraDashboard: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  flowDashboard: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  systemMenu: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  monitoringMenu: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  mosaic: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  equipmentMap: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  monitoringVehicles: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  vehicleOverview: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  restriction: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  monitoredRoutes: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  equipmentsAndCamerasMenu: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  equipments: {
    // location, location/list, location/partial (read)
    // location/create, location/update, location/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  cameras: {
    // camera, camera/list, camera/partial (read)
    // camera/create, camera/update, camera/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  reportsMenu: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  dashboardsMenu: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  captureReport: {
    // register/list, register/download (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  convoyReport: {
    // convoy/list, convoy/download (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  forensicReport: {
    // register/list, register/download (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  incidentReport: {
    // register/list, register/download (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  correlationReport: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  monitoringReport: {
    // report/monitoring-plate, report/download-monitoring-plate (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  spiaReport: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  logReport: {
    // logs (read)
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  settingsMenu: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    // "Cliente/Convidado": READ,
    // "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  accessControlMenu: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  users: {
    // user, user/list, user/partial (read)
    // user/update (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  userGroups: {
    // user-group, user-group/list, user-group/partial (read)
    // user-group/create, user-group/update, user-group/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  sharedData: {
    // user-group, user-group/list, user-group/partial (read)
    // user-group/create, user-group/update, user-group/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  customers: {
    // customer, customer/list, customer/partial (read)
    // customer/create, customer/update, customer/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE
  },
  verticals: {
    // vertical, vertical/list (read)
    // vertical/create, vertical/update, vertical/delete (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  billingMenu: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  billing: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  invoice: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": READ
  },
  customizationMenu: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    // "Cliente/Convidado": READ,
    // "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  customization: {
    // customization (read)
    // customization/set (write)
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    // "Cliente/Convidado": READ,
    // "Cliente/Usuário": READ,
    "Administrador/Coordenador": WRITE
  },
  integrations: {
    // integration/*
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    // "Cliente/Usuário": WRITE,
    "Administrador/Coordenador": READ
  },
  informationProtection: {
    "Pumatronix/Master": WRITE,
    "Pumatronix/Suporte": WRITE,
    "Administrador/Coordenador": WRITE
  },
  userGuide: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  },
  userAgreement: {
    "Pumatronix/Master": READ,
    "Pumatronix/Suporte": READ,
    "Cliente/Convidado": READ,
    "Cliente/Usuário": READ,
    "Administrador/Coordenador": READ
  }
};

export const canAccess = (
  feature: string,
  user: Auth["user"] | null,
  type: PermissionType
): boolean => {
  if (!user) return false;

  const featurePermissions = permissions[feature];
  if (!featurePermissions) return false;

  const userLevel = user["level_name"];
  if (!userLevel) return false;

  const userSubLevels = user["sub_level_name"];
  if (!userSubLevels || userSubLevels.length < 1) return false;

  for (const userSubLevel of userSubLevels) {
    const fullLevel = `${userLevel}/${userSubLevel}`;
    const permission = featurePermissions[fullLevel];
    if (permission === WRITE || permission === type) {
      return true;
    }
  }

  return false;
};

export const canRead = (feature: string, user: Auth["user"] | null): boolean =>
  canAccess(feature, user, READ);

export const canWrite = (feature: string, user: Auth["user"] | null): boolean =>
  canAccess(feature, user, WRITE);

export default Permissions;
