import {
  Backdrop,
  Box,
  Collapse,
  Grid,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clipboard,
  FileText,
  HelpCircle,
  Monitor,
  PieChart,
  Settings,
  X
} from "react-feather";
import Flag from "components/Flag";
import Button from "components/Button";
import Avatar from "components/Avatar";
import { coalesce } from "utils/String";
import Languages from "enums/Languages";
import MuiDrawer from "@mui/material/Drawer";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { ColorLuminance } from "utils/ColorLuminance";
import { CSSObject, Theme } from "@mui/material/styles";
import MyAccountDialog from "components/MyAccountDialog";
import { useHistory, useLocation } from "react-router-dom";
import { useCustomization } from "contexts/CustomizationContext";
import { useTheme as useLinceTheme } from "contexts/ThemeContext";
import { FC, MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import { getLanguageByValue, useLocale } from "contexts/LocaleContext";
import Pages from "enums/Pages";
import userGuidePtBr from "../assets/userguide_pt-BR.pdf";
import { canRead } from "utils/PermissionManager";
import { useMainMenu } from "contexts/MainMenuContext";
import userAgreementPtBr from "../assets/contract_pt-BR.pdf";

type SubMenuItem = {
  key: string;
  label?: string;
  display?: boolean;
  items: {
    key: string;
    label: string;
    path: Pages;
    display?: boolean;
  }[];
};

type AppMenuItem = {
  key: string;
  title: string;
  isOpen?: boolean;
  display?: boolean;
  path?: Pages;
  icon: ReactNode | string;
  subMenu?: SubMenuItem[];
};

type AppMenu = AppMenuItem[];

export type InnerPageMenu = {
  key: string;
  title: string;
  display?: boolean;
  items: {
    key: string;
    label: string;
    path: Pages;
    display?: boolean;
  }[];
}[];

const drawerWidth = 286;

const flagValue = {
  [Languages.EN]: "us",
  [Languages.ES]: "es",
  [Languages.PT]: "br"
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`
});

const UserActions = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end"
}));

const UserBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.sideBar?.main,
  color: theme.palette.sideBar?.contrastText,
  maxWidth: "250px",
  width: "100%",
  borderRadius: "10px",
  margin: `${theme.spacing(2)} ${theme.spacing(0.5)} ${theme.spacing(
    2
  )} ${theme.spacing(0.5)}`,
  padding: "10px",
  "& .textBox": {
    flexGrow: 1,
    marginLeft: theme.spacing(1)
  },
  "& .client": {
    fontWeight: 600
  },
  "& .username": {
    fontSize: 12,
    color: theme.palette.sideBar?.contrastText,
    opacity: 0.6
  }
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  "& .MuiPaper-root": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.sideBar?.dark,
    borderColor: theme.palette.sideBar?.main
  },
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const SideMenu = styled(List)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  svg: {
    color: theme.palette.sideBar?.light
  },
  "& .MuiListItemText-root span": {
    color: theme.palette.sideBar?.light,
    fontWeight: 500,
    fontSize: "14px"
  },
  "& .active .MuiListItemText-root span": {
    color: theme.palette.sideBar?.contrastText
  },
  "& .active .MuiListItemIcon-root svg": {
    color: theme.palette.sideBar?.contrastText
  },

  "& .active svg": {
    color: theme.palette.sideBar?.contrastText
  },
  "& .active > span": {
    color: theme.palette.sideBar?.contrastText
  },
  "& .active .MuiButtonBase-root": {
    borderLeft: `2px solid ${theme.palette.common.white}`,
    fontWeight: 600,
    svg: {
      color: theme.palette.common.white
    }
  },
  "& .active .MuiButtonBase-root .MuiTypography-root": {
    color: theme.palette.common.white
  },
  "& .MuiButtonBase-root": {
    height: "50px"
  },
  "& .subMenu-list": {
    position: "relative"
  },
  "& .subMenu-list .MuiListItemButton-root": {
    height: 40
  },
  "& .subMenu-list::before": {
    position: "absolute",
    display: "block",
    content: "''",
    width: "3px",
    height: "100%",
    background: theme.palette.sideBar?.light,
    borderRadius: "10px",
    left: "27px"
  }
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1)
}));

const SubMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: ColorLuminance(theme.palette.sideBar?.main || "#fff", -0.3),
    padding: "11px 10px 8px 8px"
  },
  "& .MuiList-root": {
    position: "relative",
    padding: 0
  },
  "& .MuiList-root .MuiMenuItem-root": {
    height: "40px",
    color: theme.palette.sideBar?.light,
    fontSize: "14px",
    fontWeight: 500,
    borderLeft: `3px solid ${theme.palette.sideBar?.light}`
  },
  "& .active": {
    color: `${theme.palette.sideBar?.contrastText} !important`
  }
}));

const userGuides = {
  [Languages.EN]: userGuidePtBr,
  [Languages.ES]: userGuidePtBr,
  [Languages.PT]: userGuidePtBr
};

const userAgreements = {
  [Languages.EN]: userAgreementPtBr,
  [Languages.ES]: userAgreementPtBr,
  [Languages.PT]: userAgreementPtBr
};

type SideMenuBarProps = {
  isOpenSideBar: boolean;
  setIsOpenSideBar: (value: boolean) => void;
};

const SideMenuBar: FC<SideMenuBarProps> = ({
  isOpenSideBar,
  setIsOpenSideBar
}) => {
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const { sessionUser } = useAuth();
  const { language, setLanguage } = useLocale();
  const [currentTitleItem, setCurrentTitleItem] = useState<string>("");
  const [currentItem, setCurrentItem] = useState<string>("");
  const openMyAccountDialog = () => setMyAccountDialogOpen(true);
  const [userBoxExpanded, setUserBoxExpanded] = useState<boolean>(false);
  const toggleUserBoxExpand = () => setUserBoxExpanded(!userBoxExpanded);
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const useLTheme = useLinceTheme();
  const userGuide = userGuides[language];
  const userAgreement = userAgreements[language];
  const [isMyAccountDialogOpen, setMyAccountDialogOpen] =
    useState<boolean>(false);

  const { openItems, setOpenItems } = useMainMenu();

  const menu: AppMenu = [
    {
      key: "dashboards",
      title: "menu.dashboards",
      icon: <PieChart />,
      isOpen: openItems.dashboards,
      display: canRead("dashboardsMenu", sessionUser),
      subMenu: [
        {
          key: "main",
          display: true,
          items: [
            {
              key: "dashboard",
              label: "menu.dashboard",
              path: Pages.MONITORING_VEHICLES_DASHBOARD,
              display: canRead("monitoredVehiclesdashboard", sessionUser)
            },
            {
              key: "flowDashboard",
              label: "menu.flowDashboard",
              path: Pages.FLOW_DASHBOARD,
              display: canRead("flowDashboard", sessionUser)
            },
            {
              key: "cameraDashboard",
              label: "menu.cameraDashboard",
              path: Pages.CAMERA_DASHBOARD,
              display: canRead("cameraDashboard", sessionUser)
            },
            /*{
              key: "cloneDashboard",
              label: "menu.cloneDashboard",
              path: Pages.CLONE_DASHBOARD,
              display: canRead("cloneDashboard", sessionUser)
            },
            {
              key: "convoyDashboard",
              label: "menu.convoyDashboard",
              path: Pages.CONVOY_DASHBOARD,
              display: canRead("convoyDashboard", sessionUser)
            },*/
            {
              key: "restrictionDashboard",
              label: "menu.restrictionDashboard",
              path: Pages.RESTRICTION_DASHBOARD,
              display: canRead("restrictionDashboard", sessionUser)
            }
          ]
        }
      ]
    },
    {
      key: "system",
      title: "menu.system",
      icon: <Monitor />,
      isOpen: openItems.system,
      display: canRead("systemMenu", sessionUser),
      subMenu: [
        {
          key: "monitoring",
          label: "menu.monitoring",
          display: canRead("monitoringMenu", sessionUser),
          items: [
            {
              key: Pages.MOSAIC,
              label: "CamerasMosaicPage.title",
              path: Pages.MOSAIC,
              display: canRead("mosaic", sessionUser)
            },
            {
              key: Pages.EQUIPMENT_MAP,
              label: "EquipmentMapPage.title",
              path: Pages.EQUIPMENT_MAP,
              display: canRead("equipmentMap", sessionUser)
            },
            {
              key: Pages.MONITORING_VEHICLES,
              label: "MonitoringVehicles.title",
              path: Pages.MONITORING_VEHICLES,
              display: canRead("monitoringVehicles", sessionUser)
            },
            {
              key: Pages.RESTRICTION,
              label: "RestrictionsPage.restrictionRecords",
              path: Pages.RESTRICTION,
              display: canRead("restriction", sessionUser)
            },
            {
              key: Pages.MONITORED_ROUTES,
              label: "MonitoredRoutesPage.title",
              path: Pages.MONITORED_ROUTES,
              display: canRead("monitoredRoutes", sessionUser)
            }
          ]
        },
        {
          key: "equipmentAndCamera",
          label: "menu.equipmentsAndCameras",
          display: canRead("equipmentsAndCamerasMenu", sessionUser),
          items: [
            {
              key: Pages.EQUIPMENTS,
              label: "EquipmentsPage.title",
              path: Pages.EQUIPMENTS,
              display: canRead("equipments", sessionUser)
            },
            {
              key: Pages.CAMERAS,
              label: "CamerasPage.title",
              path: Pages.CAMERAS,
              display: canRead("cameras", sessionUser)
            }
          ]
        }
      ]
    },
    {
      key: "reports",
      title: "menu.reports",
      icon: <FileText />,
      isOpen: openItems.reports,
      display: canRead("reportsMenu", sessionUser),
      subMenu: [
        {
          key: "main",
          display: true,
          items: [
            {
              key: Pages.CAPTURE_REPORT,
              label: "CaptureReportPage.title",
              path: Pages.CAPTURE_REPORT,
              display: canRead("captureReport", sessionUser)
            },
            {
              key: Pages.CONVOY_REPORT,
              label: "ConvoyReportPage.title",
              path: Pages.CONVOY_REPORT,
              display: canRead("convoyReport", sessionUser)
            },
            /* {
              key: Pages.CORRELATION_REPORT,
              label: "CorrelationReportPage.title",
              path: Pages.CORRELATION_REPORT,
              display: canRead("correlationReport", sessionUser)
            }, */
            {
              key: Pages.MONITORING_REPORT,
              label: "MonitoringReportPage.title",
              path: Pages.MONITORING_REPORT,
              display: canRead("monitoringReport", sessionUser)
            },
            {
              key: Pages.SPIA_REPORT,
              label: "SpiaReportPage.title",
              path: Pages.SPIA_REPORT,
              display: canRead("spiaReport", sessionUser)
            },
            {
              key: Pages.LOG_REPORT,
              label: "LogReportPage.title",
              path: Pages.LOG_REPORT,
              display: canRead("logReport", sessionUser)
            },
            {
              key: Pages.FORENSIC_REPORT,
              label: "ForensicsReportPage.title",
              path: Pages.FORENSIC_REPORT,
              display: canRead("forensicReport", sessionUser)
            },
            {
              key: Pages.INCIDENT_REPORT,
              label: "IncidentReportPage.title",
              path: Pages.INCIDENT_REPORT,
              display: canRead("incidentReport", sessionUser)
            }
          ]
        }
      ]
    },
    {
      key: "settings",
      title: "menu.settings",
      icon: <Settings />,
      isOpen: openItems.settings,
      display: canRead("settingsMenu", sessionUser),
      subMenu: [
        {
          key: "accessControl",
          label: "menu.accessControl",
          display: canRead("accessControlMenu", sessionUser),
          items: [
            {
              key: Pages.USERS,
              label: "UsersPage.title",
              path: Pages.USERS,
              display: canRead("users", sessionUser)
            },
            {
              key: Pages.USER_GROUPS,
              label: "UserGroupsPage.title",
              path: Pages.USER_GROUPS,
              display: canRead("userGroups", sessionUser)
            },
            {
              key: Pages.SHARED_DATA,
              label: "ShareDataPage.title",
              path: Pages.SHARED_DATA,
              display: canRead("sharedData", sessionUser)
            },
            {
              key: Pages.CUSTOMERS,
              label: "CustomerPage.title",
              path: Pages.CUSTOMERS,
              display: canRead("customers", sessionUser)
            }
            /*
            {
              key: Pages.VERTICALS,
              label: "VerticalsPage.title",
              path: Pages.VERTICALS,
              display: canRead("verticals", sessionUser)
            }
            */
          ]
        },
        /* {
          key: "billing",
          label: "menu.billing",
          display: canRead("billingMenu", sessionUser),
          items: [
            {
              key: Pages.BILLING,
              label: "BillingPage.title",
              path: Pages.BILLING,
              display: canRead("billing", sessionUser)
            },
            {
              key: Pages.INVOICE,
              label: "InvoicePage.title",
              path: Pages.INVOICE,
              display: canRead("invoice", sessionUser)
            }
          ]
        }, */
        {
          key: "customization",
          label: "SystemPageLayout.system",
          display: canRead("customizationMenu", sessionUser),
          items: [
            {
              key: Pages.CUSTOMIZATION,
              label: "CustomizationPage.title",
              path: Pages.CUSTOMIZATION,
              display: canRead("customization", sessionUser)
            },
            {
              key: Pages.INTEGRATIONS,
              label: "IntegrationsPage.title",
              path: Pages.INTEGRATIONS,
              display: canRead("integrations", sessionUser)
            },
            {
              key: Pages.INFORMATION_PROTECTION,
              label: "InformationProtectionPage.title",
              path: Pages.INFORMATION_PROTECTION,
              display: canRead("informationProtection", sessionUser)
            }
          ]
        }
      ]
    }
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const openLanguageMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setLanguageMenuAnchorEl(event.currentTarget);
    setLanguageMenuOpen(true);
  };

  const location = useLocation();

  useEffect(() => {
    setCurrentTitleItem(location.pathname.split("/")[1]);
    setCurrentItem(location.pathname);
  }, [location]);

  const closeLanguageMenu = () => {
    setLanguageMenuAnchorEl(null);
    setLanguageMenuOpen(false);
  };

  const changeLanguage = (lang: string) => {
    setLanguage(getLanguageByValue(lang));
    closeLanguageMenu();
  };

  const handleDrawer = () => {
    setIsOpenSideBar(!isOpenSideBar);
  };

  const history = useHistory();

  const [subMenuListActive, setSubMenuListActive] = useState<
    SubMenuItem[] | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpenSubMenuItems = Boolean(anchorEl);

  const handleMenuItemClick = (
    item: AppMenuItem,
    event: MouseEvent<HTMLElement>
  ) => {
    if (item.path) {
      history.push(item.path);
    }
    if (isOpenSideBar && item.subMenu?.length) {
      setOpenItems({
        ...openItems,
        [item.key]: !item.isOpen
      });
    }
    if (!isOpenSideBar && item.subMenu?.length) {
      setAnchorEl(event.currentTarget);
      setSubMenuListActive(item.subMenu);
    }
    if (expandedSection === item.key) {
      setExpandedSection(null);
    } else {
      setExpandedSection(item.key);
    }
  };

  const handleCloseSubMenuItems = () => {
    setAnchorEl(null);
  };

  const { appTitle, imgHeader } = useCustomization();

  const divRef = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    if (divRef.current) {
      useLTheme.setSideBarScrollPosition(divRef.current.scrollTop);
    }
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = useLTheme.sideBarScrollPosition;
    }
  }, [location]);

  const verifyEmptyTitle = (title: string): string => {
    const emptyTitle = coalesce(title);
    return emptyTitle === "‒" ? "Pumatronix Lince" : emptyTitle;
  };

  return (
    <>
      {(isMobile || isTablet) && (
        <Backdrop
          open={isOpenSideBar}
          sx={{
            zIndex: 1,
            displayPrint: "none"
          }}
          onClick={() => setIsOpenSideBar(false)}
        />
      )}
      <Drawer
        variant="permanent"
        open={isOpenSideBar}
        sx={{
          width: isMobile || isTablet ? "auto" : "286",
          displayPrint: "none"
        }}
        PaperProps={{
          onScroll,
          ref: divRef
        }}
      >
        <DrawerHeader>
          {isOpenSideBar ? (
            <UserBox sx={{ margin: "16px 16px 16px 4px" }}>
              <Box>
                <Grid container wrap="nowrap">
                  <Grid item>
                    <Link onClick={openMyAccountDialog}>
                      <Avatar
                        name={appTitle ?? "Pumatronix Lince"}
                        imgSrc={imgHeader}
                        customProps={{
                          style: { cursor: "pointer" }
                        }}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Box className="textBox">
                      <Tooltip placement="right" title={coalesce(appTitle)}>
                        <Typography noWrap className="client">
                          {verifyEmptyTitle(appTitle)}
                        </Typography>
                      </Tooltip>
                      <Typography noWrap className="username">
                        {coalesce(sessionUser?.name)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={toggleUserBoxExpand}>
                      {userBoxExpanded && (
                        <ChevronUp
                          color={theme.palette.sideBar?.contrastText}
                        />
                      )}
                      {!userBoxExpanded && (
                        <ChevronDown
                          color={theme.palette.sideBar?.contrastText}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
                {userBoxExpanded && (
                  <UserActions>
                    <Button
                      color={theme.palette.sideBar?.contrastText}
                      customProps={{
                        variant: "text",
                        onClick: openMyAccountDialog
                      }}
                    >
                      {t("DefaultPageLayout.myAccount")}
                    </Button>
                    <Button
                      color={theme.palette.sideBar?.contrastText}
                      customProps={{
                        endIcon: "▾",
                        variant: "text",
                        onClick: openLanguageMenu
                      }}
                    >
                      <Flag country={flagValue[language]} />
                    </Button>
                    <Menu
                      open={isLanguageMenuOpen}
                      anchorEl={languageMenuAnchorEl}
                      onClose={closeLanguageMenu}
                      MenuListProps={{
                        ["aria-labelledby"]: "basic-button"
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                    >
                      <MenuItem onClick={() => changeLanguage("en-US")}>
                        <Flag country="us" />
                        &nbsp; English (United States)
                      </MenuItem>
                      <MenuItem onClick={() => changeLanguage("es-ES")}>
                        <Flag country="es" />
                        &nbsp; Español
                      </MenuItem>
                      <MenuItem onClick={() => changeLanguage("pt-BR")}>
                        <Flag country="br" />
                        &nbsp; Português (Brasil)
                      </MenuItem>
                    </Menu>
                  </UserActions>
                )}
              </Box>
            </UserBox>
          ) : (
            <UserBox sx={{ background: "none", margin: "16px -1px 16px 4px" }}>
              <Link onClick={openMyAccountDialog}>
                <Avatar
                  name={appTitle ?? "Pumatronix"}
                  imgSrc={imgHeader}
                  size="small"
                  customProps={{
                    style: { cursor: "pointer" }
                  }}
                />
              </Link>
            </UserBox>
          )}
          <Tooltip
            placement="right"
            title={
              isOpenSideBar
                ? t("DefaultPageLayout.tooltipRetract").toString()
                : t("DefaultPageLayout.tooltipExpand").toString()
            }
          >
            <IconButton
              sx={{
                width: "24px",
                height: "24px",
                color: theme.palette.sideBar?.contrastText,
                position: "fixed",
                marginRight: "-21px",
                top: "30px",
                borderRight: `1px solid ${theme.palette.background.paper}`,
                backgroundColor: theme.palette.sideBar?.dark,
                "&:hover": {
                  backgroundColor: theme.palette.sideBar?.dark
                }
              }}
              size="small"
              onClick={handleDrawer}
            >
              {isOpenSideBar ? (
                isMobile || isTablet ? (
                  <X />
                ) : (
                  <ChevronLeft />
                )
              ) : (
                <ChevronRight />
              )}
            </IconButton>
          </Tooltip>
        </DrawerHeader>
        <SideMenu>
          <div>
            {menu.map(
              item =>
                item.display && (
                  <div key={item.key}>
                    <Tooltip
                      placement="right"
                      disableHoverListener={isOpenSideBar}
                      title={t(item.title).toString()}
                    >
                      <ListItemButton
                        className={
                          currentTitleItem === item.key ? "active" : ""
                        }
                        onClick={event => handleMenuItemClick(item, event)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: isOpenSideBar ? "37px" : "56px",
                            transition:
                              "min-width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        {!(!isOpenSideBar && item.key === "user-guide") && (
                          <ListItemText
                            className={
                              currentTitleItem === item.key ? "active" : ""
                            }
                          >
                            {t(item.title)}
                          </ListItemText>
                        )}
                        {item.subMenu?.length ? (
                          item.isOpen ? (
                            <ChevronUp
                              className={
                                currentTitleItem === item.key ? "active" : ""
                              }
                              size={20}
                            />
                          ) : (
                            <ChevronDown
                              className={
                                currentTitleItem === item.key ? "active" : ""
                              }
                              size={20}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </ListItemButton>
                    </Tooltip>
                    {item.subMenu?.length && (
                      <Collapse
                        in={expandedSection === item.key && isOpenSideBar}
                        timeout="auto"
                        unmountOnExit
                      >
                        {item.subMenu.map(subMenu =>
                          subMenu.display ? (
                            <div key={subMenu.key}>
                              {subMenu.label && (
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontWeight: 600,
                                    cursor: "default",
                                    marginLeft: "24px",
                                    marginTop: "16px",
                                    marginBottom: "10px",
                                    color: `${theme.palette.sideBar?.light}`
                                  }}
                                >
                                  {t(subMenu.label)}
                                </Typography>
                              )}
                              <List
                                component="div"
                                disablePadding
                                className="subMenu-list"
                              >
                                {subMenu.items.map(
                                  subMenuItem =>
                                    subMenuItem.display && (
                                      <ListItemButton
                                        sx={{ pl: 7 }}
                                        key={subMenuItem.key}
                                        onClick={() => {
                                          history.push(subMenuItem.path);
                                        }}
                                      >
                                        <ListItemText
                                          className={
                                            currentItem === subMenuItem.path
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          {t(subMenuItem.label)}
                                        </ListItemText>
                                      </ListItemButton>
                                    )
                                )}
                              </List>
                            </div>
                          ) : null
                        )}
                      </Collapse>
                    )}
                  </div>
                )
            )}
          </div>
          <div>
            {canRead("userAgreement", sessionUser) && (
              <div>
                <Tooltip
                  placement="right"
                  disableHoverListener={isOpenSideBar}
                  title={t("menu.userAgreement").toString()}
                >
                  <Link href={userAgreement} target="_blank" rel="noreferrer">
                    <ListItemButton>
                      <ListItemIcon
                        sx={{
                          minWidth: isOpenSideBar ? "37px" : "56px",
                          transition:
                            "min-width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
                        }}
                      >
                        <Clipboard />
                      </ListItemIcon>
                      <ListItemText>{t("menu.userAgreement")}</ListItemText>
                    </ListItemButton>
                  </Link>
                </Tooltip>
              </div>
            )}
            {canRead("userGuide", sessionUser) && (
              <div>
                <Tooltip
                  placement="right"
                  disableHoverListener={isOpenSideBar}
                  title={t("menu.userGuide").toString()}
                >
                  <Link href={userGuide} target="_blank" rel="noreferrer">
                    <ListItemButton>
                      <ListItemIcon
                        sx={{
                          minWidth: isOpenSideBar ? "37px" : "56px",
                          transition:
                            "min-width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
                        }}
                      >
                        <HelpCircle />
                      </ListItemIcon>
                      <ListItemText>{t("menu.userGuide")}</ListItemText>
                    </ListItemButton>
                  </Link>
                </Tooltip>
              </div>
            )}
          </div>
        </SideMenu>
      </Drawer>
      <SubMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={isOpenSubMenuItems}
        onClose={handleCloseSubMenuItems}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        {subMenuListActive &&
          subMenuListActive.map((subMenu, idx) => (
            <div key={idx}>
              {subMenu.label && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    cursor: "default",
                    marginLeft: "16px",
                    marginTop: "16px",
                    marginBottom: "10px",
                    color: `${theme.palette.sideBar?.light}`
                  }}
                >
                  {t(subMenu.label)}
                </Typography>
              )}
              {subMenu.items.map((item, idxItem) => (
                <MenuItem
                  key={idxItem}
                  className={currentItem === item.path ? "active" : ""}
                  onClick={() => {
                    history.push(item.path);
                    handleCloseSubMenuItems();
                  }}
                >
                  {t(item.label)}
                </MenuItem>
              ))}
            </div>
          ))}
      </SubMenu>
      <MyAccountDialog
        open={isMyAccountDialogOpen}
        setOpen={setMyAccountDialogOpen}
      />
    </>
  );
};

export default SideMenuBar;
