import { FC, useState } from "react";
import { Box } from "@mui/material";
import {
  AdvancedFiltersOperatorType,
  SelectType
} from "components/AdvancedFilters/AdvancedFiltersHelpers";
import GroupRow from "./GroupRow";

export type ConfigurationType = {
  column: string;
  name: string;
  type: AdvancedFiltersOperatorType;
  arrayReference?: string;
};

export type ArrayReferenceType = {
  name: string;
  list: SelectType[];
};

export type ConditionItem = {
  isGroup: boolean;
  field: string;
  operator: string;
  value: string | string[];
  condition: string;
};

export type AdvancedFilterGroupItem = {
  isGroup: boolean;
  groupOp: string;
  items: (ConditionItem | AdvancedFilterGroupItem)[];
};

const filterStructureDefaultValue: AdvancedFilterGroupItem = {
  isGroup: true,
  groupOp: "and",
  items: []
};

type AdvancedFiltersProps = {
  configs: ConfigurationType[];
  filterData?: AdvancedFilterGroupItem;
  arraysReferences?: ArrayReferenceType[];
  onFilterChange: (filter: AdvancedFilterGroupItem) => void;
};

const AdvancedFilters: FC<AdvancedFiltersProps> = ({
  configs,
  arraysReferences,
  filterData,
  onFilterChange
}) => {
  const [filterStructure, setFilterStructure] =
    useState<AdvancedFilterGroupItem>(
      filterData === undefined ? filterStructureDefaultValue : filterData
    );

  const onFilterDataChange = (filter: AdvancedFilterGroupItem) => {
    setFilterStructure(filter);
    onFilterChange(filter);
  };

  return (
    <>
      <Box>
        <GroupRow
          onFilterChange={onFilterDataChange}
          filter={filterStructure}
          configs={configs}
          arraysReferences={arraysReferences}
        />
      </Box>
    </>
  );
};
export default AdvancedFilters;
