import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import snackNotification from "./SnackNotification";
import NotificationType from "enums/NotificationType";
import { useWebSocket } from "contexts/WebSocketContext";
import { useNotificationPanel } from "contexts/NotificationPanelContext";
import alert1Mp3 from "../assets/alerts/alert1.mp3";
import alert2Mp3 from "../assets/alerts/alert2.mp3";
import alarm1Mp3 from "../assets/alerts/alarm1.mp3";
import alarm2Mp3 from "../assets/alerts/alarm2.mp3";
import carAlertMp3 from "../assets/alerts/car_alert.mp3";
import truckAlertMp3 from "../assets/alerts/truck_alert.mp3";

const alerts: { [key: string]: string } = {
  ["alert1"]: alert1Mp3,
  ["alert2"]: alert2Mp3,
  ["alarm1"]: alarm1Mp3,
  ["alarm2"]: alarm2Mp3,
  ["car_alert"]: carAlertMp3,
  ["truck_alert"]: truckAlertMp3
};

type ParsedWebSocketNotification = {
  key: string;
  data: {
    date: string;
    id: string;
    ["id_register"]: string;
    message: string;
    type: string;
  };
};

type ParsedWebSocketMessage = {
  key: string;
  data: {
    plate: string;
    ["popup_alert"]: boolean;
    ["sound_alert"]: boolean;
    ["custom_sound_alert"]: string;
    ["alert_color"]: string;
    ["persist_alert"]: boolean;
  }[];
};

const MonitoredVehicleNotification: FC = () => {
  const { lastMessage } = useWebSocket();
  const { notificationsConfig, pushNotification } = useNotificationPanel();
  const [audioSrc, setAudioSrc] = useState<string>(alerts.alert1);
  const { t } = useTranslation();

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!lastMessage?.data) return;

    const parsedMessage: ParsedWebSocketMessage = JSON.parse(lastMessage.data);

    if (parsedMessage.key === "monitoringData") {
      parsedMessage.data.forEach(monitoring => {
        if (monitoring.popup_alert) {
          snackNotification.plate(
            t("MonitoredVehicleNotification.alert", {
              plate: monitoring.plate
            }),
            {
              persist: monitoring.persist_alert
            },
            monitoring.plate,
            monitoring.alert_color ?? undefined
          );
        }
      });

      const notificationSound = parsedMessage.data.find(
        monitoring => monitoring.sound_alert
      );
      if (audioRef.current && notificationSound) {
        const sound = notificationSound.custom_sound_alert
          ? alerts[notificationSound.custom_sound_alert as string]
          : alerts.alert1;
        setAudioSrc(sound);
        audioRef.current.load();
        const promise = audioRef.current.play();
        if (promise !== undefined) {
          promise.catch(_u => {
            // Autoplay was prevented (NotAllowedError)
            // We can notify user somehow
          });
        }
      }
    }

    const parsedNotification: ParsedWebSocketNotification = JSON.parse(
      lastMessage.data
    );

    if (
      parsedNotification.key === "notificationData" &&
      notificationsConfig &&
      notificationsConfig.notification_channel.panel &&
      notificationsConfig.notification_type.alert
    ) {
      const notification = parsedNotification.data;
      pushNotification({
        date: notification.date,
        id: notification.id,
        message: notification.message,
        type: notification.type as NotificationType
      });
    }
  }, [lastMessage]);

  return (
    <audio key="notify" ref={audioRef}>
      <source src={audioSrc} type="audio/mp3" />
    </audio>
  );
};

export default MonitoredVehicleNotification;
