import { FC, ReactNode } from "react";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Box,
  Dialog,
  useMediaQuery,
  useTheme,
  Breakpoint
} from "@mui/material";
import { X } from "react-feather";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";

type Props = {
  actions?: ReactNode;
  confirmDisabled?: boolean;
  confirmText?: string;
  dirty?: boolean;
  fullScreen?: boolean;
  isLoading?: boolean;
  maxWidth?: Breakpoint;
  noCancel?: boolean;
  hasDelete?: boolean;
  onClose?: () => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
  onConfirm?: () => void | Promise<void>;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  testId?: string;
  title?: ReactNode;
  fullScreenDialog?: boolean;
};

const FormDialog: FC<Props> = ({
  actions,
  children,
  confirmDisabled = false,
  confirmText,
  dirty = false,
  fullScreen = false,
  isLoading = false,
  maxWidth = "md",
  noCancel = false,
  hasDelete = false,
  onClose,
  onConfirm,
  onDelete = () => {},
  open = false,
  setOpen,
  testId,
  title,
  fullScreenDialog = false
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { showModal } = useModal();

  const closeDialog = () => {
    if (!setOpen) return;

    if (dirty) {
      showModal({
        modalType: "confirm",
        onConfirm: () => setOpen(false),
        confirmText: t("action.confirm"),
        title: t("prompt.closeDialogConfirmTitle"),
        description: t("prompt.closeDialogConfirmDescription")
      });
    } else {
      setOpen(false);
    }

    if (onClose) onClose();
  };

  const hasActions = !noCancel || onConfirm || onDelete || hasDelete || actions;

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      fullScreen={fullScreen || isMobile}
      open={open}
      aria-labelledby="dialog"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeDialog();
        }
      }}
      data-testid={testId}
      sx={
        fullScreenDialog
          ? {
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "80%",
                  minHeight: "80%",
                  height: "fit-content",
                  maxWidth: "none"
                }
              }
            }
          : {}
      }
    >
      <DialogTitle>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            gap: theme.spacing(2)
          }}
        >
          {isLoading && <CircularProgress size={22} />}
          {title}
        </div>
        <IconButton aria-label="close" onClick={closeDialog}>
          <X />
        </IconButton>
      </DialogTitle>
      <form
        noValidate
        onSubmit={e => {
          e.preventDefault();
          if (onConfirm) {
            onConfirm();
          }
        }}
        style={{
          marginBottom: hasActions ? 0 : theme.spacing(4),
          height: "80%"
        }}
      >
        <DialogContent sx={{ height: "100%" }}>{children}</DialogContent>
        {hasActions && (
          <Box sx={{ mt: 3 }}>
            <Divider />
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: theme.spacing(2)
              }}
            >
              {!isLoading && actions}
              <>
                {hasDelete && (
                  <Button
                    customProps={{
                      type: "button",
                      variant: "text",
                      color: "error",
                      onClick() {
                        onDelete();
                      },
                      sx: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                        width: isMobile ? "100%" : "auto"
                      }
                    }}
                  >
                    {t("action.delete")}
                  </Button>
                )}
                {!noCancel && (
                  <Button
                    customProps={{
                      type: "button",
                      variant: "text",
                      color: "primary",
                      onClick: closeDialog,
                      sx: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2),
                        width: isMobile ? "100%" : "auto"
                      }
                    }}
                  >
                    {t("action.cancel")}
                  </Button>
                )}
                {onConfirm && (
                  <Button
                    customProps={{
                      color: "secondary",
                      disabled: confirmDisabled || isLoading,
                      type: "submit",
                      sx: {
                        width: isMobile ? "100%" : "auto"
                      }
                    }}
                  >
                    {confirmText ?? t("action.apply")}
                  </Button>
                )}
              </>
            </DialogActions>
          </Box>
        )}
      </form>
    </Dialog>
  );
};

export default FormDialog;
