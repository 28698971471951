import { FC, MouseEvent, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";

type NewButtonMenuProps = {
  onHandleNewConditionClick: () => void;
  onHandleNewGroupClick: () => void;
};

const NewButtonMenu: FC<NewButtonMenuProps> = ({
  onHandleNewConditionClick,
  onHandleNewGroupClick
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewGroupClick = () => {
    onHandleNewGroupClick();
    handleClose();
  };

  const handleNewConditionClick = () => {
    onHandleNewConditionClick();
    handleClose();
  };

  return (
    <>
      <Button
        id={`${1}-group-menu`}
        size="small"
        variant="text"
        startIcon={<Plus />}
        onClick={handleClick}
        disableRipple
      >
        {t("action.add")}
      </Button>
      <Menu
        transitionDuration={0}
        id={`${1}-group-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem disableRipple onClick={() => handleNewConditionClick()}>
          {t("AdvancedFilterComponent.newCondition")}
        </MenuItem>
        <MenuItem disableRipple onClick={() => handleNewGroupClick()}>
          {t("AdvancedFilterComponent.newGroup")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default NewButtonMenu;
