import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@mui/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setHours, setMinutes } from "date-fns";
import { setTimezone } from "utils/DateFunctions";
import { SchedulerFormModal } from "./SchedulerForm";
import { Controller, useForm } from "react-hook-form";
import TimePickerField from "components/TimePickerField";

const defaultDate = new Date();

export const defaultValues: SchedulerFormModal = {
  startTime: setTimezone(setMinutes(setHours(defaultDate.setDate(1), 0), 0)),
  endTime: setTimezone(new Date())
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setScheduler: (data: SchedulerFormModal) => void;
};

const SchedulerModal: FC<Props> = ({ open, setOpen, setScheduler }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<SchedulerFormModal>({
    mode: "onChange",
    defaultValues
  });

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open, reset]);

  const onSubmit = (data: SchedulerFormModal) => {
    const newData = { ...data };
    newData.startTime = setTimezone(new Date(newData.startTime));
    newData.endTime = setTimezone(new Date(newData.endTime));
    setScheduler(newData);
    setOpen(false);
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>{t("MonitoringVehicles.schedulerModal")}</DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={6}>
            <Controller
              name="startTime"
              control={control}
              rules={{
                required: t("form.requiredField").toString()
              }}
              render={({ field, fieldState }) => (
                <TimePickerField
                  required
                  gutter={false}
                  field={{ ...field }}
                  fieldState={fieldState}
                  label={t("MonitoredVehiclesDashboardPage.startDateTime")}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="endTime"
              control={control}
              rules={{
                required: t("form.requiredField").toString()
              }}
              render={({ field, fieldState }) => (
                <TimePickerField
                  required
                  gutter={false}
                  field={{ ...field }}
                  fieldState={fieldState}
                  label={t("MonitoredVehiclesDashboardPage.endDateTime")}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {t("action.cancel")}
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary">
          {t("action.apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SchedulerModal;
