import { FC, useState } from "react";
import { Calendar } from "react-feather";
import InputField from "components/InputField";
import { CalendarPickerView, DatePicker } from "@mui/x-date-pickers";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";

type InputFieldProps = {
  label?: string;
  disabled?: boolean;
  required?: boolean;
  gutter?: boolean;
  views?: CalendarPickerView[];
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
};

const DatePickerField: FC<InputFieldProps> = ({
  label,
  field,
  fieldState,
  disabled,
  gutter,
  views,
  required
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <>
      <DatePicker
        open={pickerOpen}
        onClose={() => setPickerOpen(false)}
        onOpen={() => setPickerOpen(true)}
        disabled={disabled}
        value={field.value}
        onChange={field.onChange}
        views={views}
        renderInput={({ inputRef, inputProps }) => (
          <InputField
            gutter={gutter}
            customProps={{
              inputProps,
              inputRef,
              startAdornment: <Calendar />,
              onClick: () => setPickerOpen(true),
              disabled,
              required
            }}
            fieldState={fieldState}
            label={label}
          />
        )}
      />
    </>
  );
};

export default DatePickerField;
