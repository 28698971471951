import { FC, MouseEventHandler } from "react";
import { Position } from "google-map-react";
import { Icon, styled } from "@mui/material";
import { Smartphone, Video } from "react-feather";

import Car from "icons/Car";

const Container = styled("div")(({ color }) => ({
  backgroundColor: color,
  color: "#fff",
  width: "40px",
  height: "40px",
  position: "absolute",
  transform: "translate(-50%, calc(-100% - 7px))",
  cursor: "pointer",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:after": {
    content: "''",
    position: "absolute",
    bottom: "-7px",
    width: "14px",
    height: "7px",
    backgroundColor: color,
    clipPath: "polygon(0 0, 50% 100%, 100% 0)"
  }
}));

const pinIcons = {
  static: Video,
  vehicle: Car,
  mobile: Smartphone
};

type Props = {
  type: "static" | "vehicle" | "mobile";
  color: string;
  onClick?: MouseEventHandler<HTMLElement>;
};

const EquipmentMapMarker: FC<Position & Props> = ({ type, color, onClick }) => (
  <Container onClick={onClick} color={color}>
    <Icon component={pinIcons[type]} size={36} />
  </Container>
);

export default EquipmentMapMarker;
