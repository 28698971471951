enum APIEndpoints {
  ANALYTICS_BI_RESTRICTION = "analytics/restriction/bi-restriction",
  ANALYTICS_MONITORED_VEHICLES = "analytics/monitored-vehicles",
  AVERAGE_VEHICLES = "analytics-bi/convoy/average-vehicles",
  CAMERA = "camera",
  CAMERA_ANALYTICS_LIST = "analytics-bi/list-cameras",
  CAMERA_CREATE = "camera/create",
  CAMERA_DELETE = "camera/delete",
  CAMERA_EFFICIENCY = "analytics-bi/camera-availability",
  CAMERA_EFFICIENCY_DOWNLOAD = "analytics-bi/download-camera-availability",
  CAMERA_LIST = "camera/list",
  CAMERA_PARTIAL = "camera/partial",
  CAMERA_UPDATE = "camera/update",
  CAPTURE_REPORT_DOWNLOAD = "report/download-register",
  CAPTURE_REPORT_LIST = "report/register",
  CITIES = "tools/cities",
  CONFIGURATION_WATERMARK = "configuration/watermark",
  CONFIGURATION_WATERMARK_UPDATE = "configuration/watermark/update",
  CONFIRM_FORGOT_PASSWORD = "authentication/confirm-forgot-password",
  CONFIRM_USER = "authentication/confirm-user",
  CONVOY_GET_INFO = "analytics-bi/convoy/get-info",
  CONVOY_LIST = "analytics-bi/convoy/list-by-period",
  CONVOY_LOCATION_LIST = "analytics-bi/convoy/locations",
  CONVOY_REPORT_DOWNLOAD = "report/download-convoy",
  CONVOY_REPORT_LIST = "report/convoy",
  COUNTRY = "tools/states",
  CUSTOM_FILTER_DELETE = "custom-filters/delete",
  CUSTOM_FILTER_LIST = "custom-filters/list",
  CUSTOM_FILTER_SET = "custom-filters/set",
  CUSTOMER = "customer",
  CUSTOMER_CREATE = "customer/create",
  CUSTOMER_DELETE = "customer/delete",
  CUSTOMER_INTEGRATION_CORTEX_RF = "integration/cortex",
  CUSTOMER_INTEGRATION_CORTEX_RF_UPDATE = "integration/cortex/update",
  CUSTOMER_INTEGRATION_CCONET = "integration/cconet",
  CUSTOMER_INTEGRATION_CCONET_UPDATE = "integration/cconet/update",
  CUSTOMER_INTEGRATION_DETECTA_SP = "integration/detectasp",
  CUSTOMER_INTEGRATION_DETECTA_SP_UPDATE = "integration/detectasp/update",
  CUSTOMER_INTEGRATION_SPIA_PRF = "integration/spiaprf",
  CUSTOMER_INTEGRATION_SPIA_PRF_UPDATE = "integration/spiaprf/update",
  CUSTOMER_INTEGRATION_TELEGRAM = "integration/telegram",
  CUSTOMER_INTEGRATION_TELEGRAM_CREATE = "integration/telegram/create",
  CUSTOMER_INTEGRATION_TELEGRAM_DELETE = "integration/telegram/delete",
  CUSTOMER_INTEGRATION_TELEGRAM_LIST = "integration/telegram/list",
  CUSTOMER_INTEGRATION_TELEGRAM_UPDATE = "integration/telegram/update",
  CUSTOMER_LIST = "customer/list",
  CUSTOMER_PARTIAL = "customer/partial",
  CUSTOMER_UPDATE = "customer/update",
  CUSTOMIZATION = "customization",
  CUSTOMIZATION_SET = "customization/set",
  FORENSIC_COMMENT_CREATE = "forensic-report-comment/create",
  FORENSIC_COMMENT_DELETE = "forensic-report-comment/delete",
  FORENSIC_COMMENT_GET = "forensic-report-comment",
  FORENSIC_COMMENT_LIST = "forensic-report-comment/list",
  FORENSIC_COMMENT_UPDATE = "forensic-report-comment/update",
  FORENSIC_CREATE = "forensic-report/create",
  FORENSIC_DELETE = "forensic-report/delete",
  FORENSIC_GET = "forensic-report",
  FORENSIC_LIST = "forensic-report/list",
  FORENSIC_REPORT_DOWNLOAD = "forensic-report/download",
  FORENSIC_UPDATE = "forensic-report/update",
  FORGOT_PASSWORD = "authentication/forgot-password",
  INCIDENT_REPORT_DOWNLOAD = "report/download-incident",
  INCIDENT_REPORT_LIST = "report/incidents",
  LICENCE_SET = "licence/set",
  LIST_CAMERAS = "analytics-bi/list-cameras",
  LOCATION = "location",
  LOCATION_CREATE = "location/create",
  LOCATION_DELETE = "location/delete",
  LOCATION_LIST = "location/list",
  LOCATION_LIST_GROUP = "location/list-group",
  LOCATION_PARTIAL = "location/partial",
  LOCATION_UPDATE = "location/update",
  LOGIN = "authentication/login",
  LOGOUT = "authentication/logout",
  LOGS_REPORT_LIST = "logs",
  MAKE_LIST = "vehicle/make",
  MAPS_CUSTOMER = "maps/customer",
  MAPS_TRANSLATE = "maps/translate-address",
  MODEL_LIST = "vehicle/model",
  MODEL_LITE_LIST = "vehicle/model-lite",
  MONITORING = "monitoring",
  MONITORING_RULE = "monitoring/rule",
  MONITORING_CREATE = "monitoring/create",
  MONITORING_CREATE_RULE = "monitoring/rule/create",
  MONITORING_DELETE = "monitoring/delete",
  MONITORING_DELETE_RULE = "monitoring/rule/delete",
  MONITORING_LIST = "monitoring/list",
  MONITORING_PARTIAL = "monitoring/partial",
  MONITORING_REPORT_LIST = "report/monitoring-plate",
  MONITORING_UPDATE = "monitoring/update",
  MONITORING_UPDATE_RULE = "monitoring/rule/update",
  MONITORING_UPLOAD = "monitoring/upload",
  MONITORING_RULE_UPLOAD = "monitoring/rule/upload-allowed",
  MONITORING_VEHICLES_REPORT = "report/monitoring-plate",
  MONITORING_VEHICLES_REPORT_DOWNLOAD = "report/download-monitoring-plate",
  MOSAIC = "mosaic",
  MOSAIC_UPDATE = "mosaic/update",
  MOST_REGISTER_EQUIPMENTS = "analytics/locations-most-registered",
  MOST_REGISTER_PLATES = "analytics/most-registered-plates",
  NOTIFICATIONS = "notifications",
  NOTIFICATIONS_UPDATE = "notifications/update",
  PERMISSION_LIST = "permission/list",
  REFRESH_TOKEN = "authentication/refresh-token",
  REGISTER_FLOW = "analytics/registers-flow",
  REGISTER_DETAILS_DOWNLOAD = "register/download",
  RESEND_ACCESS_CODE = "authentication/resend-code",
  RESTRICTION_CREATE = "restriction/create",
  RESTRICTION_DELETE = "restriction/delete",
  RESTRICTION_GET = "restriction",
  RESTRICTION_LIST = "restriction/list",
  RESTRICTION_PARTIAL = "restriction/partial",
  RESTRICTION_UPDATE = "restriction/update",
  ROUTE = "route",
  ROUTE_CREATE = "route/create",
  ROUTE_DELETE = "route/delete",
  ROUTE_LAST_DATA = "route/last-data",
  ROUTE_LIST = "route/list",
  ROUTE_PARTIAL = "route/partial",
  ROUTE_TOP_SPEED = "analytics/route-top-speed",
  ROUTE_UPDATE = "route/update",
  SHARE_CAMERA_CREATE = "share/camera/create",
  SHARE_CAMERA_DELETE = "share/camera/delete",
  SHARE_LOCATION_CREATE = "share/location/create",
  SHARE_LOCATION_DELETE = "share/location/delete",
  SPIA = "tools/spia",
  SPIA_REPORT_LIST = "report/spia-prf",
  SUSPECT_VEHICLES = "analytics-bi/suspect-vehicles",
  TOTAL_VEHICLES = "analytics-bi/total-vehicles",
  UPDATE_PLATE = "register/update",
  USER = "user",
  USER_CREATE = "user/create",
  USER_DELETE = "user/delete",
  USER_GROUP = "user-group",
  USER_GROUP_CREATE = "user-group/create",
  USER_GROUP_DELETE = "user-group/delete",
  USER_GROUP_LIST = "user-group/list",
  USER_GROUP_PARTIAL = "user-group/partial",
  USER_GROUP_UPDATE = "user-group/update",
  USER_LIST = "user/list",
  USER_LIST_GROUP = "user/list-group",
  USER_NOTIFICATIONS = "user-notifications",
  USER_NOTIFICATIONS_UPDATE = "user-notifications/update",
  USER_PARTIAL = "user/partial",
  USER_UPDATE = "user/update",
  VEHICLE_OVERVIEW_DOWNLOAD = "analytics/download-vehicles-overview",
  VEHICLE_OVERVIEW_LIST = "analytics/vehicle-overview",
  VERTICAL = "vertical",
  VERTICAL_LIST = "vertical/list",
  VMS_GET_DATA = "vms",
  VMS_INTEGRATION = "integration/vms",
  VMS_INTEGRATION_CREATE = "integration/vms/create",
  VMS_INTEGRATION_DELETE = "integration/vms/delete",
  VMS_INTEGRATION_LIST = "integration/vms/list",
  VMS_INTEGRATION_UPDATE = "integration/vms/update",
  VMS_REQUEST_DATA = "vms/request-data",
  WELCOME = "welcome"
}

export default APIEndpoints;
