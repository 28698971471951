import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState
} from "react";

type OpenItems = {
  system: boolean;
  reports: boolean;
  dashboards: boolean;
  settings: boolean;
};

const INITIAL_OPEN_ITEMS: OpenItems = {
  system: false,
  reports: false,
  settings: false,
  dashboards: false
};

type MainMenuContextValue = {
  openItems: OpenItems;
  setOpenItems: (() => void) | Dispatch<SetStateAction<OpenItems>>;
};

const MainMenuContext = createContext<MainMenuContextValue>({
  openItems: INITIAL_OPEN_ITEMS,
  setOpenItems() {
    throw new Error("setOpenItems must be defined.");
  }
});

export const MainMenuProvider: FC = ({ children }) => {
  const [openItems, setOpenItems] = useState<OpenItems>(INITIAL_OPEN_ITEMS);

  return (
    <MainMenuContext.Provider
      value={{
        openItems,
        setOpenItems
      }}
    >
      {children}
    </MainMenuContext.Provider>
  );
};

export const useMainMenu = (): MainMenuContextValue => {
  const context = useContext(MainMenuContext);
  if (context === undefined) {
    throw new Error("useMainMenu must be used within an MainMenuProvider");
  }
  return context;
};
