import { FC } from "react";
import {
  Box,
  /* Paper, */
  styled,
  IconButton,
  Grid,
  Divider,
  Typography,
  Stack,
  Dialog,
  DialogProps
} from "@mui/material";
import { X } from "react-feather";

import { Location } from "api/MapsAPI";
import { useTranslation } from "react-i18next";

const Container = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    boxShadow: theme.shadows["16"],
    width: "315px",
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }
}));

const Header = styled(Box)(({ theme }) => ({
  "& .statusColor": {
    display: "block",
    width: "100%",
    height: "12px"
  },
  "& .closeButton": {
    display: "flex",
    justifyContent: "end",
    padding: theme.spacing(1)
  }
}));

const Content = styled(Box)(({ theme }) => ({
  padding: "0 16px 16px",
  overflowY: "auto",
  "& .MuiGrid-item": {
    padding: "0 16px"
  },
  "& .MuiTypography-caption:not(.equipmentStatus)": {
    color: theme.palette.primary.light
  },
  "& .camerasList": {
    maxHeight: "300px",
    overflowY: "auto"
  },
  "& .camerasList > div": {
    padding: "8px 0",
    "& > div": {
      paddingLeft: "20px"
    }
  }
}));

type Props = {
  open: DialogProps["open"];
  onClose?: DialogProps["onClose"];
  equipment: Location;
  statusColor: string;
};

const EquipmentDetails: FC<Props> = ({
  open,
  onClose,
  equipment,
  statusColor
}) => {
  const { t } = useTranslation();

  return (
    <Container open={open} onClose={onClose} BackdropComponent={() => <></>}>
      <Header>
        <div className="statusColor" style={{ backgroundColor: statusColor }} />
        <div
          className="closeButton"
          onClick={() => onClose && onClose({}, "backdropClick")}
        >
          <IconButton>
            <X />
          </IconButton>
        </div>
      </Header>
      <Content>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>{t("EquipmentMapPage.equipment")}</strong>
            </Typography>
            <Typography variant="caption" className="equipmentStatus">
              {equipment["location_name"]}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>{t("EquipmentMapPage.status.label")}</strong>
            </Typography>
            <Typography variant="caption" className="equipmentStatus">
              {t(`EquipmentMapPage.status.${equipment.status}`)}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>{t("EquipmentMapPage.latitude")}</strong>
            </Typography>
            <Typography variant="caption">{equipment.latitude}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              <strong>{t("EquipmentMapPage.longitude")}</strong>
            </Typography>
            <Typography variant="caption">{equipment.longitude}</Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" sx={{ padding: "16px 16px 0 16px" }}>
          <strong>{t("EquipmentMapPage.cameras")}</strong>
        </Typography>
        <Stack divider={<Divider />} className="camerasList">
          {equipment?.["camera_data"]?.map(cameraData => (
            <Grid container key={equipment["location_name"]}>
              <Grid item xs={6}>
                <Typography variant="caption">
                  {cameraData.camera_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">
                  {t(
                    `EquipmentMapPage.cameraStatus.${
                      cameraData.active ? "active" : "inactive"
                    }`
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Content>
    </Container>
  );
};

export default EquipmentDetails;
