import { FC } from "react";
import { styled } from "@mui/material";
import { Circle } from "react-feather";
import { Position } from "google-map-react";
import { secondaryDefaultColors } from "styles/theme";

const Pin = styled(Circle)(() => ({
  position: "absolute"
}));

const CircleMapMarker: FC<Position> = () => (
  <Pin
    size={18}
    fill={secondaryDefaultColors.main}
    color={secondaryDefaultColors.light}
  />
);

export default CircleMapMarker;
