import {
  Box,
  Grid,
  styled,
  Checkbox,
  TextField,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Divider
} from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import Button from "components/Button";
import useUserAPI, { User } from "api/UserAPI";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { setTimezone } from "utils/DateFunctions";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";

const PUMATRONIX_CLIENTE = "Cliente";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFilterLogs = {
  user: string;
  message: string;
  endDate: string | Date;
  startDate: string | Date;
  endTime: string | Date;
  startTime: string | Date;
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onFilter: (data: FormFilterLogs) => void;
};

const LogReportFilter: FC<Props> = ({ open, setOpen, onFilter }) => {
  const defaultDate = new Date();
  const defaultValues: FormFilterLogs = {
    user: "",
    message: "",
    startDate: setTimezone(startOfDay(defaultDate)),
    endDate: setTimezone(defaultDate),
    startTime: setTimezone(startOfDay(defaultDate)),
    endTime: setTimezone(endOfDay(defaultDate))
  };
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const [, setSearch] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [todayCheckbox, setTodayCheckbox] = useState<boolean>(false);
  const [isFetchingUsers, setFetchingUsers] = useState<boolean>(false);
  const { errorHandler } = useErrorHandler();
  const { listAll } = useUserAPI();

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<FormFilterLogs>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const requestUsers = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setFetchingUsers(true);
    try {
      const userResponse = await listAll(sessionUser["customer_id"]);
      setUsers(userResponse.data);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setFetchingUsers(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    if (
      getValues("startDate") !== "" &&
      getValues("endDate") !== "" &&
      getValues("startTime") !== "" &&
      getValues("endTime") !== ""
    ) {
      setTodayCheckbox(true);
    }
  }, []);

  useEffect(() => {
    requestUsers();
  }, [requestUsers]);

  const clearForm = () => {
    setSelectedUser(null);
    setTodayCheckbox(false);
    reset();
    const d = setTimezone(new Date());
    setValue("startTime", startOfDay(d));
    setValue("endTime", d);
    setValue("startTime", startOfDay(d));
    setValue("endTime", endOfDay(d));
  };

  const onSubmit = async (data: FormFilterLogs) => {
    onFilter({
      ...data,
      user: selectedUser?.username ?? ""
    });
    setOpen(false);
  };

  const handleCheckbox = (newValue: boolean) => {
    setTodayCheckbox(newValue);
    if (newValue) {
      const d = setTimezone(new Date());
      setValue("startDate", startOfDay(d));
      setValue("endDate", d);
      setValue("startTime", startOfDay(d));
      setValue("endTime", d);
    }
  };

  return (
    <Drawer open={open} setOpen={setOpen} title={t("CaptureReportPage.filter")}>
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    label={t("CaptureReportPage.startDateTime")}
                    disabled={todayCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="startTime"
                control={control}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    disabled={todayCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    label={t("CaptureReportPage.endDateTime")}
                    disabled={todayCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="endTime"
                control={control}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    disabled={todayCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            {sessionUser?.level_name !== PUMATRONIX_CLIENTE && (
              <Grid item xs={12}>
                <FormLabel>{t("LogReportPage.userType")}</FormLabel>
                <Autocomplete
                  disablePortal
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={users}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option.username}
                  value={selectedUser}
                  loading={isFetchingUsers}
                  disabled={isFetchingUsers}
                  onChange={(event, user) => {
                    event.preventDefault();
                    setSelectedUser(user);
                  }}
                  sx={{
                    width: "100%",
                    height: "42px",
                    ".MuiOutlinedInput-root": { height: "40px" },
                    ".MuiOutlinedInput-input": { height: "11px" }
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        onChange={event => {
                          setSearch(event.target.value);
                        }}
                        placeholder={t(
                          isFetchingUsers
                            ? "waitState.loading"
                            : "form.startTyping"
                        )}
                      />
                      {isFetchingUsers && (
                        <CircularProgress
                          color="secondary"
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "42px"
                          }}
                        />
                      )}
                    </div>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="message"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("LogReportPage.message")}
                    gutter={false}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={todayCheckbox}
                    onChange={(_event, newValue) => {
                      handleCheckbox(newValue);
                    }}
                  />
                }
                label={t("LogReportPage.today").toString()}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: clearForm,
              disabled: isFetchingUsers
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              disabled: isFetchingUsers,
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default LogReportFilter;
