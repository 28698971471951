import JSCookie, { CookieAttributes } from "js-cookie";

import { Auth } from "api/AuthAPI";
import Crypto from "utils/Crypto";
import { Watermark } from "api/WatermarkAPI";

const MAX_COOKIE_SIZE = 5000;
const OVER_DATA_LIMIT_ERROR = "Data exceeds maximum cookie size";
const PREFIX = "lince";
const LANGUAGE = `${PREFIX}-language`;
const THEME = `${PREFIX}-theme`;
const ACCESS_TOKEN = `${PREFIX}-access-token`;
const REFRESH_TOKEN = `${PREFIX}-refresh-token`;
const SESSION_USER = `${PREFIX}-session-user`;
const WATERMARK = `${PREFIX}-watermark`;
const SESSION_START_TIME = `${PREFIX}-session-start`;

type CookieData = boolean | string | number | Auth["user"] | Watermark;

const defaultAttributes: CookieAttributes = {
  expires: 3650,
  sameSite: "strict",
  secure: window.location.protocol === "https:"
};

const get = (cookieName: string): CookieData | null => {
  const cookie = JSCookie.get(cookieName);
  return cookie ? (JSON.parse(Crypto.decrypt(cookie)) as CookieData) : null;
};

const getByteSize = (str: string) => new Blob([str]).size;

const set = (
  cookieName: string,
  data: CookieData,
  attributes: CookieAttributes = {}
): void => {
  const serialized = JSON.stringify(data);

  if (getByteSize(serialized) > MAX_COOKIE_SIZE) {
    throw new Error(OVER_DATA_LIMIT_ERROR);
  }

  const encrypted = Crypto.encrypt(serialized);
  JSCookie.set(cookieName, encrypted, { ...defaultAttributes, ...attributes });
};

const remove = (cookieName: string): void => {
  JSCookie.remove(cookieName);
};

const Cookies = {
  get,
  set,
  remove,
  OVER_DATA_LIMIT_ERROR,
  LANGUAGE,
  THEME,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  SESSION_USER,
  WATERMARK,
  SESSION_START_TIME
};

export default Cookies;
