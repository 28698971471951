import { FC, useState } from "react";
import { Clock } from "react-feather";
import InputField from "components/InputField";
import { TimePicker } from "@mui/x-date-pickers";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import { SxProps, Theme } from "@mui/material";

type InputFieldProps = {
  label?: string;
  gutter?: boolean;
  disabled?: boolean;
  required?: boolean;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  sx?: SxProps<Theme>;
};

const TimePickerField: FC<InputFieldProps> = ({
  label,
  field,
  fieldState,
  disabled,
  gutter,
  required,
  sx
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  const openPicker = () => {
    setPickerOpen(true);
  };

  return (
    <TimePicker
      ampm={false}
      open={pickerOpen}
      onClose={() => setPickerOpen(false)}
      onOpen={openPicker}
      disabled={disabled}
      value={field.value}
      onChange={field.onChange}
      renderInput={({ inputRef, inputProps }) => (
        <InputField
          gutter={gutter}
          customProps={{
            inputProps,
            inputRef,
            startAdornment: <Clock />,
            onClick: openPicker,
            disabled,
            required,
            sx
          }}
          fieldState={fieldState}
          label={label}
        />
      )}
    />
  );
};

export default TimePickerField;
