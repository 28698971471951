import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  Autocomplete
} from "@mui/material";
import useRestrictionsAPI, {
  RestrictionType,
  SchedulerRestriction
} from "api/RestrictionAPI";
import { orderBy } from "lodash";
import useCameraAPI, { CameraFilter } from "api/CameraAPI";
import useLocationAPI from "api/LocationAPI";
import FormLabel from "components/FormLabel";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import FormDialog from "components/FormDialog";
import SchedulerForm, {
  SchedulerFormModal
} from "components/SchedulerForm/SchedulerForm";
import SelectField from "components/SelectField";
import { useLocale } from "contexts/LocaleContext";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { SchedulerDate, SchedulerHourRange } from "api/MonitoringAPI";
import { FC, useCallback, MouseEvent, useEffect, useState } from "react";
import { fromDateToUtc, setAddHoursTimezone } from "utils/DateFunctions";
import { AutocompleteOptions } from "pages/MonitoredVehiclesDashboard/MonitoredVehiclesDashboardFilter";

type RestrictionForm = {
  name: string;
  type: string;
  speeding: number | null;
  startDate: Date | string | null;
  endDate: Date | string | null;
};

const defaultValues = {
  name: "",
  type: "",
  speeding: null,
  startDate: null,
  endDate: null
};

type Props = {
  restrictionIndex: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateCallerContent: () => void;
};

const RestrictionsFormDialog: FC<Props> = ({
  restrictionIndex,
  open,
  setOpen,
  updateCallerContent
}) => {
  const isCreating = restrictionIndex === "";
  const { t } = useTranslation();
  const { language } = useLocale();
  const { sessionUser } = useAuth();
  const CameraAPI = useCameraAPI();
  const LocationAPI = useLocationAPI();
  const { errorHandler } = useErrorHandler();
  const [days, setDays] = useState<string[]>([]);
  const RestrictionsAPI = useRestrictionsAPI();
  const [cameras, setCameras] = useState<CameraFilter[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [schedulerDate, setSchedulerDate] = useState<SchedulerRestriction[]>([
    { dow: "monday", ["hours_intervals"]: [] },
    { dow: "tuesday", ["hours_intervals"]: [] },
    { dow: "wednesday", ["hours_intervals"]: [] },
    { dow: "thursday", ["hours_intervals"]: [] },
    { dow: "friday", ["hours_intervals"]: [] },
    { dow: "saturday", ["hours_intervals"]: [] },
    { dow: "sunday", ["hours_intervals"]: [] }
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedCameras, setSelectedCameras] = useState<CameraFilter[]>([
    {
      ["location_name"]: t("form.all"),
      ["camera_name"]: t("form.all")
    }
  ]);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([
    t("form.all")
  ]);
  const [selectedVehicleClass, setSelectedVehicleClass] =
    useState<AutocompleteOptions>({
      value: "",
      label: ""
    });
  const [selectedVehiclePlates, setSelectedVehiclePlates] = useState<number[]>(
    []
  );
  const [classOptions, setClassOptions] = useState<AutocompleteOptions[]>([
    {
      value: "truck",
      label: t("MonitoredVehiclesDashboardPage.truck")
    },
    {
      value: "car",
      label: t("MonitoredVehiclesDashboardPage.car")
    },
    {
      value: "motorcycle",
      label: t("MonitoredVehiclesDashboardPage.motorcycle")
    },
    {
      value: "bus",
      label: t("MonitoredVehiclesDashboardPage.bus")
    }
  ]);
  const [restriction, setRestriction] =
    useState<RestrictionForm>(defaultValues);
  const [schedulerDaySelected, setSchedulerDaySelected] = useState<string>("");

  const { control, formState, watch, handleSubmit, reset, setValue } =
    useForm<RestrictionForm>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues
    });

  const watchType = watch("type");
  const watchSpeeding = watch("speeding");

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const customerId = sessionUser["customer_id"];
      const [locationResponse, cameraResponse] = await Promise.all([
        LocationAPI.listAll(customerId),
        CameraAPI.listAll({ customerId })
      ]);

      setCameras(
        [
          t("form.all"),
          ...cameraResponse.data.map(camera => ({
            ["location_name"]: camera["location_name"],
            ["camera_name"]: camera["camera_name"]
          }))
        ] || []
      );
      setLocations(
        [
          t("form.all"),
          ...locationResponse.data.map(location => location.location_name)
        ] || []
      );

      if (!isCreating) {
        const restrictionResponse = await RestrictionsAPI.getByIndex({
          customerId: sessionUser.customer_id,
          index: restrictionIndex
        });
        setRestriction({
          name: restrictionResponse.name || "",
          type: restrictionResponse.type || "",
          speeding: restrictionResponse.restriction_data.max_speed || null,
          startDate: parseDate(restrictionResponse.initial_date) || null,
          endDate: parseDate(restrictionResponse.final_date) || null
        });
        setSelectedVehiclePlates(
          restrictionResponse.restriction_data.rotation_plate_last_digit
        );
        if (restrictionResponse.restriction_data.vehicle_class !== "") {
          setSelectedVehicleClass({
            value: restrictionResponse.restriction_data.vehicle_class,
            label: t(
              `MonitoredVehiclesDashboardPage.${restrictionResponse.restriction_data.vehicle_class}`
            )
          });
        }
        if (
          restrictionResponse.restriction_data.dow_hours &&
          restrictionResponse.restriction_data.dow_hours.length > 0 &&
          restrictionResponse.restriction_data.dow_hours.find(
            scheduler => scheduler.hours_intervals.length > 0
          )
        ) {
          const schedulerDays: string[] = [];

          restrictionResponse.restriction_data.dow_hours.forEach(
            (scheduler, indexS) => {
              schedulerDays.push(scheduler.dow);
              if (scheduler.hours_intervals.length > 0) {
                scheduler.hours_intervals.forEach((hour, indexH) => {
                  const startHourList = hour.start_hour.toString().split(":");
                  const endHourList = hour.end_hour.toString().split(":");
                  if (startHourList.length > 0 && endHourList.length > 0) {
                    const timezoneHour = {
                      ["start_hour"]: setAddHoursTimezone(
                        new Date(
                          new Date().getFullYear(),
                          1,
                          1,
                          Number(startHourList[0]),
                          Number(startHourList[1])
                        )
                      ),
                      ["end_hour"]: setAddHoursTimezone(
                        new Date(
                          new Date().getFullYear(),
                          1,
                          1,
                          Number(endHourList[0]),
                          Number(endHourList[1])
                        )
                      )
                    };
                    restrictionResponse.restriction_data.dow_hours[
                      indexS
                    ].hours_intervals[indexH] = timezoneHour;
                  }
                });
              } else {
                const timezoneHour = {
                  ["start_hour"]: new Date(
                    new Date().getFullYear(),
                    1,
                    1,
                    0,
                    0
                  ),
                  ["end_hour"]: new Date(new Date().getFullYear(), 1, 1, 23, 59)
                };
                restrictionResponse.restriction_data.dow_hours[
                  indexS
                ].hours_intervals[0] = timezoneHour;
              }
              const schedulerDateIdx = schedulerDate.findIndex(
                elem => elem.dow === scheduler.dow
              );
              schedulerDate[schedulerDateIdx] =
                restrictionResponse.restriction_data.dow_hours[indexS];
            }
          );
          setDays(schedulerDays);
          setSchedulerDate([...schedulerDate]);
        }
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [isCreating, reset, sessionUser, t, restrictionIndex]);

  function parseDate(date: string) {
    const [day, month, year] = date.split("/");
    const formattedDate = `${year}-${month}-${day}`;
    return new Date(formattedDate);
  }

  useEffect(() => {
    if (open) {
      requestData();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(restriction);
  }, [setValue, restriction]);

  useEffect(() => {
    if (!open) {
      clear();
    } else if (open && isCreating) {
      setDays([
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ]);
    }
  }, [open]);

  const updateAutoCompleteLabel = (options: AutocompleteOptions) => ({
    ...options,
    label: t(`MonitoredVehiclesDashboardPage.${options.value}`)
  });

  const changeClassOptionsValue = (objects: AutocompleteOptions) => {
    setSelectedVehicleClass(objects);
  };

  useEffect(() => {
    if (open) {
      const newClasses: AutocompleteOptions[] = classOptions.map(
        updateAutoCompleteLabel
      );
      setClassOptions([...newClasses]);
    }
  }, [language, open]);

  const setScheduler = (data: SchedulerFormModal) => {
    const newSchedulerDate: SchedulerDate[] = schedulerDate;
    const dayIndex = newSchedulerDate.findIndex(
      scheduler => schedulerDaySelected === scheduler.dow
    );
    newSchedulerDate[dayIndex].hours_intervals.push({
      ["start_hour"]: data.startTime,
      ["end_hour"]: data.endTime
    });

    setSchedulerDate(newSchedulerDate);
  };

  const removeScheduler = (
    dayRemoved: string,
    hourRemoved: SchedulerHourRange
  ) => {
    const newSchedulerDate: SchedulerDate[] = schedulerDate;
    const dayIndex = newSchedulerDate.findIndex(
      scheduler => dayRemoved === scheduler.dow
    );
    const hourIndex = newSchedulerDate[dayIndex].hours_intervals.findIndex(
      hour =>
        hourRemoved.start_hour === hour.start_hour &&
        hourRemoved.end_hour === hour.end_hour
    );
    newSchedulerDate[dayIndex].hours_intervals.splice(hourIndex, 1);

    setSchedulerDate([...newSchedulerDate]);
  };

  const handleDays = (event: MouseEvent<HTMLElement>, newFormats: string[]) => {
    event.preventDefault();
    setDays(newFormats);
    if (newFormats.length > 0) {
      if (newFormats.length > days.length) {
        const addedDay = newFormats.filter(elem => !days.includes(elem));
        const addedDayIndex = schedulerDate.findIndex(
          elem => elem.dow === addedDay[0]
        );
        schedulerDate[addedDayIndex].hours_intervals.push({
          ["start_hour"]: new Date(new Date().getFullYear(), 1, 1, 0, 0),
          ["end_hour"]: new Date(new Date().getFullYear(), 1, 1, 23, 59)
        });
      } else {
        const removedDay = days.filter(elem => !newFormats.includes(elem));
        const removedDayIndex = schedulerDate.findIndex(
          elem => elem.dow === removedDay[0]
        );
        schedulerDate[removedDayIndex] = {
          dow: removedDay[0],
          ["hours_intervals"]: []
        };
      }
    } else {
      const removedDayIndex = schedulerDate.findIndex(
        elem => elem.dow === days[0]
      );
      schedulerDate[removedDayIndex] = {
        dow: days[0],
        ["hours_intervals"]: []
      };
    }
  };

  const onSubmit = async (data: RestrictionForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    const newData = { ...data };

    const newScheduler: SchedulerDate[] = [];
    schedulerDate.forEach(scheduler => {
      if (
        days.includes(scheduler.dow) &&
        scheduler.hours_intervals.length > 0
      ) {
        newScheduler.push(scheduler);
      }
    });

    try {
      if (isCreating) {
        await RestrictionsAPI.create({
          customerId: sessionUser?.["customer_id"],
          name: data.name,
          type: data.type as RestrictionType,
          ["final_date"]: newData.endDate
            ? fromDateToUtc(newData.endDate as Date)
            : "",
          ["initial_date"]: newData.startDate
            ? fromDateToUtc(newData.startDate as Date)
            : "",
          locations:
            selectedEquipments.includes(t("form.all")) ||
            selectedEquipments.length === 0
              ? undefined
              : selectedEquipments,
          cameras:
            selectedCameras.filter(c => c["location_name"] === t("form.all"))
              .length > 0 || selectedCameras.length === 0
              ? undefined
              : selectedCameras.map(camera => camera.camera_name),
          ["restriction_data"]: {
            ["dow_hours"]: newScheduler || undefined,
            ["max_speed"]: data.speeding || undefined,
            ["vehicle_class"]:
              selectedVehicleClass.value === ""
                ? undefined
                : selectedVehicleClass.value,
            ["rotation_plate_last_digit"]: selectedVehiclePlates || undefined
          }
        });
        snackNotification.success(t("RestrictionsPage.restrictionCreated"));
      } else {
        await RestrictionsAPI.update({
          customerId: sessionUser?.["customer_id"],
          name: data.name,
          index: restrictionIndex,
          type: data.type as RestrictionType,
          ["final_date"]: newData.endDate
            ? fromDateToUtc(newData.endDate as Date)
            : "",
          ["initial_date"]: newData.startDate
            ? fromDateToUtc(newData.startDate as Date)
            : "",
          locations:
            selectedEquipments.includes(t("form.all")) ||
            selectedEquipments.length === 0
              ? undefined
              : selectedEquipments,
          cameras:
            selectedCameras.filter(c => c["location_name"] === t("form.all"))
              .length > 0 || selectedCameras.length === 0
              ? undefined
              : selectedCameras.map(camera => camera.camera_name),
          ["restriction_data"]: {
            ["dow_hours"]: newScheduler || undefined,
            ["max_speed"]: data.speeding || undefined,
            ["vehicle_class"]:
              selectedVehicleClass.value === ""
                ? undefined
                : selectedVehicleClass.value,
            ["rotation_plate_last_digit"]: selectedVehiclePlates || undefined
          }
        });
        snackNotification.success(t("RestrictionsPage.restrictionUpdated"));
      }
      updateCallerContent();
      setOpen(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const clear = () => {
    setRestriction(defaultValues);
    reset(defaultValues);
    setDays([]);
    setSchedulerDate([
      { dow: "monday", ["hours_intervals"]: [] },
      { dow: "tuesday", ["hours_intervals"]: [] },
      { dow: "wednesday", ["hours_intervals"]: [] },
      { dow: "thursday", ["hours_intervals"]: [] },
      { dow: "friday", ["hours_intervals"]: [] },
      { dow: "saturday", ["hours_intervals"]: [] },
      { dow: "sunday", ["hours_intervals"]: [] }
    ]);
    setSelectedEquipments([t("form.all")]);
    setSelectedCameras([
      {
        ["location_name"]: t("form.all"),
        ["camera_name"]: t("form.all")
      }
    ]);
    setSelectedVehiclePlates([]);
    setSelectedVehicleClass({
      value: "",
      label: ""
    });
  };

  const changeType = () => {
    setValue("speeding", null);
    setDays([]);
    setSchedulerDate([
      { dow: "monday", ["hours_intervals"]: [] },
      { dow: "tuesday", ["hours_intervals"]: [] },
      { dow: "wednesday", ["hours_intervals"]: [] },
      { dow: "thursday", ["hours_intervals"]: [] },
      { dow: "friday", ["hours_intervals"]: [] },
      { dow: "saturday", ["hours_intervals"]: [] },
      { dow: "sunday", ["hours_intervals"]: [] }
    ]);
    setSelectedVehiclePlates([]);
    setSelectedVehicleClass({
      value: "",
      label: ""
    });
  };

  return (
    <FormDialog
      onConfirm={handleSubmit(onSubmit)}
      open={open}
      setOpen={setOpen}
      isLoading={isLoading}
      dirty={formState.isDirty}
      confirmDisabled={
        !formState.isValid ||
        (selectedCameras.length === 0 && selectedEquipments.length === 0) ||
        (watchType === "speeding" &&
          (watchSpeeding === null || days.length <= 0)) ||
        (watchType === "exclusive_road" &&
          (selectedVehicleClass.value === "" || days.length <= 0)) ||
        (watchType === "vehicle_rotation" &&
          (selectedVehicleClass.value === "" ||
            selectedVehiclePlates.length <= 0 ||
            days.length <= 0)) ||
        (watchType === "blocked_road" && days.length <= 0)
      }
      confirmText={t("action.apply")}
      title={
        isCreating
          ? t("RestrictionsPage.createANewRestriction")
          : t("RestrictionsPage.editRestriction")
      }
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <DatePickerField
                required
                gutter
                label={t("MonitoringVehicles.activationDate")}
                disabled={isLoading}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="endDate"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <DatePickerField
                required
                gutter
                label={t("MonitoringVehicles.expirationDateForm")}
                disabled={isLoading}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field, fieldState }) => (
              <InputField
                label={t("RestrictionsPage.name")}
                customProps={{
                  required: true,
                  disabled: isLoading
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ div: { marginBottom: "3px" } }}>
          <Controller
            name="type"
            control={control}
            rules={{
              required: t("form.requiredField").toString()
            }}
            render={({ field }) => (
              <SelectField
                {...field}
                required
                label={t("RestrictionsPage.typeTitle")}
                disabled={isLoading}
                onChange={event => {
                  field.onChange(event.target.value as string);
                }}
              >
                <MenuItem value="" onClick={() => changeType()}>
                  {t("RestrictionsPage.emptyType")}
                </MenuItem>
                <MenuItem value="border_division" onClick={() => changeType()}>
                  {t("RestrictionsPage.type.border_division")}
                </MenuItem>
                <MenuItem value="speeding" onClick={() => changeType()}>
                  {t("RestrictionsPage.type.speeding")}
                </MenuItem>
                <MenuItem value="exclusive_road" onClick={() => changeType()}>
                  {t("RestrictionsPage.type.exclusive_road")}
                </MenuItem>
                <MenuItem value="vehicle_rotation" onClick={() => changeType()}>
                  {t("RestrictionsPage.type.vehicle_rotation")}
                </MenuItem>
                <MenuItem value="blocked_road" onClick={() => changeType()}>
                  {t("RestrictionsPage.type.blocked_road")}
                </MenuItem>
              </SelectField>
            )}
          />
          {watchType !== "" && (
            <Typography align="left" variant="caption" color="primary.light">
              {t(`RestrictionsPage.typeTooltip.${watchType}`)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormLabel required={selectedCameras.length < 1}>
            {t("CamerasPage.equipment")}
          </FormLabel>
          <Autocomplete
            multiple
            sx={{ mb: 2 }}
            disabled={isLoading}
            openText={t("action.open")}
            closeText={t("action.close")}
            clearText={t("action.clear")}
            options={locations}
            getOptionLabel={option => option}
            filterSelectedOptions
            onChange={(event, newValues) => {
              event.preventDefault();
              const noIsAll = !newValues.includes(t("form.all"));
              if (
                selectedEquipments.includes(t("form.all")) &&
                selectedEquipments.length === 1
              ) {
                const idx = newValues.indexOf(t("form.all"));
                newValues.splice(idx, 1);
                setSelectedEquipments(newValues);
              } else if (noIsAll) {
                setSelectedEquipments(newValues);
              } else {
                setSelectedEquipments([t("form.all")]);
                setSelectedCameras([
                  {
                    ["location_name"]: t("form.all"),
                    ["camera_name"]: t("form.all")
                  }
                ]);
              }
            }}
            noOptionsText={t("form.noOptions")}
            value={selectedEquipments}
            size="small"
            renderInput={params => (
              <TextField
                {...params}
                placeholder={t("MonitoringVehicles.equipmentGroupHint")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel required={selectedEquipments.length < 1}>
            {t("CaptureReportPage.cameras")}
          </FormLabel>
          <Autocomplete
            size="small"
            multiple
            sx={{ mb: 2 }}
            disablePortal
            disabled={isLoading}
            openText={t("action.open")}
            closeText={t("action.close")}
            clearText={t("action.clear")}
            options={[
              {
                ["location_name"]: t("form.all"),
                ["camera_name"]: t("form.all")
              },
              ...cameras.filter(c =>
                selectedEquipments.includes(c["location_name"])
              )
            ]}
            noOptionsText={t("form.noOptions")}
            getOptionLabel={option => option.camera_name}
            value={selectedCameras}
            onChange={(event, newValues) => {
              event.preventDefault();
              const noIsAll = newValues.findIndex(
                c => c["location_name"] === t("form.all")
              );
              const allArray = selectedCameras.filter(
                c => c["location_name"] === t("form.all")
              );
              if (allArray.length > 0 && selectedCameras.length === 1) {
                newValues.splice(noIsAll, 1);
                setSelectedCameras(newValues);
              } else if (noIsAll === -1) {
                setSelectedCameras(newValues);
              } else {
                setSelectedCameras([
                  {
                    ["location_name"]: t("form.all"),
                    ["camera_name"]: t("form.all")
                  }
                ]);
              }
            }}
            renderInput={params => (
              <div style={{ position: "relative" }}>
                <TextField {...params} placeholder={t("form.startTyping")} />
              </div>
            )}
          />
        </Grid>
        {watchType === "speeding" && (
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Controller
              name="speeding"
              control={control}
              rules={{
                required: t("form.requiredField").toString()
              }}
              render={({ field, fieldState }) => (
                <InputField
                  mask="999"
                  customProps={{
                    disabled: isLoading,
                    required: true
                  }}
                  label={`${t("RestrictionsPage.speeding")} (Km/h)`}
                  field={{ ...field }}
                  fieldState={fieldState}
                />
              )}
            />
          </Grid>
        )}
        {watchType === "vehicle_rotation" && (
          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormLabel required>
              {t("RestrictionsPage.vehiclePlates")}
            </FormLabel>
            <Autocomplete
              multiple
              filterSelectedOptions
              disabled={isLoading}
              openText={t("action.open")}
              closeText={t("action.close")}
              clearText={t("action.clear")}
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              getOptionLabel={option => `${option}`}
              onChange={(event, newValues) => {
                event.preventDefault();
                setSelectedVehiclePlates(newValues);
              }}
              noOptionsText={t("form.noOptions")}
              value={selectedVehiclePlates}
              size="small"
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={t("RestrictionsPage.vehiclePlatesHint")}
                />
              )}
            />
          </Grid>
        )}
        {(watchType === "vehicle_rotation" ||
          watchType === "exclusive_road") && (
          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormLabel required>
              {watchType === "vehicle_rotation"
                ? t("RestrictionsPage.restrictionVehicleClassBlocked")
                : t("RestrictionsPage.restrictionVehicleClass")}
            </FormLabel>
            <Autocomplete
              filterSelectedOptions
              disabled={isLoading}
              openText={t("action.open")}
              closeText={t("action.close")}
              clearText={t("action.clear")}
              options={orderBy(classOptions, ["", "label"], [false, "asc"])}
              getOptionLabel={option => option.label}
              onChange={(event, newValues) => {
                event.preventDefault();
                if (newValues) changeClassOptionsValue(newValues);
              }}
              noOptionsText={t("form.noOptions")}
              value={selectedVehicleClass}
              size="small"
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={t(
                    "MonitoredVehiclesDashboardPage.vehicleClassHint"
                  )}
                />
              )}
            />
          </Grid>
        )}
        {watchType !== "" && watchType !== "border_division" && (
          <Grid item xs={12}>
            <SchedulerForm
              open={open}
              days={days}
              required
              schedulerDate={schedulerDate}
              setSchedulerDaySelected={setSchedulerDaySelected}
              setScheduler={setScheduler}
              handleDays={handleDays}
              removeScheduler={removeScheduler}
            />
          </Grid>
        )}
      </Grid>
    </FormDialog>
  );
};

export default RestrictionsFormDialog;
