import { FC, useEffect, useState } from "react";
import {
  AdvancedFiltersOperatorType,
  cloneFilterCondition,
  getOperatorsByType,
  GridItem,
  GridWrapper,
  InputFilterField,
  SelectFilterField,
  SelectType
} from "./AdvancedFiltersHelpers";
import { IconButton } from "@mui/material";
import { XCircle } from "react-feather";
import {
  ArrayReferenceType,
  ConditionItem,
  ConfigurationType
} from "./AdvancedFilters";
import DateInputFilter from "components/AdvancedFilters/components/DateInputFilter";
import MultiSelectInputFilter from "components/AdvancedFilters/components/MultiSelectInputFilter";
import { useTranslation } from "react-i18next";

type ActiveConfig = {
  operators: SelectType[];
  optionsArray: SelectType[];
  type: AdvancedFiltersOperatorType;
};

type ConditionRowProps = {
  item: ConditionItem;
  configs: ConfigurationType[];
  arraysReferences?: ArrayReferenceType[];
  onFilterChange: (conditionItem: ConditionItem) => void;
  removeItem?: () => void;
};

const ConditionRow: FC<ConditionRowProps> = ({
  configs,
  item,
  arraysReferences,
  onFilterChange,
  removeItem
}) => {
  const { t } = useTranslation();
  const [activeConfig, setActiveConfig] = useState<ActiveConfig>({
    operators: [],
    optionsArray: [],
    type: AdvancedFiltersOperatorType.TEXT
  });

  const changeValue = (value: string | string[]) => {
    const newCondition = cloneFilterCondition(item);
    newCondition.value = value;
    onFilterChange(newCondition);
  };

  const changeOperator = (value: string) => {
    const newCondition = cloneFilterCondition(item);
    newCondition.operator = value;
    onFilterChange(newCondition);
  };

  const getArrayReferenceByName = (name: string | undefined) => {
    if (!name || !arraysReferences) return [];
    const listIndex = arraysReferences.findIndex(a => a.name === name);
    return arraysReferences[listIndex].list;
  };

  const setActiveConfigByField = (field: string) => {
    const index = configs.findIndex(config => config.column === field);
    const { type, arrayReference } = configs[index];
    const operatorList = getOperatorsByType(type);
    const activeConfig = {
      operators: operatorList,
      optionsArray: getArrayReferenceByName(arrayReference),
      type
    };
    setActiveConfig(activeConfig);
    return activeConfig;
  };

  useEffect(() => {
    if (!item.field) return;
    setActiveConfigByField(item.field);
  }, [item]);

  const changeFieldValue = (value: string) => {
    if (!value) return;
    const activeConfig = setActiveConfigByField(value);

    const newCondition = cloneFilterCondition(item);
    newCondition.field = value;
    newCondition.operator = activeConfig.operators[0].value;
    newCondition.value = "";
    onFilterChange(newCondition);
  };

  const handleColumnChange = (value: string) => changeFieldValue(value);
  const handleOperatorChange = (value: string) => changeOperator(value);
  const handleValueChange = (value: string | string[]) => changeValue(value);

  const handleConditionRemoveClick = () => {
    if (removeItem) {
      removeItem();
    }
  };

  return (
    <GridWrapper>
      <GridItem className="iconButton">
        <IconButton disableRipple onClick={handleConditionRemoveClick}>
          <XCircle size={18} />
        </IconButton>
      </GridItem>
      <GridItem className="fieldSelect">
        <SelectFilterField
          style={{ width: "100%" }}
          onChange={event => handleColumnChange(event.target.value as string)}
          value={item.field}
        >
          <option value="" />
          {configs.map((config, idx) => (
            <option key={`${idx}-column-item`} value={config.column}>
              {config.name}
            </option>
          ))}
        </SelectFilterField>
      </GridItem>
      <GridItem className="operationSelect">
        <SelectFilterField
          style={{ width: 130 }}
          onChange={event => handleOperatorChange(event.target.value as string)}
          value={item.operator}
        >
          {activeConfig.operators.map((operator, idx) => (
            <option key={`${idx}-operator-item`} value={operator.value}>
              {t(operator.name)}
            </option>
          ))}
        </SelectFilterField>
      </GridItem>
      <GridItem className="valueColumn">
        {(activeConfig.type === AdvancedFiltersOperatorType.TEXT ||
          activeConfig.type === AdvancedFiltersOperatorType.TEXT_LIKE) && (
          <InputFilterField
            value={item.value as string}
            onChange={event => handleValueChange(event.target.value as string)}
          />
        )}
        {activeConfig.type === AdvancedFiltersOperatorType.PLATE && (
          <InputFilterField
            value={item.value as string}
            onChange={event => handleValueChange(event.target.value as string)}
            maxLength={7}
          />
        )}
        {activeConfig.type === AdvancedFiltersOperatorType.NUMBER && (
          <InputFilterField
            type="number"
            value={item.value as string}
            onChange={event => handleValueChange(event.target.value as string)}
          />
        )}
        {activeConfig.type === AdvancedFiltersOperatorType.SELECT && (
          <SelectFilterField
            value={item.value as string}
            onChange={event => handleValueChange(event.target.value as string)}
          >
            <option value="" />
            {activeConfig.optionsArray.map((option, idx) => (
              <option key={`${option.value}-${idx}`} value={option.value}>
                {option.name}
              </option>
            ))}
          </SelectFilterField>
        )}
        {activeConfig.type === AdvancedFiltersOperatorType.MULTI_SELECT && (
          <MultiSelectInputFilter
            selectedValues={item.value ? (item.value as string[]) : []}
            onValueChange={value => handleValueChange(value)}
            options={activeConfig.optionsArray}
          />
        )}
        {activeConfig.type === AdvancedFiltersOperatorType.BOOLEAN && (
          <SelectFilterField
            value={item.value as string}
            onChange={event => handleValueChange(event.target.value as string)}
          >
            <option value="true">true</option>
            <option value="false">false</option>
          </SelectFilterField>
        )}
        {activeConfig.type === AdvancedFiltersOperatorType.DATE && (
          <DateInputFilter
            value={item.value as string}
            onValueChange={value => {
              handleValueChange(value);
            }}
          />
        )}
        {activeConfig.type === AdvancedFiltersOperatorType.DATETIME && (
          <DateInputFilter
            time
            value={item.value as string}
            onValueChange={value => {
              handleValueChange(value);
            }}
          />
        )}
      </GridItem>
    </GridWrapper>
  );
};

export default ConditionRow;
