import {
  SnackbarKey,
  useSnackbar,
  OptionsObject,
  SnackbarMessage,
  WithSnackbarProps
} from "notistack";
import { X } from "react-feather";
import { FC, ReactNode } from "react";
import { IconButton } from "@mui/material";
import PlateNotification from "components/PlateNotification";

let useSnackbarRef: WithSnackbarProps;

enum VariantType {
  default = "default",
  error = "error",
  success = "success",
  warning = "warning",
  info = "info"
}

interface SnackProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: FC<SnackProps> = ({
  setUseSnackbarRef
}) => {
  setUseSnackbarRef(useSnackbar());
  return null;
};

const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator: FC = (props: {
  children?: ReactNode;
}) => (
  <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef}>
    {props.children}
  </InnerSnackbarUtilsConfigurator>
);

const snackNotification = {
  success(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: VariantType.success });
  },
  warning(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: VariantType.warning });
  },
  info(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: VariantType.info });
  },
  error(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: VariantType.error });
  },
  toast(msg: string, options: OptionsObject = {}): void {
    const newOptions = {
      ...options,
      action: (key: SnackbarKey) => (
        <>
          <IconButton
            sx={{ color: "white" }}
            aria-label="close"
            color="default"
            onClick={() => useSnackbarRef.closeSnackbar(key)}
          >
            <X />
          </IconButton>
        </>
      )
    };

    useSnackbarRef.enqueueSnackbar(msg, newOptions);
  },
  plate(
    msg: string,
    options: OptionsObject = {},
    plate: string,
    bgColor?: string
  ): void {
    const newOptions = {
      ...options,
      key: plate,
      content: (key: SnackbarKey, message: SnackbarMessage) => (
        <PlateNotification
          key={key}
          message={message}
          plate={plate}
          bgColor={bgColor}
        />
      )
    };
    useSnackbarRef.enqueueSnackbar(msg, newOptions);
  },
  close(key?: SnackbarKey): void {
    useSnackbarRef.closeSnackbar(key);
  }
};

export default snackNotification;
