import { AxiosResponse } from "axios";

import { NestedData, Success } from "api/API";
import APIEndpoints from "enums/APIEndpoints";
import Crypto from "utils/Crypto";
import useAxios from "hooks/useAxios";

export type User = {
  ["customer_id"]: string;
  ["auth_token"]?: string;
  username: string;
  name: string;
  status: "ACTIVE" | "WRONG_1" | "WRONG_2" | "BLOCKED" | "INITIAL_BLOCKED";
  ["level_name"]?: string;
  ["sub_level_name"]?: string[];
  ["group_name"]?: string[];
  ["user_data"]?: {
    //cpf: string;
    whatsapp: string;
    telegram: string;
    ["expiration_date"]: string;
    ["accept_licence"]: boolean;
  };
  ["ignore_user_group"]: boolean;
};

export type DataUserList = {
  ["page"]: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  ["users"]: User[];
};

export type DataUserCustomerList = {
  ["page"]: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  ["customers"]: User[];
};

type CreateParams = {
  customerId: string;
  username: string;
  name: string;
  password: string;
  levelName: string;
  subLevelName: string[];
  cpf?: string;
  whatsapp?: string;
  telegram?: string;
  expirationDate?: string;
  ignoreUserGroup?: boolean;
};

type UpdateParams = {
  customerId: string;
  username: string;
  name: string;
  oldPassword?: string;
  newPassword?: string;
  levelName?: string;
  subLevelName?: string[];
  cpf?: string;
  whatsapp?: string;
  telegram?: string;
  expirationDate?: string;
  ignoreUserGroup?: boolean;
};

type DeleteByUsernameParams = {
  customerId: string;
  username: string;
};

type UseUserAPIType = {
  search: (
    customerId: string,
    userName: string,
    page: number
  ) => Promise<NestedData<DataUserCustomerList | User[]>>;
  listPaginated: (
    customerId: string,
    page: number,
    pageSize: number
  ) => Promise<NestedData<DataUserList>>;
  listAll: (customerId: string) => Promise<NestedData<User[]>>;
  getByUsername: (customerId: string, username: string) => Promise<User>;
  create: (data: CreateParams) => Promise<Success>;
  update: (data: UpdateParams) => Promise<Success>;
  deleteByUsername: (data: DeleteByUsernameParams) => Promise<Success>;
};

const useUserAPI = (): UseUserAPIType => {
  const API = useAxios();

  const search = async (
    customerId: string,
    userName: string,
    page = 0
  ): Promise<NestedData<DataUserCustomerList | User[]>> => {
    const response: AxiosResponse<NestedData<DataUserCustomerList | User[]>> =
      await API.instance.get(APIEndpoints.USER_PARTIAL, {
        params: {
          ["customer_id"]: customerId,
          name: userName,
          page: page > 0 ? page : undefined
        }
      });
    return response.data;
  };

  const listPaginated = async (
    customerId: string,
    page: number,
    pageSize: number
  ): Promise<NestedData<DataUserList>> => {
    const response: AxiosResponse<NestedData<DataUserList>> =
      await API.instance.post(APIEndpoints.USER_LIST, {
        ["customer_id"]: customerId,
        page,
        ["page_size"]: pageSize
      });
    return response.data;
  };

  const listAll = async (customerId: string): Promise<NestedData<User[]>> => {
    const response: AxiosResponse<NestedData<User[]>> = await API.instance.post(
      APIEndpoints.USER_LIST,
      {
        ["customer_id"]: customerId,
        page: -1
      }
    );
    return response.data;
  };

  const getByUsername = async (
    customerId: string,
    username: string
  ): Promise<User> => {
    const response: AxiosResponse<User> = await API.instance.get(
      APIEndpoints.USER,
      {
        params: {
          ["customer_id"]: customerId,
          username
        }
      }
    );
    return response.data;
  };

  const create = async (data: CreateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.USER_CREATE,
      {
        ["customer_id"]: data.customerId,
        name: data.name,
        username: data.username,
        ["user_data"]: {
          //cpf: data.cpf,
          whatsapp: data.whatsapp || "",
          telegram: data.telegram || "",
          ["expiration_date"]: data.expirationDate
        },
        ["permission_level"]: data.levelName,
        ["permission_sub_level"]: data.subLevelName,
        password: Crypto.encrypt(data.password),
        ["ignore_user_group"]: data.ignoreUserGroup
      }
    );
    return response.data;
  };

  const update = async (data: UpdateParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.USER_UPDATE,
      {
        username: data.username,
        name: data.name,
        ["customer_id"]: data.customerId,
        ["permission_level"]: data.levelName,
        ["permission_sub_level"]: data.subLevelName,
        ["old_password"]: data.oldPassword
          ? Crypto.encrypt(data.oldPassword)
          : undefined,
        ["new_password"]: data.newPassword
          ? Crypto.encrypt(data.newPassword)
          : undefined,
        //["user_data"]: {
        //cpf: data.cpf,
        //whatsapp: data.whatsapp || "",
        //telegram: data.telegram || "",
        //["expiration_date"]: data.expirationDate || ""
        //}
        ["ignore_user_group"]: data.ignoreUserGroup
      }
    );
    return response.data;
  };

  const deleteByUsername = async (
    data: DeleteByUsernameParams
  ): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.USER_DELETE,
      {
        ["customer_id"]: data.customerId,
        username: data.username
      }
    );
    return response.data;
  };

  return {
    search,
    listPaginated,
    listAll,
    create,
    update,
    getByUsername,
    deleteByUsername
  };
};
export default useUserAPI;
