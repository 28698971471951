import { FC, useEffect, useState } from "react";
import { Autocomplete, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CustomFilterTextField,
  SelectType
} from "components/AdvancedFilters/AdvancedFiltersHelpers";

type MultiselectInputFilterProps = {
  selectedValues: string[];
  onValueChange: (value: string[]) => void;
  options: SelectType[];
};

const MultiSelectInputFilter: FC<MultiselectInputFilterProps> = ({
  options,
  selectedValues,
  onValueChange
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<SelectType[]>([]);

  useEffect(() => {
    createSelectedArray(selectedValues);
  }, []);

  const createSelectedArray = (selecteds: string[]) => {
    selecteds.forEach(selectedItem => {
      const objItem = {
        name: t(`DashboardPage.${selectedItem}`),
        value: selectedItem
      };
      setValue(prev => (prev ? [...prev, objItem] : [objItem]));
    });
  };

  return (
    <>
      <Autocomplete
        size="small"
        multiple
        disablePortal
        openText={t("action.open")}
        closeText={t("action.close")}
        clearText={t("action.clear")}
        noOptionsText={t("form.noOptions")}
        options={options}
        getOptionLabel={option => option.name}
        value={value}
        onChange={(event, values) => {
          event.preventDefault();
          setValue(values);
          const valuesString: string[] = [];
          values.forEach(value => valuesString.push(value.value));
          onValueChange(valuesString);
        }}
        renderOption={(props, option) => (
          <Box component="li" sx={{ fontSize: 12 }} {...props}>
            {option.name}
          </Box>
        )}
        renderInput={params => (
          <CustomFilterTextField
            {...params}
            placeholder={t("form.startTyping")}
          />
        )}
      />
    </>
  );
};

export default MultiSelectInputFilter;
