import { FC, forwardRef } from "react";
import { Box, Button, IconButton, styled, Typography } from "@mui/material";
import { AlertCircle, X } from "react-feather";
import { SnackbarContent, SnackbarKey, SnackbarMessage } from "notistack";
import { useTranslation } from "react-i18next";
import history from "utils/history";
import Pages from "enums/Pages";
import snackNotification from "components/SnackNotification";

type NotificationParams = {
  message: SnackbarMessage;
  key: SnackbarKey;
  bgColor?: string;
  plate: string;
};

const CustomSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  padding: "6px 8px 6px 20px",
  backgroundColor: "red",
  borderRadius: 4,
  boxShadow: theme.shadows[5]
}));

const CardActions = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));

const Buttons = styled(Box)(() => ({
  display: "flex",
  flexShrink: 0,
  marginLeft: 16
}));

type NotificationButtonsProps = {
  plate: string;
};

const NotificationButtons: FC<NotificationButtonsProps> = ({ plate }) => {
  const { t } = useTranslation();

  return (
    <Button
      size="small"
      variant="text"
      sx={{ color: "white", textTransform: "uppercase" }}
      onClick={() =>
        history.push(`${Pages.CAPTURE_REPORT}/${encodeURIComponent(plate)}`)
      }
    >
      {t("MonitoredVehicleNotification.record")}
    </Button>
  );
};

const PlateNotification = forwardRef<HTMLDivElement, NotificationParams>(
  (props, ref) => (
    <CustomSnackbarContent
      ref={ref}
      style={{ backgroundColor: props.bgColor ?? "#323e54" }}
    >
      <CardActions>
        <AlertCircle style={{ marginRight: "8px", color: "white" }} />
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          {props.message}
        </Typography>
        <Buttons>
          <NotificationButtons plate={props.plate} />
          <IconButton
            sx={{ color: "white" }}
            onClick={() => snackNotification.close(props.plate)}
          >
            <X />
          </IconButton>
        </Buttons>
      </CardActions>
    </CustomSnackbarContent>
  )
);

PlateNotification.displayName = "PlateNotification";

export default PlateNotification;
