import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { Checkbox, Typography } from "@mui/material";
import Pages from "enums/Pages";
import debounce from "lodash/debounce";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import { useIsMount } from "hooks/useIsMount";
import { useHistory } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import useMosaicAPI, { Mosaic } from "api/MosaicAPI";
import useCameraAPI, { CamerasPaginated } from "api/CameraAPI";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import PageSection from "components/PageSection/PageSection";
import DataTable from "components/DataTable";
import { Check } from "react-feather";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

type CameraTableRow = {
  id: string;
  checked: boolean;
  camera: string;
  equipment: string;
  direction: string;
  serialNumber: string;
  active: boolean;
  owner: string;
};

const FilterCamerasPage: FC = () => {
  const MosaicAPI = useMosaicAPI();
  const CameraAPI = useCameraAPI();
  const isMount = useIsMount();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const [pageSize, setPageSize] = useState<number>(10);
  const [rows, setRows] = useState<CameraTableRow[]>([]);
  const [search, setSearch] = useState("");
  const [, setFetchingMosaic] = useState<boolean>(false);

  const [selectAllCheckbox, setSelectAllCheckbox] = useState<boolean>(false);
  const [isFetchingCameras, setFetchingCameras] = useState<boolean>(false);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [page, setPage] = useState(1);
  const [mosaicSettings, setMosaicSettings] = useState<
    Mosaic["mosaicData"] | null
  >(null);
  const [cameraLimit, setCameraLimit] = useState<number>(64);
  const history = useHistory();
  const { errorHandler } = useErrorHandler();

  const requestMosaicData = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setFetchingMosaic(true);
    try {
      const mosaicResponse = await MosaicAPI.getByUsername({
        customerId: sessionUser["customer_id"],
        username: sessionUser.username
      });
      return mosaicResponse?.mosaicData ?? null;
    } catch (error) {
      errorHandler({ error });
      return null;
    } finally {
      setFetchingMosaic(false);
    }
  }, [sessionUser]);

  const requestCameraData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setFetchingCameras(true);
      try {
        let cameraResponse;
        if (searchValue) {
          cameraResponse = await CameraAPI.search({
            customerId: sessionUser["customer_id"],
            name: searchValue,
            page: pageValue
          });
        } else {
          cameraResponse = await CameraAPI.listPaginated({
            customerId: sessionUser["customer_id"],
            page: pageValue,
            pageSize: pageSizeValue
          });
        }

        const {
          cameras,
          total_pages: totalPages,
          total_count: totalCount
        } = cameraResponse.data as CamerasPaginated;

        let currentMosaicSettings = mosaicSettings;
        if (!currentMosaicSettings) {
          currentMosaicSettings = (await requestMosaicData()) ?? null;
          if (currentMosaicSettings) setMosaicSettings(currentMosaicSettings);
        }

        const mosaicCameras = currentMosaicSettings?.["mosaic_data"].map(
          data => data.camera
        );

        const newRows = cameras?.map(camera => ({
          id: `${camera["location_name"]}/${camera["camera_name"]}`,
          checked: mosaicCameras?.includes(camera["camera_name"]) ?? false,
          camera: camera["camera_name"],
          equipment: camera["location_name"],
          direction: camera["camera_data"].side,
          serialNumber: camera["serial_number"],
          active: camera["camera_data"].active,
          owner: camera["shared_data"]?.owner ?? ""
        }));

        setCameraLimit(
          mosaicSettings
            ? parseInt(mosaicSettings.size.split("x")[0], 10) ** 2
            : 64
        );
        if (mosaicSettings) limitMosaicData(mosaicSettings);
        setRows(cameras ? newRows : []);
        setPaginator({
          totalPages: totalPages ?? defaultPaginatorModelValues.totalPages,
          totalItems: totalCount ?? defaultPaginatorModelValues.totalItems
        });

        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setFetchingCameras(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    const mosaicRequest = async () => {
      await requestMosaicData();
    };
    mosaicRequest();
    requestCameraData(search, page, pageSize);
  }, [requestMosaicData, requestCameraData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string) => {
        requestCameraData(searchValue, page, pageSize);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search);
    }
  }, [search, fetch]);

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.filterCameras"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.monitoring")
      },
      {
        label: t("CamerasMosaicPage.title"),
        page: Pages.MOSAIC
      },
      {
        label: t("FilterCamerasPage.title"),
        page: Pages.SYSTEM_FILTER_CAMERAS
      }
    ]);
  }, [t, Pages]);

  useEffect(() => {
    if (rows.every(i => i.checked)) {
      setSelectAllCheckbox(true);
    } else {
      setSelectAllCheckbox(false);
    }
  }, [rows]);

  useEffect(() => {
    (async () => applyFilter())();
    if (mosaicSettings) {
      setCameraLimit(parseInt(mosaicSettings.size.split("x")[0], 10) ** 2);
    }
  }, [mosaicSettings]);

  const handleCheckboxChange = (
    id: string | "all",
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!mosaicSettings) return;
    const isChecked = event.target.checked;

    if (isChecked && mosaicSettings.mosaic_data.length + 1 > cameraLimit) {
      errorHandler({
        error: t("FilterCamerasPage.limitReached", {
          limit: cameraLimit
        }).toString()
      });
      return;
    }

    const newMosaicSettings: typeof mosaicSettings = {
      ...mosaicSettings
    };

    if (id === "all") {
      newMosaicSettings.mosaic_data = isChecked
        ? [
            ...mosaicSettings.mosaic_data,
            ...rows
              .filter(
                row =>
                  !mosaicSettings.mosaic_data.find(
                    data => data.camera === row.camera
                  )
              )
              .map(row => ({
                location: row.equipment,
                camera: row.camera,
                owner: row.owner
              }))
          ]
        : mosaicSettings.mosaic_data.filter(
            data => !rows.find(row => row.camera === data.camera)
          );
      setSelectAllCheckbox(isChecked);
    } else {
      newMosaicSettings.mosaic_data = isChecked
        ? [
            ...mosaicSettings.mosaic_data,
            {
              location: rows.find(row => row.id === id)?.equipment || "",
              camera: rows.find(row => row.id === id)?.camera || "",
              owner: rows.find(row => row.id === id)?.owner || ""
            }
          ]
        : mosaicSettings.mosaic_data.filter(
            data => `${data.location}/${data.camera}` !== id
          );
    }

    limitMosaicData(newMosaicSettings);
  };

  const limitMosaicData = (mosaicSettings: Mosaic["mosaicData"]) => {
    if (mosaicSettings.mosaic_data.length > cameraLimit) {
      errorHandler({
        error: t("FilterCamerasPage.limitReached", {
          limit: cameraLimit
        }).toString()
      });
    }

    const updatedMosaicData =
      mosaicSettings.mosaic_data.length > cameraLimit
        ? mosaicSettings.mosaic_data.slice(
            mosaicSettings.mosaic_data.length - cameraLimit
          )
        : mosaicSettings.mosaic_data;

    setRows(
      rows.map(row => ({
        ...row,
        checked: updatedMosaicData.some(data => data.camera === row.camera)
      }))
    );
    setMosaicSettings({
      ...mosaicSettings,
      mosaic_data: updatedMosaicData
    });
  };

  const applyFilter = async (isSavePageData?: boolean) => {
    if (!mosaicSettings) return;
    try {
      const response = await MosaicAPI.update({
        customerId: mosaicSettings["customer_id"],
        username: mosaicSettings.username,
        size: mosaicSettings.size,
        mosaicData: mosaicSettings["mosaic_data"].filter(x => x.camera !== "")
      });

      if (response && isSavePageData) history.push(Pages.MOSAIC);
    } catch (error) {
      errorHandler({ error });
    }
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          onBackClick={() => history.push(Pages.MOSAIC)}
          title={`${t("FilterCamerasPage.title")} ${
            mosaicSettings?.mosaic_data.length || 0
          }/${cameraLimit}`}
          isLoading={isFetchingCameras}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("FilterCamerasPage.searchCameras")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Check />}
              onClick={() => applyFilter(true)}
              disabled={(mosaicSettings?.["mosaic_data"]?.length ?? 0) < 1}
            >
              {t("action.apply")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              {
                key: "check",
                label: (
                  <Checkbox
                    size="small"
                    color="secondary"
                    disabled={isFetchingCameras}
                    value={selectAllCheckbox}
                    checked={selectAllCheckbox}
                    onChange={event => handleCheckboxChange("all", event)}
                  />
                ),
                noSort: true
              },
              { key: "camera", label: t("FilterCamerasPage.camera") },
              { key: "equipment", label: t("FilterCamerasPage.equipment") },
              {
                key: "direction",
                label: t("FilterCamerasPage.direction")
              },
              {
                key: "serialNumber",
                label: t("FilterCamerasPage.serialNumber")
              },
              {
                key: "status",
                label: t("FilterCamerasPage.status")
              }
            ]}
            defaultSort={["camera", "asc"]}
            onHeaderSort={setRows}
            data={rows}
            renderRow={row => [
              <>
                <Checkbox
                  size="small"
                  color="secondary"
                  value={row.checked}
                  checked={row.checked}
                  onChange={event => handleCheckboxChange(row.id, event)}
                />
              </>,
              <>{row.camera}</>,
              <>
                <Typography component="div" variant="caption">
                  <strong>{row.equipment}</strong>
                </Typography>
              </>,
              <>{t(`CameraDirection.${row.direction}`)}</>,
              <>{row.serialNumber}</>,
              <>{t(`status.${row.active ? "active" : "inactive"}`)}</>
            ]}
            hideColumnsSm={[3, 4]}
            hideColumnsXs={[1, 2, 3, 4]}
            page={page}
            onPageChange={pageValue =>
              requestCameraData(search, pageValue, pageSize)
            }
            pageSize={pageSize}
            onPageSizeChange={value => {
              setPage(1);
              setPageSize(value);
            }}
            totalPages={
              paginator?.totalPages ?? defaultPaginatorModelValues.totalPages
            }
            totalItems={
              paginator?.totalItems ?? defaultPaginatorModelValues.totalItems
            }
            isLoading={isFetchingCameras}
          />
        </PageSection>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default FilterCamerasPage;
