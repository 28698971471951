import { Box } from "@mui/material";
import Pages from "enums/Pages";
import { EMPTY_VALUE } from "utils/String";
import fileDownload from "js-file-download";
import { format, parseISO } from "date-fns";
import useLocationAPI from "api/LocationAPI";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import ConvoyReportFilter, {
  FormFilterConvoys
} from "pages/ConvoyReport/ConvoyReportFilter";
import { Download, Sliders } from "react-feather";
import { useLocale } from "contexts/LocaleContext";
import ConvoyReportSlide from "./ConvoyReportSlide";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import CaptureImgButton from "components/CaptureImgButton";
import { useHistory } from "react-router-dom";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { formatDateTime, setTimezone } from "utils/DateFunctions";
import CaptureImageViewerDialog, {
  CaptureImageDialog,
  initialCaptureImageDialogProps
} from "components/CaptureImageViewerDialog/CaptureImageViewerDialog";
import useConvoyReportAPI, { ConvoyReport } from "api/ConvoyReportAPI";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { LocationSection, usePageLocation } from "contexts/PageLocationContext";
import { parsePlateCoordinate } from "components/ImageViewer";
import LinkPlate from "components/LinkPlate";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import MenuButton from "components/MenuButton";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

export const validateFilter = (filter: FormFilterConvoys): number | string =>
  filter.plate &&
  filter.equipment &&
  filter.referenceDate &&
  filter.referenceTime &&
  filter.maxGapMinutes;

const ConvoyReportPage: FC = () => {
  const [isFilterOpen, setFilterOpen] = useState(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isDownloadingCsv, setDownloadingCsv] = useState<boolean>(false);
  const [isDownloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [reportResults, setReportResults] = useState<ConvoyReport[]>([]);
  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const { sessionUser } = useAuth();
  const { language } = useLocale();
  const LocationAPI = useLocationAPI();
  const ConvoyReportAPI = useConvoyReportAPI();

  const [pageMode, setPageMode] = useState<string>("slide");

  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const [equipments, setEquipments] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      if (sessionUser) {
        const customerId = sessionUser["customer_id"];
        try {
          const locationResponse = await LocationAPI.listAll(customerId);
          setEquipments(
            locationResponse.data.map(location => location["location_name"]) ||
              []
          );
        } catch (error) {
          errorHandler({ error });
        }
      }
    })();
  }, []);

  const defaultFilterValues: FormFilterConvoys = {
    plate: "",
    equipment: "",
    referenceDate: setTimezone(new Date()),
    referenceTime: setTimezone(new Date()),
    maxGapMinutes: 10
  };

  const [filter, setFilter] = useState<FormFilterConvoys>({
    ...defaultFilterValues
  });

  const history = useHistory();

  const requestData = useCallback(
    async (
      filterParam: FormFilterConvoys,
      pageValue: number,
      pageSizeValue: number,
      pageModeValue: string
    ) => {
      if (!sessionUser?.["customer_id"]) return;
      const customerId = sessionUser["customer_id"];
      if (!validateFilter(filterParam) || pageModeValue !== "table") return;
      setLoading(true);
      try {
        if (
          !filterParam.plate &&
          history.location.pathname !== Pages.CONVOY_REPORT
        ) {
          history.replace(Pages.CONVOY_REPORT);
        }
        const reportResponse = await ConvoyReportAPI.list({
          ["customer_id"]: customerId,
          plate: filterParam.plate,
          ["referential_date"]: formatDateTime(
            filterParam.referenceDate as Date,
            filterParam.referenceTime as Date
          ),
          equipment: filterParam.equipment,
          time: filterParam.maxGapMinutes,
          page: pageValue,
          ["page_size"]: pageSizeValue
        });
        setReportResults([
          reportResponse.registers.referential,
          ...reportResponse.registers.items
        ]);
        setPaginator({
          totalPages: reportResponse.registers["total_pages"] || 0,
          totalItems: reportResponse.registers["total_items"] || 0
        });
        setPage(reportResponse.registers.page || 1);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setLoading(false);
      }
    },
    [sessionUser]
  );

  const onFilter = (filterValue: FormFilterConvoys) => {
    setFilter(filterValue);
  };

  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      requestData(filter, page, pageSize, pageMode);
    }
  }, [filter, pageSize, pageMode]);

  const onClearFilter = () => {
    setFilter(defaultFilterValues);
  };

  const downloadCsv = async () => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    if (!filter || isDownloadingCsv) return;
    try {
      setDownloadingCsv(true);
      const response = await ConvoyReportAPI.downloadCsv({
        ["customer_id"]: customerId,
        plate: filter.plate,
        ["referential_date"]: formatDateTime(
          filter.referenceDate as Date,
          filter.referenceTime as Date
        ),
        equipment: filter.equipment,
        time: filter.maxGapMinutes,
        language: language.split("-")[0]
      });
      fileDownload(response, "convoy_report.csv", "text/csv");
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingCsv(false);
    }
  };

  const forceDownloadPdf = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "convoy_report.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadPdf = async () => {
    if (!sessionUser?.["customer_id"]) return;
    const customerId = sessionUser["customer_id"];
    if (!filter || isDownloadingPdf) return;
    try {
      setDownloadingPdf(true);
      const response = await ConvoyReportAPI.downloadPdf({
        ["customer_id"]: customerId,
        plate: filter.plate,
        ["referential_date"]: formatDateTime(
          filter.referenceDate as Date,
          filter.referenceTime as Date
        ),
        equipment: filter.equipment,
        time: filter.maxGapMinutes,
        language: language.split("-")[0]
      });
      forceDownloadPdf(response);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingPdf(false);
    }
  };

  const [capture, setCapture] = useState<CaptureImageDialog>(
    initialCaptureImageDialogProps
  );
  const [isCaptureDialogOpen, setCaptureDialogOpen] = useState<boolean>(false);

  const openCaptureDialog = (capture: ConvoyReport) => {
    setCapture({
      id: capture.id,
      image: capture.imageLink,
      equipment: capture.equipment,
      camera: capture.camera,
      dateTime: capture["date_capture"],
      plate: capture.plate,
      plateCoordinate: capture.plate_coordinate
        ? parsePlateCoordinate(capture.plate_coordinate)
        : undefined,
      renavam: {
        vehicleMake: capture.vehicle_make,
        vehicleModel: capture.vehicle_model,
        vehicleColor: capture.vehicle_color,
        vehicleCategory: capture.vehicle_class,
        vehicleYear: capture.vehicle_year
      }
    });
    setCaptureDialogOpen(true);
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.convoyReport"));
    const location: LocationSection[] = [
      {
        label: t("menu.reports")
      },
      {
        label: t("ConvoyReportPage.title"),
        page: Pages.CONVOY_REPORT
      }
    ];
    setLocation(location);
  }, [t, Pages]);

  const makeRow = (row: ConvoyReport | null): ReactNode[] => {
    if (!row) return [];
    return [
      <>{row.id}</>,
      <>
        {format(
          parseISO(row["date_capture"]),
          t("ConvoyReportPage.dateFormat")
        )}
      </>,
      <>{row.gap || EMPTY_VALUE}</>,
      <>{row.equipment}</>,
      <>{row.camera}</>,
      <>
        <LinkPlate plate={row.plate} path={Pages.VEHICLE_OVERVIEW} />
      </>,
      <>
        <div>{row["vehicle_make"] || EMPTY_VALUE}</div>
        <div>{row["vehicle_model"] || EMPTY_VALUE}</div>
        <div>{row["vehicle_color"] || EMPTY_VALUE}</div>
      </>,
      <>
        <CaptureImgButton
          imageLink={row.imageLink}
          onClick={() => openCaptureDialog(row)}
        />
      </>
    ];
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("ConvoyReportPage.title")}
          isLoading={isLoading}
          actions={
            <>
              <MenuButton
                id="view-button"
                label={t(`ConvoyReportPage.${pageMode}`)}
                actions={[
                  {
                    isLoading,
                    key: "slide",
                    label: t("ConvoyReportPage.slide"),
                    onClick() {
                      setPageMode("slide");
                      setPage(1);
                    }
                  },
                  {
                    isLoading,
                    key: "table",
                    label: t("ConvoyReportPage.table"),
                    onClick() {
                      setPageMode("table");
                      setPage(1);
                    }
                  }
                ]}
              />
              <MenuButton
                id="download-button"
                disabled={paginator.totalItems < 1}
                icon={<Download />}
                label={t("action.export")}
                actions={[
                  {
                    key: "downloadCsv",
                    isLoading: isDownloadingCsv,
                    label: t("CaptureReportPage.exportCsv"),
                    onClick: downloadCsv
                  },
                  {
                    key: "downloadPdf",
                    isLoading: isDownloadingPdf,
                    label: t("CaptureReportPage.exportPdf"),
                    onClick: downloadPdf
                  }
                ]}
              />
            </>
          }
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Sliders />}
              onClick={() => setFilterOpen(true)}
            >
              {t("action.filter")}
            </PageSectionHeaderAction>
          }
        >
          {pageMode === "table" && (
            <Box>
              <DataTable
                watermarked
                headers={[
                  { key: "id", label: t("ConvoyReportPage.id") },
                  {
                    key: "date_capture",
                    label: t("ConvoyReportPage.datetime")
                  },
                  { key: "gap", label: t("ConvoyReportPage.gap") },
                  { key: "equipment", label: t("ConvoyReportPage.equipment") },
                  { key: "camera", label: t("ConvoyReportPage.camera") },
                  { key: "plate", label: t("ConvoyReportPage.plate") },
                  {
                    key: "vehicle_make",
                    label: (
                      <>
                        <div>{t("ConvoyReportPage.make")}</div>
                        <div>{t("ConvoyReportPage.model")}</div>
                        <div>{t("ConvoyReportPage.color")}</div>
                      </>
                    )
                  },
                  {
                    key: "capture",
                    label: t("ConvoyReportPage.capture"),
                    noSort: true
                  }
                ]}
                defaultSort={["id", "asc"]}
                onHeaderSort={setReportResults}
                data={reportResults}
                renderRow={makeRow}
                hideColumnsSm={[6, 7, 8]}
                hideColumnsXs={[0, 1, 3, 4, 6, 7, 8]}
                page={page}
                onPageChange={pageValue =>
                  requestData(filter, pageValue, pageSize, pageMode)
                }
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
                totalPages={paginator.totalPages}
                totalItems={paginator.totalItems}
                isLoading={isLoading}
              />
            </Box>
          )}
          {pageMode === "slide" && (
            <ConvoyReportSlide filterParam={filter} pageMode={pageMode} />
          )}
        </PageSection>
      </InnerPageLayout>
      <ConvoyReportFilter
        open={isFilterOpen}
        equipments={equipments}
        setOpen={setFilterOpen}
        filter={filter}
        defaultValues={defaultFilterValues}
        onFilter={onFilter}
        onClear={onClearFilter}
      />
      <CaptureImageViewerDialog
        open={isCaptureDialogOpen}
        setOpen={setCaptureDialogOpen}
        capture={capture}
      />
    </DefaultPageLayout>
  );
};

export default ConvoyReportPage;
