import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Box, Link, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import useAuthAPI from "api/AuthAPI";
import Pages from "enums/Pages";
import PasswordField from "components/PasswordField";
import { useModal } from "contexts/ModalContext";
import RegexPatterns from "utils/RegexPatterns";

import BackButton from "./BackButton";
import { useErrorHandler } from "contexts/ErrorHandlerContext";

type StepPasswordProps = {
  setStep: (step: number) => void;
  email: string;
  code: string;
};

type PasswordForm = {
  confirmationCode: string;
  newPassword: string;
  confirmNewPassword: string;
};

const StepPassword: FC<StepPasswordProps> = (props: StepPasswordProps) => {
  const { showModal } = useModal();
  const [loading, setLoading] = useState(false);
  const { errorHandler } = useErrorHandler();
  const { confirmPasswordReset } = useAuthAPI();

  const { control, formState, handleSubmit, getValues } = useForm<PasswordForm>(
    {
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        confirmationCode: "",
        newPassword: "",
        confirmNewPassword: ""
      }
    }
  );

  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async (data: PasswordForm) => {
    setLoading(true);
    try {
      await confirmPasswordReset({
        code: props.code,
        username: props.email,
        password: data.newPassword
      });
      showModal({
        title: t("ResetPasswordPage.successTitle"),
        description: (
          <Trans
            i18nKey="ResetPasswordPage.successDescription"
            t={t}
            components={[
              <Link key={0} href={Pages.SIGN_IN} color="secondary" />
            ]}
          />
        ),
        onConfirm: () => history.push(Pages.SIGN_IN)
      });
    } catch (error) {
      errorHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BackButton goBack={() => props.setStep(2)} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <Typography variant="h3">
            {t("ResetPasswordPage.setNewPassword")}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body1" color="textSecondary">
            {t("ResetPasswordPage.step3")}
          </Typography>
        </Box>
        <Box mb={2}>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: t("form.requiredField").toString(),
              pattern: {
                value: RegexPatterns.PASSWORD,
                message: t("form.invalidFormat")
              }
            }}
            render={({ field, fieldState }) => (
              <PasswordField
                label={t("ResetPasswordPage.newPassword")}
                customProps={{
                  disabled: loading,
                  placeholder: t("ResetPasswordPage.newPasswordHint")
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
          <Controller
            name="confirmNewPassword"
            control={control}
            rules={{
              required: t("form.requiredField").toString(),
              validate: value =>
                value === getValues("newPassword") ||
                t("form.passwordsMustMatch").toString()
            }}
            render={({ field, fieldState }) => (
              <PasswordField
                label={t("ResetPasswordPage.confirmNewPassword")}
                customProps={{
                  disabled: loading,
                  placeholder: t("ResetPasswordPage.confirmNewPasswordHint")
                }}
                field={{ ...field }}
                fieldState={fieldState}
              />
            )}
          />
        </Box>
        <Box>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!formState.isValid}
            loading={loading}
          >
            {t("action.continue")}
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default StepPassword;
