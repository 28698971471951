import {
  Box,
  Link,
  styled,
  Typography,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import Pages, { ErrorPages } from "enums/Pages";
import { AxiosError } from "axios";
import Flag from "components/Flag";
import Logo from "components/Logo";
import { FC, useEffect } from "react";
import {
  Location,
  usePageLocation,
  getSearchParamValue
} from "contexts/PageLocationContext";
import { LoadingButton } from "@mui/lab";
import { Key, Mail } from "react-feather";
import { HOME_PAGE } from "components/Router";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import { useLocale } from "contexts/LocaleContext";
import AuthPageLayout from "layouts/AuthPageLayout";
import PasswordField from "components/PasswordField";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { Link as RLink, useHistory, useLocation } from "react-router-dom";

const LanguageContainer = styled(Box)(() => ({
  justifyContent: "center",
  display: "flex"
}));

type FormFields = {
  username: string;
  password: string;
};

const SignInPage: FC = () => {
  const { showModal } = useModal();
  const { errorHandler } = useErrorHandler();
  const { search, state } = useLocation<Location>();
  const { signIn, isAttemptingSignIn } = useAuth();
  const username = getSearchParamValue(search, "username") ?? "";

  const { control, handleSubmit, formState } = useForm<FormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      username,
      password: ""
    }
  });

  const history = useHistory();

  const onSubmit = async (data: FormFields) => {
    snackNotification.close();
    try {
      await signIn(data);
      const redirectTo =
        state?.from?.pathname && !ErrorPages.includes(state.from.pathname)
          ? state.from.pathname
          : HOME_PAGE;
      history.push(redirectTo);
    } catch (err: unknown) {
      const error = err as AxiosError;
      if (error.response && error.response.data.error) {
        if (error.response.data.error.code === 3086) {
          history.push(`${Pages.RESET_PASSWORD}?username=${data.username}`);
          showModal({
            type: "error",
            title: t("SignInPage.initialBlockTitle"),
            description: t("SignInPage.initialBlockDescription")
          });
        } else if (error.response.data.error.code === 3087) {
          showModal({
            type: "error",
            title: t("SignInPage.accountSuspended"),
            description: (
              <Trans
                i18nKey="SignInPage.accountSuspendedMessage"
                t={t}
                components={[<Link key={0} href="#" color="secondary" />]}
              />
            )
          });
        } else if (error.response.data.error.code === 3088) {
          errorHandler({ error: err });
        }
      } else {
        errorHandler({ error: err });
      }
    }
  };

  const { t } = useTranslation();

  const { setPageTitle } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.signIn"));
  }, [t]);

  const { language, setLanguage } = useLocale();

  return (
    <AuthPageLayout>
      <Box sx={{ pb: 3 }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          data-testid="login-form"
        >
          <Logo />
          <Box mb={1}>
            <div data-testid="username">
              <Controller
                name="username"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("SignInPage.username")}
                    customProps={{
                      autoFocus: username === "",
                      disabled: isAttemptingSignIn,
                      type: "email",
                      placeholder: t("SignInPage.usernameHint"),
                      startAdornment: <Mail />
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </div>
            <div data-testid="password">
              <Controller
                name="password"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <PasswordField
                    label={t("SignInPage.password")}
                    customProps={{
                      autoFocus: username !== "",
                      disabled: isAttemptingSignIn,
                      placeholder: t("SignInPage.passwordHint"),
                      startAdornment: <Key />
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </div>
          </Box>
          <Box mb={7} data-testid="login-btn">
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
              loading={isAttemptingSignIn}
            >
              {t("SignInPage.enter")}
            </LoadingButton>
          </Box>
          <Box mb={1}>
            <Typography variant="body1" align="center" color="textSecondary">
              {t("SignInPage.forgotPassword")}{" "}
              <Link
                component={RLink}
                to={Pages.RESET_PASSWORD}
                color="secondary"
              >
                {t("SignInPage.resetPassword")}
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" align="center" color="textSecondary">
              {t("SignInPage.newUser")}{" "}
              <Link
                component={RLink}
                to={Pages.CREATE_ACCOUNT}
                color="secondary"
              >
                {t("SignInPage.createAccount")}
              </Link>
            </Typography>
          </Box>
        </form>
      </Box>
      <LanguageContainer>
        <ToggleButtonGroup
          exclusive
          aria-label="language"
          value={language}
          onChange={(_event, value) => setLanguage(value)}
        >
          <ToggleButton value="en-US" title="English (United States)">
            <Flag country="us" />
          </ToggleButton>
          <ToggleButton value="es-ES" title="Español">
            <Flag country="es" />
          </ToggleButton>
          <ToggleButton value="pt-BR" title="Português (Brasil)">
            <Flag country="br" />
          </ToggleButton>
        </ToggleButtonGroup>
      </LanguageContainer>
    </AuthPageLayout>
  );
};

export default SignInPage;
