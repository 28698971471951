import {
  Fab,
  Box,
  Grid,
  styled,
  useTheme,
  IconButton,
  Typography,
  ToggleButton,
  useMediaQuery,
  ToggleButtonGroup
} from "@mui/material";
import { format } from "date-fns";
import { EMPTY_VALUE } from "utils/String";
import { Plus, XCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { FC, MouseEvent, useState } from "react";
import SchedulerModal from "components/SchedulerForm/SchedulerModal";
import { SchedulerDate, SchedulerHourRange } from "api/MonitoringAPI";

export type SchedulerFormModal = {
  startTime: string | Date;
  endTime: string | Date;
};

type SchedulerFormProps = {
  required: boolean;
  days: string[];
  schedulerDate: SchedulerDate[];
  setSchedulerDaySelected: (current: string) => void;
  setScheduler: (data: SchedulerFormModal) => void;
  handleDays: (event: MouseEvent<HTMLElement>, newFormats: string[]) => void;
  removeScheduler: (
    dayRemoved: string,
    hourRemoved: SchedulerHourRange
  ) => void;
};

const DayContainer = styled(ToggleButtonGroup)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  button: {
    border: "1px solid rgba(0, 0, 0, 0.12) !important",
    borderTopLeftRadius: "4px !important",
    borderBottomLeftRadius: "4px !important",
    borderTopRightRadius: "4px !important",
    borderBottomRightRadius: "4px !important"
  }
}));

const SchedulerForm: FC<SchedulerFormProps> = ({
  days,
  required,
  schedulerDate,
  setScheduler,
  setSchedulerDaySelected,
  handleDays,
  removeScheduler
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isSchedulerModalOpen, setIsSchedulerModalOpen] =
    useState<boolean>(false);

  const formatDate = (date: Date): string => {
    if (typeof date === "string") {
      return EMPTY_VALUE;
    }
    return format(date, "HH:mm");
  };

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4" align="left">
              {t("MonitoringVehicles.days")}
            </Typography>
            {required && (
              <span style={{ color: theme.palette.error.main }}>&nbsp;*</span>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <DayContainer
            sx={{ flexDirection: fullScreen ? "column" : "row" }}
            value={days}
            color="secondary"
            onChange={handleDays}
          >
            <ToggleButton
              sx={{
                width: fullScreen ? "100%" : "auto",
                mb: fullScreen ? 2 : 0
              }}
              value="monday"
            >
              {t("form.weekDays.monday")}
            </ToggleButton>
            <ToggleButton
              sx={{
                width: fullScreen ? "100%" : "auto",
                mb: fullScreen ? 2 : 0
              }}
              value="tuesday"
            >
              {t("form.weekDays.tuesday")}
            </ToggleButton>
            <ToggleButton
              sx={{
                width: fullScreen ? "100%" : "auto",
                mb: fullScreen ? 2 : 0
              }}
              value="wednesday"
            >
              {t("form.weekDays.wednesday")}
            </ToggleButton>
            <ToggleButton
              sx={{
                width: fullScreen ? "100%" : "auto",
                mb: fullScreen ? 2 : 0
              }}
              value="thursday"
            >
              {t("form.weekDays.thursday")}
            </ToggleButton>
            <ToggleButton
              sx={{
                width: fullScreen ? "100%" : "auto",
                mb: fullScreen ? 2 : 0
              }}
              value="friday"
            >
              {t("form.weekDays.friday")}
            </ToggleButton>
            <ToggleButton
              sx={{
                width: fullScreen ? "100%" : "auto",
                mb: fullScreen ? 2 : 0
              }}
              value="saturday"
            >
              {t("form.weekDays.saturday")}
            </ToggleButton>
            <ToggleButton
              sx={{
                width: fullScreen ? "100%" : "auto",
                mb: fullScreen ? 2 : 0
              }}
              value="sunday"
            >
              {t("form.weekDays.sunday")}
            </ToggleButton>
          </DayContainer>
        </Grid>
        <Grid item xs={12}>
          {schedulerDate.map(scheduler => (
            <Box
              key={scheduler.dow}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 3
              }}
            >
              <Typography sx={{ width: "116px", mr: 2 }} variant="body1">
                {t(`form.weekDays.${scheduler.dow}`)}
              </Typography>

              {days.includes(scheduler.dow) &&
                scheduler.hours_intervals.map((hour, idx) => (
                  <Box
                    key={`${hour}${idx}`}
                    sx={{
                      p: 1,
                      mr: 1,
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "16px",
                      backgroundColor: "#C2C9D1",
                      justifyContent: "space-between"
                    }}
                  >
                    <Typography sx={{ p: 1 }} variant="body1">
                      {`${formatDate(hour.start_hour as Date)} - ${formatDate(
                        hour.end_hour as Date
                      )}`}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => removeScheduler(scheduler.dow, hour)}
                    >
                      <XCircle size={20} />
                    </IconButton>
                  </Box>
                ))}
              <Fab
                disabled={!days.includes(scheduler.dow)}
                size="small"
                color="secondary"
                onClick={() => {
                  setSchedulerDaySelected(scheduler.dow);
                  setIsSchedulerModalOpen(true);
                }}
              >
                <Plus />
              </Fab>
            </Box>
          ))}
        </Grid>
      </Grid>
      <SchedulerModal
        open={isSchedulerModalOpen}
        setOpen={setIsSchedulerModalOpen}
        setScheduler={setScheduler}
      />
    </>
  );
};

export default SchedulerForm;
