import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";
import { NestedData, Success } from "api/API";
import { SchedulerDate } from "./MonitoringAPI";
import { formatSchedulerDateTime } from "utils/DateFunctions";

export type SchedulerRestriction = {
  dow: string;
  ["hours_intervals"]: {
    ["start_hour"]: Date | string;
    ["end_hour"]: Date | string;
  }[];
};

export type Restriction = {
  name: string;
  index: string;
  cameras: string[];
  locations: string[];
  type: RestrictionType;
  ["final_date"]: string;
  ["initial_date"]: string;
  ["restriction_data"]: {
    ["dow_hours"]: SchedulerRestriction[];
    ["vehicle_class"]: string;
    ["rotation_plate_last_digit"]: number[];
    ["max_speed"]: number;
  };
};

export type RestrictionType =
  | "all"
  | "blocked_road"
  | "vehicle_rotation"
  | "speeding"
  | "border_division"
  | "exclusive_road";

export type RestrictionData = {
  ["dow_hours"]: SchedulerDate[] | undefined;
  ["vehicle_class"]: string | undefined;
  ["rotation_plate_last_digit"]: number[] | undefined;
  ["max_speed"]: number | undefined;
};

type CreateParams = {
  customerId: string;
  name: string;
  type: RestrictionType;
  ["final_date"]: string;
  ["initial_date"]: string;
  locations: string[] | undefined;
  cameras: string[] | undefined;
  ["restriction_data"]: RestrictionData;
};

type UpdateParams = {
  customerId: string;
  index: string;
  name: string;
  type: RestrictionType;
  ["final_date"]: string;
  ["initial_date"]: string;
  locations: string[] | undefined;
  cameras: string[] | undefined;
  ["restriction_data"]: RestrictionData;
};

type RestrictionDefaultParams = {
  customerId: string;
  index: string;
};

export type RestrictionPaginated = {
  restrictions: Restriction[];
  page: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  ["page_size"]?: number;
};

type UseRestrictionsAPIType = {
  listPaginated: (
    customerId: string,
    page: number,
    pageSize: number
  ) => Promise<NestedData<RestrictionPaginated>>;
  listAll: (customerId: string) => Promise<NestedData<Restriction[]>>;
  search: (
    customerId: string,
    name: string,
    page: number,
    pageSize?: number
  ) => Promise<NestedData<RestrictionPaginated | Restriction[]>>;
  create: (data: CreateParams) => Promise<Success>;
  getByIndex: (data: RestrictionDefaultParams) => Promise<Restriction>;
  update: (data: UpdateParams) => Promise<Success>;
  deleteByIndex: (data: RestrictionDefaultParams) => Promise<Success>;
};

const useRestrictionsAPI = (): UseRestrictionsAPIType => {
  const API = useAxios();

  const listPaginated = async (
    customerId: string,
    page: number,
    pageSize: number
  ): Promise<NestedData<RestrictionPaginated>> => {
    const response: AxiosResponse<NestedData<RestrictionPaginated>> =
      await API.instance.post(APIEndpoints.RESTRICTION_LIST, {
        ["customer_id"]: customerId,
        page,
        ["page_size"]: pageSize
      });
    return response.data;
  };

  const listAll = async (
    customerId: string
  ): Promise<NestedData<Restriction[]>> => {
    const response: AxiosResponse<NestedData<Restriction[]>> =
      await API.instance.post(APIEndpoints.RESTRICTION_LIST, {
        ["customer_id"]: customerId,
        page: -1
      });
    return response.data;
  };

  const search = async (
    customerId: string,
    name: string,
    page = -1,
    pageSize?: number
  ): Promise<NestedData<RestrictionPaginated | Restriction[]>> => {
    const response: AxiosResponse<
      NestedData<RestrictionPaginated | Restriction[]>
    > = await API.instance.get(APIEndpoints.RESTRICTION_PARTIAL, {
      params: {
        ["customer_id"]: customerId,
        ["restriction_name"]: name,
        page,
        ["page_size"]: pageSize
      }
    });
    return response.data;
  };

  const create = async (data: CreateParams): Promise<Success> => {
    const newData = formatSchedulerDateTime(
      data.restriction_data.dow_hours || []
    );

    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.RESTRICTION_CREATE,
      {
        ["customer_id"]: data.customerId,
        name: data.name,
        type: data.type,
        cameras: data.cameras,
        ["initial_date"]: data.initial_date,
        ["final_date"]: data.final_date,
        locations: data.locations,
        ["restriction_data"]: {
          ["dow_hours"]: newData,
          ["vehicle_class"]: data.restriction_data.vehicle_class,
          ["rotation_plate_last_digit"]:
            data.restriction_data.rotation_plate_last_digit,
          ["max_speed"]: data.restriction_data.max_speed
        }
      }
    );
    return response.data;
  };

  const getByIndex = async (
    data: RestrictionDefaultParams
  ): Promise<Restriction> => {
    const response: AxiosResponse<Restriction> = await API.instance.get(
      APIEndpoints.RESTRICTION_GET,
      {
        params: {
          ["customer_id"]: data.customerId,
          index: data.index
        }
      }
    );
    return response.data;
  };

  const update = async (data: UpdateParams): Promise<Success> => {
    const newData = formatSchedulerDateTime(
      data.restriction_data.dow_hours || []
    );

    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.RESTRICTION_UPDATE,
      {
        ["customer_id"]: data.customerId,
        index: data.index,
        name: data.name,
        cameras: data.cameras,
        type: data.type,
        locations: data.locations,
        ["initial_date"]: data.initial_date,
        ["final_date"]: data.final_date,
        ["restriction_data"]: {
          ["dow_hours"]: newData,
          ["vehicle_class"]: data.restriction_data.vehicle_class,
          ["rotation_plate_last_digit"]:
            data.restriction_data.rotation_plate_last_digit,
          ["max_speed"]: data.restriction_data.max_speed
        }
      }
    );

    return response.data;
  };

  const deleteByIndex = async ({
    customerId,
    index
  }: RestrictionDefaultParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.RESTRICTION_DELETE,
      {
        ["customer_id"]: customerId,
        index
      }
    );
    return response.data;
  };

  return {
    listPaginated,
    listAll,
    search,
    create,
    getByIndex,
    update,
    deleteByIndex
  };
};

export default useRestrictionsAPI;
