import Pages from "enums/Pages";
import { Sliders } from "react-feather";
import LogReportFilter, {
  FormFilterLogs
} from "pages/LogReport/LogReportFilter";
import { format, parse, set } from "date-fns";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import DataTable from "components/DataTable";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import useLogReportAPI, { LogReport } from "api/LogReportAPI";
import { FC, useCallback, useEffect, useState } from "react";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { formatDateTime, setTimezone } from "utils/DateFunctions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";

const PUMATRONIX_CLIENTE = "Cliente";

const LogReportPage: FC = () => {
  const LogReportAPI = useLogReportAPI();
  const isMount = useIsMount();
  const { sessionUser } = useAuth();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [pageSize, setPageSize] = useState<number>(10);

  const defaultFilterValues: FormFilterLogs = {
    user: "",
    message: "",
    startDate: setTimezone(
      set(new Date(), { hours: 0, minutes: 0, seconds: 0 })
    ),
    endDate: setTimezone(new Date()),
    startTime: setTimezone(
      set(new Date(), { hours: 0, minutes: 0, seconds: 0 })
    ),
    endTime: setTimezone(new Date())
  };

  const [reportResults, setReportResults] = useState<LogReport[]>([]);
  const [filterData, setFilterData] =
    useState<FormFilterLogs>(defaultFilterValues);

  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const requestData = useCallback(
    async (
      filter: FormFilterLogs | undefined,
      pageValue,
      pageSizeValue: number
    ) => {
      if (!sessionUser?.["customer_id"] || !filter) return;
      setIsLoading(true);
      try {
        const reportResponse = await LogReportAPI.list({
          ["customer_id"]: sessionUser["customer_id"],
          page: pageValue,
          user:
            sessionUser?.level_name === PUMATRONIX_CLIENTE
              ? sessionUser?.username
              : filter.user,
          message: filter.message,
          ["final_date"]: formatDateTime(
            filter.endDate as Date,
            filter.endTime as Date
          ),
          ["initial_date"]: formatDateTime(
            filter.startDate as Date,
            filter.startTime as Date
          ),
          ["page_size"]: pageSizeValue
        });
        setReportResults(reportResponse.logs || []);
        setPaginator({
          totalPages: reportResponse.total_pages || 0,
          totalItems: reportResponse.total_count || 0
        });
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(filterData, page, pageSize);
  }, [requestData, pageSize]);

  useEffect(() => {
    if (!isMount) {
      if (filterData === null) {
        requestData(filterData, page, pageSize);
      } else {
        requestData(filterData, 1, pageSize);
      }
    }
  }, [filterData]);

  const onFilter = (data: FormFilterLogs) => {
    setFilterData(data);
  };

  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.logReport"));
    setLocation([
      {
        label: t("menu.reports")
      },
      {
        label: t("LogReportPage.title"),
        page: Pages.LOG_REPORT
      }
    ]);
  }, [t, Pages]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("LogReportPage.title")}
          isLoading={isLoading}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Sliders />}
              onClick={() => setIsFilterOpen(true)}
            >
              {t("action.filter")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "date_time", label: t("LogReportPage.datetime") },
              { key: "user", label: t("LogReportPage.userType") },
              { key: "message", label: t("LogReportPage.message") }
            ]}
            defaultSort={["date_time", "desc"]}
            onHeaderSort={setReportResults}
            data={reportResults}
            renderRow={row => [
              <>
                {format(
                  parse(row.date_time, "dd/MM/yyyy HH:mm:ss.SSS", new Date()),
                  t("LogReportPage.dateTimeFormat")
                )}
              </>,
              <>{row.user}</>,
              <>{row.message}</>
            ]}
            hideColumnsXs={[2]}
            page={page}
            onPageChange={pageValue =>
              requestData(filterData, pageValue, pageSize)
            }
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <LogReportFilter
        open={isFilterOpen}
        setOpen={setIsFilterOpen}
        onFilter={data => onFilter(data)}
      />
    </DefaultPageLayout>
  );
};

export default LogReportPage;
