import {
  Box,
  MenuItem,
  Skeleton,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Watermark from "components/Watermark";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import SelectField from "components/SelectField";
import { formatDateTime } from "utils/DateFunctions";
import { Table, TableContainer } from "components/Table";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import useFlowDashboardAPI, { TopSpeedRank } from "api/FlowDashboardAPI";
import useMonitoredRouteAPI, { MonitoredRoute } from "api/MonitoredRouteAPI";
import { useFlowDashboardFilter } from "pages/FlowDashboard/FlowDashboardFilterContext";
import PageSection from "components/PageSection/PageSection";

const CameraName = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "12px"
}));

const TopSpeedRanking: FC = () => {
  const FlowDashboardAPI = useFlowDashboardAPI();
  const MonitoredRouteAPI = useMonitoredRouteAPI();
  const { sessionUser } = useAuth();
  const [isFetching, setIsFetching] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState<string>("");
  const [routes, setRoutes] = useState<MonitoredRoute[]>([]);
  const [data, setData] = useState<TopSpeedRank[]>([]);
  const { filterData, getFilterData } = useFlowDashboardFilter();
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const requestRoutes = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    try {
      setIsFetching(true);
      const allRoutes = await MonitoredRouteAPI.listAll({
        customerId: sessionUser.customer_id
      });
      setRoutes(allRoutes.data);
      if (allRoutes.data[0]) {
        setSelectedRoute(
          `${allRoutes.data[0].route_name}@@${allRoutes.data[0].initial_camera}`
        );
      }
    } finally {
      setIsFetching(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestRoutes();
  }, [requestRoutes]);

  useEffect(() => {
    (async () => {
      if (!sessionUser) return;
      const customerId = sessionUser["customer_id"];
      const routeName = selectedRoute.split("@@")[0];
      const initialCamera = selectedRoute.split("@@")[1];
      const route = routes.find(
        r => r.route_name === routeName && r.initial_camera === initialCamera
      );
      if (!route) return;
      try {
        setIsFetching(true);
        const filter = getFilterData();
        const response = await FlowDashboardAPI.routeTopSpeed({
          ["customer_id"]: customerId,
          ["initial_date"]: formatDateTime(
            filter.startDate as Date,
            filter.startTime as Date
          ),
          ["final_date"]: formatDateTime(
            filter.endDate as Date,
            filter.endTime as Date
          ),
          ["initial_camera"]: route.initial_camera,
          ["final_camera"]: route.final_camera,
          ["route_name"]: route.route_name,
          ranking: filter.ranking ?? 5
        });
        setData(response.routeTopSpeed);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsFetching(false);
      }
    })();
  }, [selectedRoute, filterData]);

  return (
    <PageSection
      title={t("FlowDashboardPage.rankingOfPlatesWithHigherAverageSpeed")}
      variant="h5"
      actions={
        <SelectField
          style={{
            marginBottom: 0
          }}
          value={selectedRoute}
          onChange={event => setSelectedRoute(event.target.value as string)}
        >
          <MenuItem>{t("FlowDashboardPage.selectRoute")}</MenuItem>
          {routes.map(route => (
            <MenuItem
              key={route.route_name + route.initial_camera + route.final_camera}
              value={`${route.route_name}@@${route.initial_camera}`}
            >
              <Box>
                <Box>{route.route_name}</Box>
                <Box>
                  <CameraName>
                    {route.initial_camera} - {route.final_camera}
                  </CameraName>
                </Box>
              </Box>
            </MenuItem>
          ))}
        </SelectField>
      }
    >
      <Watermark>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key="h0" component="th">
                  #
                </TableCell>
                <TableCell key="h1" component="th">
                  {t("FlowDashboardPage.plate")}
                </TableCell>
                <TableCell key="h2" component="th">
                  {t("FlowDashboardPage.speed")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching &&
                [0, 1, 2, 3, 4].map(i => (
                  <TableRow key={i}>
                    <TableCell>
                      <Skeleton variant="text" width={60} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={60} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={60} />
                    </TableCell>
                  </TableRow>
                ))}
              {!isFetching &&
                data &&
                data.length > 0 &&
                data.map((row, idx) => (
                  <TableRow key={`r0${idx}`}>
                    <TableCell component="td">{idx + 1}</TableCell>
                    <TableCell component="td">{row.plate}</TableCell>
                    <TableCell component="td">{row.speed}</TableCell>
                  </TableRow>
                ))}
              {!isFetching && data && data.length <= 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography align="center">
                      {t("FlowDashboardPage.noDataFound")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Watermark>
    </PageSection>
  );
};

export default TopSpeedRanking;
