import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";
import { NestedData, Success } from "api/API";
import { formatSchedulerDateTime } from "utils/DateFunctions";

export type MonitoringType =
  | "documentation"
  | "intelligence"
  | "search_and_seizure"
  | "theft_and_robbery"
  | "automatic_monitoring";

export type Monitoring = {
  index: string;
  plate: string;
  ["custom_sound_alert"]: string;
  ["alert_color"]: string;
  ["persist_alert"]: boolean;
  ["sound_alert"]: boolean;
  ["customer_id"]: string;
  ["final_date_timestamp"]: number;
  name: string;
  type: MonitoringType;
  ["initial_date_timestamp"]: number;
  locations: string[];
  owners: string[];
  ["owners_group"]: string[];
  code: string;
  ["final_date"]: string;
  ["initial_date"]: string;
  ["popup_alert"]: boolean;
  ["monitoring_data"]: {
    description: string;
    characteristics: string;
    ["diff_chars_in_plate"]: number;
    ["vehicle_make"]: string;
    ["vehicle_model"]: string;
    ["vehicle_color"]: string;
    ["vehicle_classes"]: string[];
    ["dow_hours"]: {
      dow: string;
      ["hours_intervals"]: {
        ["start_hour"]: Date | string;
        ["end_hour"]: Date | string;
      }[];
    }[];
    notifications: {
      sms: boolean;
      whatsapp: boolean;
      telegram: boolean;
      ["telegram_groups"]: string[];
      email: boolean;
    };
  };
};

export type VehiclesMake = {
  name: string;
  isDivider: boolean;
};

type ListAllParams = {
  customerId: string;
};

type ListFilteredParams = {
  page: string;
  type: string;
  plate: string;
  equipment: string;
  startDate: string;
  endDate: string;
  soundAlert: string;
};

type PaginatedData = {
  page: number;
  ["page_size"]?: number;
  ["total_count"]: number;
  ["total_pages"]: number;
  monitorings: Monitoring[];
};

type GetByIndexParams = {
  customerId: string;
  index: string;
};

type DeleteParams = {
  customerId: string;
  index: string;
};

export type SchedulerHourRange = {
  ["start_hour"]: Date | string;
  ["end_hour"]: Date | string;
};

export type SchedulerDate = {
  dow: string;
  ["hours_intervals"]: SchedulerHourRange[];
};

type CreateParams = {
  customerId: string;
  name: string;
  plate: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  ["vehicle_classes"]: string[];
  ["vehicle_color"]: string;
  ["custom_sound_alert"]: string;
  ["alert_color"]: string;
  ["persist_alert"]: boolean;
  plateQtd: number;
  code: string;
  startDate: string;
  endDate: string;
  soundAlert: boolean;
  popupAlert: boolean;
  equipments: string[];
  owners: string[];
  ownersGroup: string[];
  type: MonitoringType;
  description: string;
  characteristics: string;
  email: boolean;
  whatsapp: boolean;
  telegram: boolean;
  telegramGroups: string[];
  sms: boolean;
  ["dow_hours"]: SchedulerDate[];
};

type UpdateParams = {
  customerId: string;
  index: string;
  name: string;
  plate: string;
  plateQtd: number;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  ["vehicle_color"]: string;
  ["vehicle_classes"]: string[];
  ["custom_sound_alert"]: string;
  ["alert_color"]: string;
  ["persist_alert"]: boolean;
  code: string;
  startDate: string;
  endDate: string;
  soundAlert: boolean;
  popupAlert: boolean;
  equipments: string[];
  owners: string[];
  ownersGroup: string[];
  type: MonitoringType;
  description: string;
  characteristics: string;
  email: boolean;
  whatsapp: boolean;
  telegram: boolean;
  telegramGroups: string[];
  sms: boolean;
  ["dow_hours"]: SchedulerDate[];
};

type UploadFileResponse = {
  success: {
    ignored: string[];
    imported: string[];
    message: string;
  };
};

type ImportFileParams = {
  file: File;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
};

type UseMonitoringAPIType = {
  listAll: ({
    customerId
  }: ListAllParams) => Promise<NestedData<{ Items: Monitoring[] }>>;
  listFiltered: (
    customerId: string,
    filter: ListFilteredParams,
    pageSizeValue: number
  ) => Promise<NestedData<PaginatedData>>;
  getByIndex: ({ customerId, index }: GetByIndexParams) => Promise<Monitoring>;
  deleteByIndex: ({ customerId, index }: DeleteParams) => Promise<Success>;
  create: (data: CreateParams) => Promise<Success>;
  update: (data: UpdateParams) => Promise<Success>;
  importFile: (data: ImportFileParams) => Promise<UploadFileResponse>;
};

const useMonitoringAPI = (): UseMonitoringAPIType => {
  const API = useAxios();

  const listAll = async ({
    customerId
  }: ListAllParams): Promise<NestedData<{ Items: Monitoring[] }>> => {
    const response: AxiosResponse<NestedData<{ Items: Monitoring[] }>> =
      await API.instance.post(APIEndpoints.MONITORING_LIST, {
        ["customer_id"]: customerId
      });
    return response.data;
  };

  const listFiltered = async (
    customerId: string,
    filter: ListFilteredParams,
    pageSizeValue: number
  ): Promise<NestedData<PaginatedData>> => {
    const params: {
      ["customer_id"]: string;
      page: string;
      type?: string;
      plate?: string;
      locations?: string;
      ["page_size"]?: number;
      ["initial_date"]?: string;
      ["final_date"]?: string;
      ["sound_alert"]?: string;
    } = {
      ["customer_id"]: customerId,
      page: filter.page,
      plate: filter.plate,
      type: filter.type,
      ["sound_alert"]: filter.soundAlert,
      locations: filter.equipment,
      ["page_size"]: pageSizeValue,
      ["initial_date"]: filter.startDate,
      ["final_date"]: filter.endDate
    };

    if (!filter.type) {
      delete params.type;
    }
    if (!filter.plate) {
      delete params.plate;
    }
    if (!pageSizeValue) {
      delete params.page_size;
    }
    if (!filter.equipment) {
      delete params.locations;
    }
    if (!filter.soundAlert) {
      delete params.sound_alert;
    }
    if (!filter.startDate) {
      delete params.initial_date;
    }
    if (!filter.endDate) {
      delete params.final_date;
    }

    const response: AxiosResponse<NestedData<PaginatedData>> =
      await API.instance.get(APIEndpoints.MONITORING_PARTIAL, {
        params
      });
    return response.data;
  };

  const getByIndex = async ({
    customerId,
    index
  }: GetByIndexParams): Promise<Monitoring> => {
    const response: AxiosResponse<Monitoring> = await API.instance.get(
      APIEndpoints.MONITORING,
      {
        params: {
          ["customer_id"]: customerId,
          index
        }
      }
    );
    return response.data;
  };

  const deleteByIndex = async ({
    customerId,
    index
  }: DeleteParams): Promise<Success> => {
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.MONITORING_DELETE,
      {
        ["customer_id"]: customerId,
        index
      }
    );
    return response.data;
  };

  const create = async (data: CreateParams): Promise<Success> => {
    const newData = formatSchedulerDateTime(data.dow_hours || []);

    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.MONITORING_CREATE,
      {
        ["customer_id"]: data.customerId,
        name: data.name,
        plate: data.plate,
        ["custom_sound_alert"]: data.custom_sound_alert,
        ["alert_color"]: data.alert_color,
        ["persist_alert"]: data.persist_alert,
        code: data.code,
        ["initial_date"]: data.startDate,
        ["final_date"]: data.endDate,
        ["sound_alert"]: data.soundAlert,
        ["popup_alert"]: data.popupAlert,
        locations: data.equipments,
        owners: data.owners,
        ["owners_group"]: data.ownersGroup,
        type: data.type,
        ["monitoring_data"]: {
          description: data.description,
          characteristics: data.characteristics,
          ["diff_chars_in_plate"]: data.plateQtd,
          ["vehicle_classes"]: data.vehicle_classes,
          ["vehicle_make"]: data.vehicle_make,
          ["vehicle_model"]: data.vehicle_model,
          ["vehicle_color"]: data.vehicle_color,
          ["dow_hours"]: newData,
          notifications: {
            email: data.email,
            whatsapp: data.whatsapp,
            telegram: data.telegram,
            ["telegram_groups"]: data.telegramGroups,
            sms: data.sms
          }
        }
      }
    );
    return response.data;
  };

  const update = async (data: UpdateParams): Promise<Success> => {
    const newData = formatSchedulerDateTime(data.dow_hours || []);
    const response: AxiosResponse<Success> = await API.instance.post(
      APIEndpoints.MONITORING_UPDATE,
      {
        ["customer_id"]: data.customerId,
        index: data.index,
        name: data.name,
        plate: data.plate,
        ["custom_sound_alert"]: data.custom_sound_alert,
        ["alert_color"]: data.alert_color,
        ["persist_alert"]: data.persist_alert,
        code: data.code,
        ["initial_date"]: data.startDate,
        ["final_date"]: data.endDate,
        ["sound_alert"]: data.soundAlert,
        ["popup_alert"]: data.popupAlert,
        locations: data.equipments,
        type: data.type,
        owners: data.owners,
        ["owners_group"]: data.ownersGroup,
        ["monitoring_data"]: {
          description: data.description,
          characteristics: data.characteristics,
          ["diff_chars_in_plate"]: data.plateQtd,
          ["vehicle_classes"]: data.vehicle_classes,
          ["vehicle_make"]: data.vehicle_make,
          ["vehicle_model"]: data.vehicle_model,
          ["vehicle_color"]: data.vehicle_color,
          ["dow_hours"]: newData,
          notifications: {
            email: data.email,
            whatsapp: data.whatsapp,
            telegram: data.telegram,
            ["telegram_groups"]: data.telegramGroups,
            sms: data.sms
          }
        }
      }
    );
    return response.data;
  };

  const importFile = async (
    data: ImportFileParams
  ): Promise<UploadFileResponse> => {
    const formData = new FormData();
    formData.append("", data.file);
    const response: AxiosResponse<UploadFileResponse> = await API.instance.post(
      APIEndpoints.MONITORING_UPLOAD,
      formData,
      {
        onUploadProgress: data.onUploadProgress
      }
    );
    return response.data;
  };

  return {
    listAll,
    listFiltered,
    getByIndex,
    create,
    update,
    deleteByIndex,
    importFile
  };
};

export default useMonitoringAPI;
