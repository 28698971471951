import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  styled,
  TextField,
  Typography
} from "@mui/material";
import { AxiosError } from "axios";
import Button from "components/Button";
import useToolsAPI from "api/ToolsAPI";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { FC, useEffect, useState } from "react";
import { setTimezone } from "utils/DateFunctions";
import { Controller, useForm } from "react-hook-form";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";
import { CameraFilter } from "api/CameraAPI";
import { useAuth } from "contexts/AuthContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { VehicleColor, vehicleColors } from "utils/VehicleConsts";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import { RestrictionType } from "api/RestrictionAPI";
import { VehiclesMake } from "api/IncidentReportAPI";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFilterIncident = {
  restrictions?: RestrictionType[];
  equipment?: string[];
  cameras?: string[];
  plate?: string;
  startTime: string | Date | null;
  endTime: string | Date | null;
  startDate: string | Date | null;
  endDate: string | Date | null;
  make?: string;
  model?: string;
  color?: string;
};

type Props = {
  open: boolean;
  cameras: CameraFilter[];
  equipments: string[];
  makes: VehiclesMake[];
  setOpen: (isOpen: boolean) => void;
  filter: FormFilterIncident;
  defaultValues: FormFilterIncident;
  onFilter: (data: FormFilterIncident) => void;
  onClear?: () => void;
};

const IncidentReportFilter: FC<Props> = ({
  open,
  setOpen,
  filter,
  defaultValues,
  onFilter,
  onClear,
  cameras,
  makes,
  equipments
}) => {
  const ToolsAPI = useToolsAPI();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [cameraSelected, setCameraSelected] = useState<CameraFilter[]>([
    {
      ["location_name"]: t("form.all"),
      ["camera_name"]: t("form.all")
    }
  ]);
  const [restrictionSelected, setRestrictionSelected] = useState<
    RestrictionType[]
  >(["all"]);
  const [equipmentSelected, setEquipmentSelected] = useState<string[]>([
    t("form.all")
  ]);
  const [makeSelected, setMakeSelected] = useState<VehiclesMake>({
    name: "",
    isDivider: false
  });
  const [colorSelected, setColorSelected] = useState<VehicleColor>({
    color: ""
  });
  const [specificModels, setSpecificModels] = useState<string[]>([]);
  const [genericModels, setGenericModels] = useState<string[]>([]);
  const [specificModelSelected, setSpecificModelSelected] =
    useState<string>("");
  const [genericModelSelected, setGenericModelSelected] = useState<string>("");
  const [isSpecificModelLoading, setSpecificModelLoading] =
    useState<boolean>(false);
  const [isGenericModelLoading, setGenericModelLoading] =
    useState<boolean>(false);

  const { control, handleSubmit, reset } = useForm<FormFilterIncident>({
    mode: "onChange",
    defaultValues: filter
  });

  useEffect(() => {
    reset(filter);
  }, [filter]);

  const restrictions = [
    "all",
    "blocked_road",
    "border_division",
    "exclusive_road",
    "speeding",
    "vehicle_rotation"
  ];

  const clearForm = () => {
    setCameraSelected([
      {
        ["location_name"]: t("form.all"),
        ["camera_name"]: t("form.all")
      }
    ]);
    setEquipmentSelected([t("form.all")]);
    setMakeSelected({ name: "", isDivider: false });
    setGenericModelSelected("");
    setSpecificModelSelected("");
    setColorSelected({ color: "" });
    setRestrictionSelected(["all"]);
    reset(defaultValues);
    if (onClear) onClear();
  };

  const setSpecificModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setSpecificModelLoading(true);
    try {
      const response = await ToolsAPI.getSpecificModels(makeSelected.name);
      setSpecificModels(response.model.map(item => item.name) || []);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setSpecificModelLoading(false);
    }
  };

  const setGenericModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setGenericModelLoading(true);
    try {
      const response = await ToolsAPI.getGenericModels(makeSelected.name);
      setGenericModels(response.model.map(item => item.name) || []);
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err.response?.data.error.code === 4054) {
        setGenericModels([]);
      } else {
        errorHandler({ error });
      }
    } finally {
      setGenericModelLoading(false);
    }
  };

  useEffect(() => {
    if (makeSelected.name === "") {
      setSpecificModelSelected("");
      setGenericModelSelected("");
    } else {
      setSpecificModelSelected("");
      setSpecificModelOptions();
      setGenericModelSelected("");
      setGenericModelOptions();
    }
  }, [makeSelected]);

  const getFilterData = (data: FormFilterIncident): FormFilterIncident => {
    const newData = { ...data };

    if (equipmentSelected.includes(t("form.all"))) {
      newData.equipment = [];
    } else {
      newData.equipment = equipmentSelected;
    }

    const IsAll = cameraSelected.filter(
      c => c["location_name"] === t("form.all")
    );

    if (IsAll.length > 0) {
      newData.cameras = [];
    } else {
      newData.cameras = cameraSelected.map(camera => camera.camera_name);
    }

    if (restrictionSelected.includes("all")) {
      newData.restrictions = [];
    } else {
      newData.restrictions = restrictionSelected;
    }

    newData.startTime = newData.startTime
      ? setTimezone(new Date(newData.startTime))
      : null;
    newData.endTime = newData.endTime
      ? setTimezone(new Date(newData.endTime))
      : null;
    newData.startDate = newData.startDate
      ? setTimezone(new Date(newData.startDate))
      : null;
    newData.endDate = newData.endDate
      ? setTimezone(new Date(newData.endDate))
      : null;

    newData.make = makeSelected.name;

    if (specificModelSelected === "" && genericModelSelected !== "") {
      newData.model = genericModelSelected;
    } else if (genericModelSelected === "" && specificModelSelected !== "") {
      newData.model = specificModelSelected;
    } else {
      newData.model = undefined;
    }

    newData.color = colorSelected.color;

    return newData;
  };

  const onSubmit = async (data: FormFilterIncident) => {
    const filterData = getFilterData(data);
    onFilter(filterData);
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={t("IncidentReportPage.filter")}
    >
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    label={t("IncidentReportPage.startDateTime")}
                    required
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    label={t("IncidentReportPage.endDateTime")}
                    required
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.equipment")}</FormLabel>
              <Autocomplete
                multiple
                disablePortal
                size="small"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                noOptionsText={t("form.noOptions")}
                options={equipments}
                getOptionLabel={option => option}
                value={equipmentSelected}
                onChange={(event, values) => {
                  event.preventDefault();
                  const noIsAll = !values.includes(t("form.all"));
                  if (
                    equipmentSelected.includes(t("form.all")) &&
                    equipmentSelected.length === 1
                  ) {
                    const idx = values.indexOf(t("form.all"));
                    values.splice(idx, 1);
                    setEquipmentSelected(values);
                  } else if (noIsAll) {
                    setEquipmentSelected(values);
                  } else {
                    setEquipmentSelected([t("form.all")]);
                    setCameraSelected([
                      {
                        ["location_name"]: t("form.all"),
                        ["camera_name"]: t("form.all")
                      }
                    ]);
                  }
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.cameras")}</FormLabel>
              <Autocomplete
                multiple
                disablePortal
                size="small"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={[
                  {
                    ["location_name"]: t("form.all"),
                    ["camera_name"]: t("form.all")
                  },
                  ...cameras.filter(c =>
                    equipmentSelected.includes(c["location_name"])
                  )
                ]}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option.camera_name}
                value={cameraSelected}
                onChange={(event, values) => {
                  event.preventDefault();
                  const noIsAll = values.findIndex(
                    c => c["location_name"] === t("form.all")
                  );
                  const allArray = cameraSelected.filter(
                    c => c["location_name"] === t("form.all")
                  );

                  if (allArray.length > 0 && cameraSelected.length === 1) {
                    values.splice(noIsAll, 1);
                    setCameraSelected(values);
                  } else if (noIsAll === -1) {
                    setCameraSelected(values);
                  } else {
                    setCameraSelected([
                      {
                        ["location_name"]: t("form.all"),
                        ["camera_name"]: t("form.all")
                      }
                    ]);
                  }
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.incidentType")}</FormLabel>
              <Autocomplete
                multiple
                disablePortal
                size="small"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={restrictions}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => t(`RestrictionsPage.type.${option}`)}
                value={restrictionSelected as string[]}
                onChange={(event, values) => {
                  event.preventDefault();
                  const noIsAll = !values.includes("all");
                  if (
                    restrictionSelected.includes("all") &&
                    restrictionSelected.length === 1
                  ) {
                    const idx = values.indexOf("all");
                    values.splice(idx, 1);
                    setRestrictionSelected(values as RestrictionType[]);
                  } else if (noIsAll) {
                    setRestrictionSelected(values as RestrictionType[]);
                  } else {
                    setRestrictionSelected(["all"]);
                  }
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Typography variant="h5" color="primary.light">
                  {t("DefaultPageLayout.plateSearch")}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="plate"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    uppercase
                    label={t("IncidentReportPage.plate")}
                    gutter={false}
                    field={{ ...field }}
                    fieldState={fieldState}
                    customProps={{
                      inputProps: {
                        maxLength: 7
                      }
                    }}
                  />
                )}
              />
              <Typography variant="caption" align="left" color="primary.light">
                {t("DefaultPageLayout.tooltipPlate")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Typography variant="h5" color="primary.light">
                  {t("DefaultPageLayout.characteristicsSearch")}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.make")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={makes}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option.name}
                value={makeSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  setMakeSelected({
                    name: value?.name || "",
                    isDivider: false
                  });
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
                renderOption={(props, value) => (
                  <>
                    <li {...props}>{value.name}</li>
                    {value.isDivider === true ? <Divider /> : null}
                  </>
                )}
              />
              <Typography align="left" variant="caption" color="primary.light">
                {t("DefaultPageLayout.tooltipModel")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.genericModel")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                disabled={
                  makeSelected.name === "" ||
                  isGenericModelLoading ||
                  specificModelSelected !== ""
                }
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={genericModels}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option}
                value={genericModelSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  setGenericModelSelected(value || "");
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                    {isGenericModelLoading && (
                      <CircularProgress
                        color="secondary"
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "42px"
                        }}
                      />
                    )}
                  </div>
                )}
              />
              <Divider>
                <Typography variant="subtitle1" color="primary.light">
                  {t("DefaultPageLayout.or")}
                </Typography>
              </Divider>
              <FormLabel>{t("IncidentReportPage.specificModel")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                disabled={
                  makeSelected.name === "" ||
                  isSpecificModelLoading ||
                  genericModelSelected !== ""
                }
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={specificModels}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option}
                value={specificModelSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  setSpecificModelSelected(value || "");
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                    {isSpecificModelLoading && (
                      <CircularProgress
                        color="secondary"
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "42px"
                        }}
                      />
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.color")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={vehicleColors}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option.color}
                value={colorSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  setColorSelected({ color: value?.color || "" });
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: clearForm
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default IncidentReportFilter;
