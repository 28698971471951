import { StrictMode } from "react";
import { render } from "react-dom";
import "flag-icons/css/flag-icons.min.css";

import App from "components/App";
import "styles/global.css";

import reportWebVitals from "./reportWebVitals";

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
