import { styled, TextField } from "@mui/material";
import { AdvancedFilterGroupItem, ConditionItem } from "./AdvancedFilters";

export enum AdvancedFiltersOperatorType {
  TEXT = "text",
  TEXT_LIKE = "text-like",
  PLATE = "plate",
  NUMBER = "number",
  BOOLEAN = "boolean",
  SELECT = "select",
  MULTI_SELECT = "multi-select",
  DATE = "date",
  DATETIME = "datetime"
}

export type SelectType = {
  name: string;
  value: string;
};

export const cloneFilter = (
  obj: AdvancedFilterGroupItem
): AdvancedFilterGroupItem => JSON.parse(JSON.stringify(obj));

export const cloneFilterCondition = (obj: ConditionItem): ConditionItem =>
  JSON.parse(JSON.stringify(obj));

export const getOperatorsByType = (
  type: AdvancedFiltersOperatorType
): SelectType[] => {
  if (
    type === AdvancedFiltersOperatorType.DATE ||
    type === AdvancedFiltersOperatorType.DATETIME
  ) {
    return [
      { name: "AdvancedFilterComponent.is", value: "=" },
      { name: "AdvancedFilterComponent.isAfter", value: ">" },
      { name: "AdvancedFilterComponent.isBefore", value: "<" },
      { name: "AdvancedFilterComponent.isOnOrAfter", value: ">=" },
      { name: "AdvancedFilterComponent.isOnOrBefore", value: "<=" }
    ];
  }
  if (type === AdvancedFiltersOperatorType.NUMBER) {
    return [
      { name: "AdvancedFilterComponent.is", value: "=" },
      { name: "AdvancedFilterComponent.isNot", value: "<>" },
      { name: "AdvancedFilterComponent.isAfter", value: ">" },
      { name: "AdvancedFilterComponent.isBefore", value: "<" },
      { name: "AdvancedFilterComponent.isOnOrAfter", value: ">=" },
      { name: "AdvancedFilterComponent.isOnOrBefore", value: "<=" }
    ];
  }
  if (type === AdvancedFiltersOperatorType.MULTI_SELECT) {
    return [
      { name: "AdvancedFilterComponent.contains", value: "in" },
      { name: "AdvancedFilterComponent.notContains", value: "not in" }
    ];
  }
  if (
    type === AdvancedFiltersOperatorType.TEXT_LIKE ||
    type === AdvancedFiltersOperatorType.PLATE
  ) {
    return [
      { name: "AdvancedFilterComponent.contains", value: "like" },
      { name: "AdvancedFilterComponent.notContains", value: "not like" }
    ];
  }
  return [
    { name: "AdvancedFilterComponent.is", value: "=" },
    { name: "AdvancedFilterComponent.isNot", value: "<>" }
  ];
};

export const GroupWrapper = styled("div")(({ theme }) => ({
  "& .group-items": {
    paddingLeft: theme.spacing(4)
  }
}));

export const GridWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  marginLeft: "-8px"
}));

export const GridItem = styled("div")(({ theme }) => ({
  flexDirection: "column",
  "&.iconButton": {
    maxWidth: 40
  },
  "&.conditionSelect": {
    minWidth: 70
  },
  "&.fieldSelect": {
    minWidth: 160
  },
  "&.operationSelect": {
    minWidth: 80
  },
  "&.valueColumn": {
    flexGrow: 1,
    "& .MuiFormControl-root": {
      display: "flex",
      minHeight: 42,
      justifyContent: "center",
      "& .MuiInputBase-root": {
        paddingLeft: theme.spacing(1)
      }
    }
  },
  marginRight: theme.spacing(1)
}));

export const SelectFilterField = styled("select")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  width: "100%",
  minHeight: 42,
  border: `1px solid ${theme.palette.grey["300"]}`,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius
}));

export const InputFilterField = styled("input")(({ theme }) => ({
  minHeight: 42,
  width: "100%",
  fontFamily: theme.typography.fontFamily,
  border: `1px solid ${theme.palette.grey["300"]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  borderWidth: 1
}));

export const CustomFilterTextField = styled(TextField)(() => ({
  "& input, & .MuiChip-label": {
    fontSize: 12
  }
}));
